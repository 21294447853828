import { FC } from 'react';

import { CardDefault, CardDefaultProps } from '@/components/shared/CardDefault';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBCardDefaultProps = CardDefaultProps & BlokCommonProps;

export const SBCardDefault: FC<BlokProps<SBCardDefaultProps>> = ({ blok }) => {
  const { image, arrowLink, items, number } = blok;

  return <CardDefault image={image} arrowLink={arrowLink} items={items} number={number} />;
};
