import React, { FC } from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

export interface FooterColumnProps {
  links: BlokDynamicZone[];
}

export const FooterColumn: FC<FooterColumnProps> = ({ links }) => (
  <div className="w-full">
    {links.map((column) => (
      <BlokProvider key={column._uid} blok={column} />
    ))}
  </div>
);
