import { NavigationItem } from '@/components/layout/Header/types';

export interface IBreadcrumb {
  name: string;
  url: string;
}

const withoutLang = (url: string, locale: string): string => {
  if (url === '') return '/';

  return url.replace(locale, '');
};

export const getLastBreadcrumb = (
  currentPath: string,
  navigation: NavigationItem[],
  locale: string
): IBreadcrumb | null => {
  const item = navigation.find(({ link }) => {
    const url = withoutLang(link.url !== '' ? link.url : link.cached_url, locale);

    return currentPath.includes(url) || currentPath.includes(url.slice(0, -1));
  });

  if (!item) return null;

  const url = withoutLang(item.link.url !== '' ? item.link.url : item.link.cached_url, locale);
  if (!item?.name) {
    return null;
  }

  return {
    name: item.name,
    url: url
  };
};
