import { FC } from 'react';

import { ColumnContent } from '@/components/nextjs/ColumnContent';
import { LinkProps } from '@/components/nextjs/Link';
import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

interface Props {
  items?: BlokDynamicZone[];
  links?: LinkProps[];
}

export const CaseStudyContent: FC<Props> = ({ items, links }) => {
  const withLinks = links && links.length > 0;

  return (
    <div className="w-full lg:w-[50%] max-w-[65rem] self-start lg:self-auto">
      <ColumnContent items={items} />

      {withLinks && (
        <ul className="mt-[1.6rem] lg:mt-[2.4rem] flex items-center gap-[2.4rem]">
          {links.map((link) => (
            <li key={link._uid}>
              <SBLink link={link?.link}>
                <ButtonLink>{link.name}</ButtonLink>
              </SBLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
