import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';

import { NavLink } from '../../types';

interface Props {
  links: NavLink[];
  className?: string;
}

export const SliderLinks: FC<Props> = ({ links, className }) => {
  return (
    <div className={className}>
      <ul className="flex items-center gap-[2.4rem]">
        {links.map(({ name, _uid, link, variant }) => (
          <li key={_uid}>
            <SBLink link={link}>
              <ButtonLink variant={variant}>{name}</ButtonLink>
            </SBLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
