import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { HeroBanner, HeroBannerProps } from '@/components/nextjs/HeroBanner';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBHeroBannerProps = HeroBannerProps & BlokCommonProps & SpaceBottomProp;

export const SBHeroBanner: FC<BlokProps<SBHeroBannerProps>> = ({ blok }) => {
  const { title, rollingTexts, items, image, spaceBottom, id, priority, tag } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <HeroBanner
      id={id}
      title={title}
      rollingTexts={rollingTexts}
      items={items}
      image={image}
      priority={priority}
      className={styles}
      tag={tag}
    />
  );
};
