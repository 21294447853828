import { useRouter } from 'next/router';
import { useEffect } from 'react';

const isStoryblok = typeof window !== 'undefined' && window.location.search.includes('_storyblok');

const isProduction = process.env['NODE_ENV'] === 'production';

export const usePreviewMode = (isPreviewMode: boolean): boolean => {
  const { reload } = useRouter();

  useEffect(() => {
    if (
      !isPreviewMode &&
      isStoryblok &&
      isProduction &&
      process.env['NEXT_PUBLIC_STORYBLOK_TOKEN']
    ) {
      fetch(`/api/enter-preview?token=${process.env['NEXT_PUBLIC_STORYBLOK_TOKEN']}`).then(reload);
    }
  }, [isPreviewMode]);

  useEffect(() => {
    if (isPreviewMode && !isStoryblok) {
      fetch('/api/exit-preview').then(reload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return isPreviewMode;
};
