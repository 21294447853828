import clsx from 'clsx';
import { useSelect } from 'downshift';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

import { ErrorMessage } from '../ErrorMessage';
import { DropdownProps } from '../types';
import { defaultItemToString, getItemContent } from '../utils';

const listItemStyles = clsx(
  'cursor-pointer whitespace-nowrap relative py-[1.3rem] px-[1.6rem] bg-white text-[1.6rem] lg:text-[1.8rem] leading=[2.4rem] lg:leading=[2.6rem] mb-[0.2rem] transition-colors',
  'hover:bg-gray-200 hover:text-white'
);

// Task: BIWR-5
// @docs https://www.downshift-js.com/use-select
export const DropdownDesktop = <T,>({
  items,
  itemToString,
  defaultLabel,
  defaultSelectedItem,
  labelText,
  errorMessage,
  id,
  className,
  onItemChange,
  disabled,
  render
}: DropdownProps<T>) => {
  const {
    isOpen: isListOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps
  } = useSelect({
    items,
    defaultSelectedItem,
    id,
    itemToString: itemToString ? itemToString : defaultItemToString,
    onSelectedItemChange: ({ selectedItem }) =>
      onItemChange && selectedItem && onItemChange(selectedItem)
  });
  const isOpen = isListOpen && !disabled;
  const btnLabel = getItemContent(selectedItem, itemToString) || defaultLabel;

  const buttonStyles = clsx(
    'py-[1.2rem] px-[1.6rem] flex justify-between items-center bg-white w-full',
    'border border-transparent outline-none',
    'focus:border focus:border-gray-200',
    disabled && 'border-gray-200 cursor-not-allowed',
    errorMessage && 'border border-red-500'
  );
  const buttonTextStyles = clsx(
    'text-black text-[1.6rem] lg:text-[1.8rem] leading=[2.4rem] lg:leading-[2.6rem]',
    disabled && 'text-gray-200'
  );
  const listStyles = clsx(
    'absolute top-[5.1rem] left-0 right-0 px-0 py-[0.8rem] z-10 opacity-0 transition-opacity bg-white',
    'max-h-[26rem] overflow-x-hidden overflow-y-auto',
    isOpen && 'pt-[1rem] opacity-100',
    labelText && 'top-[8.2rem]'
  );
  const wrapperStyles = clsx('relative', className);

  const iconStyles = clsx(
    'ml-[2rem] text-primary transition-transform',
    !isOpen && 'rotate-90',
    isOpen && '-rotate-90'
  );

  return (
    <div className={wrapperStyles}>
      {labelText && (
        <label
          {...getLabelProps()}
          className="inline-block mb-[0.4rem] text-black text-[1.2rem] lg:text-[1.4rem] leading-[1.6rem] lg:leading-[2.2rem]"
        >
          {labelText}
        </label>
      )}
      <button
        className={buttonStyles}
        {...getToggleButtonProps({ id, 'aria-label': id, 'aria-labelledby': undefined })}
      >
        <span className={buttonTextStyles}>{btnLabel}</span>
        <ArrowIcon className={iconStyles} />
      </button>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <ul
        {...getMenuProps({ 'aria-label': 'Choose a value', 'aria-labelledby': undefined })}
        aria-label="Choose a value"
        className={listStyles}
      >
        {isOpen &&
          items.map((item, index) => {
            const itemLabel = getItemContent(item, itemToString);

            return (
              <li key={itemLabel} {...getItemProps({ item, index })} className={listItemStyles}>
                {render ? render({ item, index }) : itemLabel}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
