import clsx from 'clsx';
import { FC } from 'react';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';
import { ButtonLink, ButtonLinkVariant } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokLink } from '@/lib/types/utils';

export interface LinkProps {
  variant?: ButtonLinkVariant;
  link: StoryblokLink;
  bgColor?: BackgroundColumnColor;
  name?: string;
  _uid?: string;
}

export const Link: FC<LinkProps> = ({ variant, name, link, bgColor }) => {
  const styles = clsx('mb-[2rem]', bgColor === 'primary' && '!text-white !hover:text-gray-100');

  return (
    <SBLink link={link}>
      <ButtonLink variant={variant} className={styles}>
        {name}
      </ButtonLink>
    </SBLink>
  );
};
