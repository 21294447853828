import { FC } from 'react';

import { FaqItem, FaqItemProps } from '@/components/nextjs/FaqAccordion/Components/FaqItem';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBFaqItemProps = FaqItemProps & BlokCommonProps;

export const SBFaqItem: FC<BlokProps<SBFaqItemProps>> = ({ blok }) => {
  const { question, answer, isOpen, onToggle } = blok;

  return <FaqItem question={question} answer={answer} isOpen={isOpen} onToggle={onToggle} />;
};
