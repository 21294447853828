import { AnimatePresence, motion } from 'framer-motion';
import { VideoJsonLd } from 'next-seo';
import { FC, useRef } from 'react';

import { PlayIcon } from '@/components/shared/Icons/PlayIcon';

import { useToggle } from '@/hooks/useToggle';

import { StoryblokImage, StoryblokVideo } from '@/lib/types/utils';

export interface VideoProps {
  file?: StoryblokVideo;
  loop: boolean;
  autoPlay?: boolean;
  thumbnailImage?: StoryblokImage;
  uploadDate?: string;
}

export const Video: FC<VideoProps> = ({ file, autoPlay, loop, thumbnailImage, uploadDate }) => {
  const autoPl = autoPlay ?? false;
  const [playing, togglePlaying] = useToggle(autoPl);
  const ref = useRef<HTMLVideoElement>(null);

  if (!file) {
    return null;
  }

  const handleToggle = () => {
    if (!playing) {
      togglePlaying();
      ref?.current?.play();
    } else {
      togglePlaying();
      ref?.current?.pause();
    }
  };

  const thumbnailUrls = thumbnailImage?.filename ? [thumbnailImage.filename] : [];
  const validUploadDate = uploadDate ? new Date(uploadDate).toISOString() : undefined;

  return (
    <div
      className="relative mb-[2.2rem]"
      role="button"
      aria-label="Play video"
      onClick={handleToggle}
    >
      <VideoJsonLd
        name={file.title || file.alt || 'Video file'}
        description={file.alt}
        contentUrl={file.filename}
        uploadDate={validUploadDate}
        thumbnailUrls={thumbnailUrls}
      />
      <video
        controls={false}
        playsInline
        width="100%"
        height="100%"
        autoPlay={autoPl}
        muted
        loop={loop}
        ref={ref}
      >
        <source src={file.filename} type="video/mp4" />
        <source src={file.filename} type="video/webm" />
        <track default kind="captions" src={file.filename} srcLang="de" />
        <p>Your browser cannot play the provided video file.</p>
      </video>
      <AnimatePresence mode="wait" initial={false}>
        {!playing && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
          >
            <PlayIcon className="w-[30%] max-w-[8.5rem] h-[30%] max-h-[8.5rem]" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
