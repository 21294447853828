import { FC } from 'react';

import { ColumnContent, ColumnContentProps } from '@/components/nextjs/ColumnContent';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBColumnContentProps = ColumnContentProps & BlokCommonProps;

export const SBColumnContent: FC<BlokProps<SBColumnContentProps>> = ({ blok }) => {
  const { items, contentPosition, bgColor, gap } = blok;

  return (
    <ColumnContent items={items} contentPosition={contentPosition} bgColor={bgColor} gap={gap} />
  );
};
