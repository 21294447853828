import clsx from 'clsx';
import { FC } from 'react';

import { AspectRatio, aspectRatioVariants } from '@/styles/aspectRatio';

import { Image } from '@/components/shared/Image';

import { StoryblokImage } from '@/lib/types/utils';

type PositionVariants = 'left' | 'right' | 'center';

export interface ImageSectionProps {
  image?: StoryblokImage;
  position?: PositionVariants;
  objectFit?: 'cover' | 'contain';
  aspectRatio?: AspectRatio;
  className?: string;
  id?: string;
  priority?: boolean;
}

export const ImageSection: FC<ImageSectionProps> = ({
  image,
  position = 'center',
  objectFit = 'contain',
  aspectRatio = 'video',
  className,
  id,
  priority
}) => {
  if (!image) {
    return null;
  }

  const wrapperStyles = clsx(
    'w-full max-w-[176rem]',
    position === 'left' && 'mr-auto',
    position === 'right' && 'ml-auto',
    position === 'center' && 'mx-auto',
    className
  );

  const imageStyles = clsx('relative w-full', aspectRatioVariants[aspectRatio || 'video']);

  return (
    <section className={wrapperStyles} id={id}>
      <div className={imageStyles}>
        <Image
          priority={priority}
          src={image.filename}
          alt={image.alt || ''}
          layout="fill"
          objectFit={objectFit}
          objectPosition="center"
        />
      </div>
    </section>
  );
};
