import clsx from 'clsx';
import { FC } from 'react';

import { Link } from '@/components/shared/Link';
import { StructuredData } from '@/components/shared/StructuredData';

import { StoryblokImage } from '@/lib/types/utils';
import { getOrganizationSchema } from '@/lib/utils/getOrganizationSchema';

interface Props {
  data: StoryblokImage;
  className?: string;
}

export const HeaderLogo: FC<Props> = ({ className }) => {
  const linkStyles = clsx(
    'inline-block relative shrink-0 w-[6.2rem] lg:w-[7.7rem] h-[4.8rem] lg:h-[5.9rem]',
    className
  );

  return (
    <>
      <StructuredData data={getOrganizationSchema()} />

      <Link href="/">
        <a className={linkStyles} aria-label="Logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 59" fill="none" name="best it">
            <title>best it</title>
            <path
              d="M77.5522 20.3627C76.8057 8.00214 71.7463 0 47.4031 0H35.1693V14.0283H42.5096H56.1534C68.014 14.0283 74.2899 16.3546 77.5522 20.3627ZM17.7241 1.05107C2.68411 3.938 0.5 12.5988 0.5 24.4268V34.7834C0.5 48.167 3.27852 57.4865 24.3179 59C18.7056 55.5385 17.7241 49.7646 17.7241 42.5473V36.7033V1.05107ZM34.6164 35.5401V41.0618C34.6164 46.3171 35.432 49.2741 43.7952 49.2741H54.8817C63.2449 49.2741 64.0605 46.3171 64.0605 41.0618V35.5401C64.0605 30.2848 63.2449 27.3278 54.8817 27.3278H43.7952C35.432 27.3278 34.6164 30.2848 34.6164 35.5401Z"
              fill="#0000FF"
            />
            <path
              d="M34.6164 41.0618V35.5401C34.6164 30.2848 35.432 27.3278 43.7952 27.3278H54.8817C63.2449 27.3278 64.0605 30.2848 64.0605 35.5401V41.0618C64.0605 46.3171 63.2449 49.2741 54.8817 49.2741H43.7952C35.432 49.2741 34.6164 46.3171 34.6164 41.0618Z"
              fill="#0000FF"
            />
            <path
              d="M34.6164 41.0618V35.5401C34.6164 30.2848 35.432 27.3278 43.7952 27.3278H54.8817C63.2449 27.3278 64.0605 30.2848 64.0605 35.5401V41.0618C64.0605 46.3171 63.2449 49.2741 54.8817 49.2741H43.7952C35.432 49.2741 34.6164 46.3171 34.6164 41.0618Z"
              fill="#0000FF"
            />
          </svg>
        </a>
      </Link>
    </>
  );
};
