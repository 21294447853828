import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { SBLink } from '@/components/shared/SBLink';

import { StoryblokLink } from '@/lib/types/utils';

interface Props {
  link: StoryblokLink;
  children?: ReactNode;
  className?: string;
  name?: string;
}

export const FooterLink: FC<Props> = ({ link, children, className, name, ...rest }) => {
  const footerLinkStyles = clsx(
    'text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] whitespace-nowrap',
    'hover:text-gray-100',
    'focus:text-gray-100',
    'visited:underline visited:underline-offset-4',
    className
  );

  return (
    <SBLink link={link}>
      <a className={footerLinkStyles} aria-label={name} {...rest}>
        {children}
      </a>
    </SBLink>
  );
};
