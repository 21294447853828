import clsx from 'clsx';

import { spacingBottom, SpaceBottomProp } from '@/styles/spacing';

import {
  ColumnsWithCardsAndBackgroundImage,
  ColumnsWithCardsAndBackgroundImageProps
} from '@/components/nextjs/ColumnsWithCardsAndBackgroundImage';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBColumnsWithCardsAndBackgroundImageProps = ColumnsWithCardsAndBackgroundImageProps &
  BlokCommonProps &
  SpaceBottomProp;

export const SBColumnsWithCardsAndBackgroundImage = ({
  blok
}: BlokProps<SBColumnsWithCardsAndBackgroundImageProps>) => {
  const { image, column, items, spaceBottom, id, priority } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <ColumnsWithCardsAndBackgroundImage
      id={id}
      image={image}
      column={column}
      items={items}
      priority={priority}
      className={styles}
    />
  );
};
