import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';

import { FaqCategoryBadge } from '@/components/nextjs/FaqAccordion/Components/FaqCategoryBadge';
import { ArrowDownIcon } from '@/components/shared/Icons/ArrowDownIcon';

import { RichText as IRichText } from '@/lib/types/richContent';
import { createMarkup } from '@/lib/utils/createMarkup/createMarkup';

export interface FaqItemProps {
  question?: string | null;
  categories?: string[] | null;
  answer?: IRichText;
  isOpen: boolean;
  onToggle: () => void;
}

export const FaqItem: FC<FaqItemProps> = ({ question, categories, answer, isOpen, onToggle }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      setHeight(isOpen ? content.scrollHeight : 0);
    }
  }, [isOpen]);

  const itemClasses = clsx('border-1', 'border-primary', 'rounded-large', 'not-last:mb-4');
  const toggleButtonClasses = clsx('flex', 'justify-between', 'w-full', 'p-4', 'items-center');
  const questionClasses = clsx('grow', 'pr-2', 'md-pr-0', 'leading-none');
  const toggleIndicatorClasses = clsx('self-center pr-2');

  return (
    <div className={itemClasses}>
      <button className={toggleButtonClasses} onClick={onToggle}>
        <span className={questionClasses}>{question}</span>

        <span className={toggleIndicatorClasses}>
          <ArrowDownIcon className={'transition ease-out' + (isOpen ? ' rotate-180' : '')} />
        </span>
      </button>

      <div
        ref={contentRef}
        className="overflow-hidden transition-height ease-out duration-200"
        style={{ height: `${height}px` }}
      >
        <div className={'text-black pt-0 p-[24px]'}>
          <div className={'mb-[16px]'}>
            {categories?.map((cat) => (
              <FaqCategoryBadge key={cat} category={cat} />
            ))}
          </div>

          {answer && <div dangerouslySetInnerHTML={createMarkup(answer)}></div>}
        </div>
      </div>
    </div>
  );
};
