import { getLocaleUrl } from '@/components/shared/SBLink';

import { getContentNodes } from '@/lib/api/utils/getContentNodes';
import { blockedSlugsList } from '@/lib/consts';

interface GetRecentAsideItemsInput {
  slug: string;
  locale: string;
}

export const getRecentAsideItems = async ({ slug, locale }: GetRecentAsideItemsInput) => {
  const excludingSlugsVariable = [`${slug}/`, 'layout/*', 'dev/*']
    .filter(Boolean)
    .map((item) => `de/${item}`)
    .join(',');

  const recentDataItems = await getContentNodes({
    perPage: 5,
    page: 1,
    startsWith: `${locale}/`,
    sortBy: 'first_published_at:desc',
    excludingSlugs: excludingSlugsVariable,
    filterQuery: {
      component: {
        in: 'caseStudies,insights,insightsCategory,page'
      },
      excludeFromAside: {
        is: false
      }
    }
  });

  const recent =
    recentDataItems?.items?.map((item) => ({
      id: item?.uuid,
      slug: getLocaleUrl(item?.full_slug || '', blockedSlugsList),
      name: item?.name
    })) || null;

  return recent;
};
