import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';
import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokLink } from '@/lib/types/utils';
import { getDate } from '@/lib/utils';

export interface BreadcrumbProps {
  date?: string;
  breadcrumbLabel?: string;
  title?: string;
  arrowLink?: StoryblokLink;
  bgColor?: BackgroundColumnColor;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
  date,
  breadcrumbLabel,
  title,
  arrowLink,
  bgColor = 'white'
}) => {
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  useEffect(() => {
    setFormattedDate(getDate(date));
  }, [date]);

  const buttonStyles = clsx(bgColor === 'primary' && '!text-white');

  return (
    <div className="mb-[1.6rem] lg:mb-[2.2rem]">
      <div className="flex gap-[1.6rem] mb-[0.8rem]">
        {formattedDate && (
          <span className="text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] text-black">
            {formattedDate}
          </span>
        )}

        <span className="text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] text-black">
          {breadcrumbLabel}
        </span>
      </div>
      {title && (
        <h3 className="font-bold text-[1.8rem] lg:text-[2.4rem] leading-[2.6rem] lg:leading-[3.4rem] mb-[1.6rem]">
          {title}
        </h3>
      )}
      {arrowLink && (
        <SBLink link={arrowLink}>
          <ButtonLink variant="arrow" className={buttonStyles} />
        </SBLink>
      )}
    </div>
  );
};
