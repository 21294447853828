import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import {
  MobileSliderDesktopCol,
  MobileSliderDesktopColProps
} from '@/components/nextjs/MobileSliderDesktopCol';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBMobileSliderDesktopColProps = MobileSliderDesktopColProps &
  BlokCommonProps &
  SpaceBottomProp;

export const SBMobileSliderDesktopCol: FC<BlokProps<SBMobileSliderDesktopColProps>> = ({
  blok
}) => {
  const { preline, title, description, items, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <MobileSliderDesktopCol
      id={id}
      preline={preline}
      title={title}
      description={description}
      items={items}
      className={styles}
    />
  );
};
