import { PageItem } from '@/lib/api/types/basic';
import { PageItemDocument } from '@/lib/api/types/hooks';
import { PageItemQuery, PageItemQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * If data is not undefined, then data is a PageItem.
 * @param {any} data - any - this is the data that we're checking to see if it's a PageItem.
 * @returns A function that takes in a parameter of type any and returns a boolean.
 */
const isPageItem = (data: any): data is PageItem => {
  return data !== undefined;
};

/**
 * It gets a page item from the API
 * @param {PageItemQueryVariables} variables - PageItemQueryVariables
 * @returns A promise that resolves to a PageItem or null.
 */
export const getPage = async (variables: PageItemQueryVariables): Promise<PageItem | null> => {
  const { data } = await Api.getPageItem<PageItemQuery, PageItemQueryVariables>(
    PageItemDocument,
    variables
  );

  return isPageItem(data?.PageItem) ? data?.PageItem : null;
};
