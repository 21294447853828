import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

interface CustomLinkProps extends LinkProps {
  readonly children: ReactNode;
}

export const CustomLink = ({ children, ...rest }: CustomLinkProps) => (
  <Link {...rest}>{children}</Link>
);
