import { createSha256 } from '@/lib/utils/createSha256';

export const addStringToLocalStorageArray = (key: string, value: string, hashed = false): void => {
  const storedArrayJSON = window.localStorage.getItem(key);
  const storedArray = storedArrayJSON ? (JSON.parse(storedArrayJSON) as string[]) : [];

  const str: string = hashed ? createSha256(value) : value;
  if (!storedArray.includes(str)) {
    storedArray.push(str);
  }

  window.localStorage.setItem(key, JSON.stringify(storedArray));
};

export const isStringInLocalStorageArray = (
  key: string,
  value: string,
  hashed = false
): boolean => {
  const storedArrayJSON = window.localStorage.getItem(key);
  if (!storedArrayJSON) return false;

  const storedArray = JSON.parse(storedArrayJSON) as string[];

  const str: string = hashed ? createSha256(value) : value;

  return storedArray.includes(str);
};
