import clsx from 'clsx';
import { FC } from 'react';

import { Chips } from '@/components/shared/Chips';

export interface FaqCategoryBadgeProps {
  category?: string;
}

export const FaqCategoryBadge: FC<FaqCategoryBadgeProps> = ({ category }) => {
  if (!category) {
    return null;
  }

  const classes = clsx('not-last:mr-4 hover:text-black focus:text-black cursor-auto');

  return <Chips className={classes} label={category} isSelected={false} as={'span'} />;
};
