import { FC } from 'react';

import { Divider, DividerProps } from '@/components/shared/Divider';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBDividerProps = DividerProps & BlokCommonProps;

export const SBDivider: FC<BlokProps<SBDividerProps>> = ({ blok }) => {
  const { space } = blok;

  return <Divider space={space} />;
};
