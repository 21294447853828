import { boolean, object, SchemaOf, string } from 'yup';

import { TFunc } from '@/components/layout/DatasourceEntriesProvider/DatasourceEntriesProvider';

import { ContactLayerFormData } from './types';

export const contactLayerFormValidationSchema = (t: TFunc) =>
  <SchemaOf<ContactLayerFormData>>object({
    name: string().required(t('required_field')),
    email: string().email(t('invalid_email')).required(t('required_field')),
    content: string().required(t('required_field')),
    agreement: boolean().oneOf([true], t('required_field'))
  });
