import clsx from 'clsx';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { StoryblokLink } from '@/lib/types/utils';

import { PaginationButton } from './components/PaginationButton';

export interface GallerySliderProps {
  id?: string;
  uuid?: string;
  images: BlokDynamicZone[];
  topContant?: BlokDynamicZone[];
  linkLabel?: string;
  link?: StoryblokLink;
  className?: string;
}

SwiperCore.use([Navigation]);

// Task: BIWR-21
export const GallerySlider = ({
  id,
  uuid,
  images,
  linkLabel,
  link,
  className,
  topContant
}: GallerySliderProps) => {
  const withLink = linkLabel !== undefined && link !== undefined;
  const wrapperStyles = clsx(
    'w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] lg:pr-[6rem]',
    className
  );

  const withItems = Array.isArray(images) && images.length > 0;
  const withArrows = Array.isArray(images) && images.length > 3;
  const withTopContent = Array.isArray(topContant) && topContant.length > 0;

  return (
    <section className={wrapperStyles} id={id}>
      <div className="max-w-[130.2rem] w-full mx-auto">
        {withTopContent && topContant.map((blok) => <BlokProvider key={blok._uid} blok={blok} />)}
        <div className="mt-[4.4rem] lg:mt-[2.4rem] lg:relative lg:-translate-x-[4rem]">
          {withItems && (
            <>
              <Swiper
                speed={500}
                grabCursor
                slidesPerView={1.1}
                spaceBetween={16}
                breakpoints={{
                  370: {
                    slidesPerView: 1.3,
                    spaceBetween: 30
                  },
                  520: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  620: {
                    slidesPerView: 2.2,
                    spaceBetween: 30
                  },
                  820: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
                }}
                navigation={{
                  enabled: true,
                  nextEl: `#swpier-next-${uuid}`,
                  prevEl: `#swpier-prev-${uuid}`
                }}
                className="!pb-[4rem] lg:!px-[4rem]"
              >
                {withItems &&
                  images.map((blok) => (
                    <SwiperSlide key={blok._uid} className="py-2 lg:py-4">
                      <BlokProvider blok={blok} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              {withArrows && (
                <>
                  <PaginationButton id={`swpier-prev-${uuid}`} />
                  <PaginationButton id={`swpier-next-${uuid}`} variant="next" />
                </>
              )}
            </>
          )}
        </div>
        {withLink && (
          <SBLink link={link}>
            <ButtonLink className="mt-[3.2rem]">{linkLabel}</ButtonLink>
          </SBLink>
        )}
      </div>
    </section>
  );
};
