import clsx from 'clsx';
import { FC, MouseEventHandler } from 'react';

interface Props {
  label: string;
  isOpen?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

// Task: BIWR-23
export const ShowMore: FC<Props> = ({ isOpen = false, onClick, label, className }) => {
  const buttonStyles = clsx(
    'font-bold text-primary text-[1.6rem] lg:text-[1.8rem] leading-[3.2rem]',
    'hover-text-hoverPrimary',
    'focus-text-hoverPrimary',
    className
  );

  return (
    <button onClick={onClick} className={buttonStyles} aria-label="show more">
      <span className="inline-block mr-[0.4rem] text-[2rem] lg:text-[2.4rem]">
        {isOpen ? '-' : '+'}
      </span>
      {label}
    </button>
  );
};
