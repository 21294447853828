import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { ImageSection, ImageSectionProps } from '@/components/nextjs/ImageSection';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBImageSectionProps = ImageSectionProps & BlokCommonProps & SpaceBottomProp;

export const SBImageSection: FC<BlokProps<SBImageSectionProps>> = ({ blok }) => {
  const { image, position, objectFit, spaceBottom, aspectRatio, id, priority } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <ImageSection
      id={id}
      priority={priority}
      image={image}
      position={position}
      objectFit={objectFit}
      className={styles}
      aspectRatio={aspectRatio}
    />
  );
};
