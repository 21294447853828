import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';
import { SBLink } from '@/components/shared/SBLink';

import { RollingText } from '../../types';

interface HeroRollingTextsProps {
  item: RollingText;
  className?: string;
}

const textVariants = {
  hidden: {
    y: '100%',
    transition: {
      duration: 0.4,
      ease: [0.6, 0.01, -0.05, 0.95]
    }
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.6, 0.01, -0.05, 0.95]
    }
  },
  exit: {
    y: '-100%',
    transition: {
      duration: 0.4,
      ease: [0.6, 0.01, -0.05, 0.95]
    }
  }
};

export const HeroRollingTexts: FC<HeroRollingTextsProps> = ({ item, className }) => {
  if (!item) {
    return null;
  }

  const words = [item.label];

  return (
    <div className={className}>
      <AnimatePresence initial={false} mode="wait">
        <motion.div
          layout
          key={item._uid}
          className="overflow-hidden"
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <SBLink link={item.link}>
            <motion.a variants={textVariants} className="text-white flex flex-wrap">
              {words.map((word, index) => (
                <span
                  key={word}
                  className={clsx(
                    'bg-primary mb-[0.8rem] lg:mb-[1.6rem] w-full md:w-auto',
                    index === 0 && 'pr-6'
                  )}
                >
                  <span key={item._uid} className="flex items-center">
                    {word}
                    {index === words.length - 1 && (
                      <ArrowIcon className="w-[3.8rem] lg:w-[9.6rem] h-full -rotate-45" />
                    )}
                  </span>
                </span>
              ))}
            </motion.a>
          </SBLink>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
