import clsx from 'clsx';
import { FC } from 'react';

import { BackgroundImage } from '@/components/nextjs/HeroBannerWithText/components';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';

export interface HeroBannerWithTextProps {
  background: StoryblokImage;
  content?: BlokDynamicZone[];
}

export const HeroBannerWithText: FC<HeroBannerWithTextProps> = ({ background, content }) => {
  const wrappingClasses: string = clsx('relative');
  const contentClasses: string = clsx(
    'prose prose-headings:font-heading prose-a:border-2 prose-a:border-transparent prose-a:no-underline',
    'grid grid-cols-[80%_1fr] md:grid-cols-[70%_1fr] lg:grid-cols-[40%_1fr]',
    'py-[25px] md:py-[100px] lg:py-[200px]',
    'px-[1.6rem] md:px-[3.2rem] 2xl:px-0',
    'max-w-[160rem] w-full mx-auto inset-0'
  );

  return (
    <div className={wrappingClasses}>
      <BackgroundImage background={background} />

      <div className={contentClasses}>
        <div className={'flex flex-col justify-center'}>
          {content?.map((blok: BlokDynamicZone) => (
            <BlokProvider key={blok._uid} blok={blok} />
          ))}
        </div>
      </div>
    </div>
  );
};
