import { Organization, WithContext } from 'schema-dts';

import { domainPrefix, ORGANIZATION_LOGO, ORGANIZATION_NAME } from '@/lib/consts';

export const getOrganizationSchema = (): WithContext<Organization> => {
  const url: string = typeof window !== 'undefined' ? domainPrefix + window.location.host : '/';

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: ORGANIZATION_NAME,
    logo: ORGANIZATION_LOGO,
    url: url
  };
};
