import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { CardItems, CardItemsProps } from '@/components/nextjs/CardItems';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBCardItemsProps = CardItemsProps & BlokCommonProps & SpaceBottomProp;

export const SBCardItems: FC<BlokProps<SBCardItemsProps>> = ({ blok }) => {
  const {
    items,
    itemsWithCounter,
    filtersAvailable,
    showMoreLabel,
    maxItems,
    spaceBottom,
    topContent,
    className,
    id
  } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default'], className);

  return (
    <CardItems
      id={id}
      items={items}
      topContent={topContent}
      itemsWithCounter={itemsWithCounter}
      filtersAvailable={filtersAvailable}
      showMoreLabel={showMoreLabel}
      maxItems={maxItems}
      className={styles}
    />
  );
};
