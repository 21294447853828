import clsx from 'clsx';
import React, { FC } from 'react';

import { BlokProvider, BlokDynamicZone } from '@/components/storyblok/BlokProvider';

export interface RowProps {
  items?: BlokDynamicZone[];
  gap?: 'small' | 'normal';
}

export const Row: FC<RowProps> = ({ items = [], gap }) => {
  if (items.length === 0) {
    return null;
  }

  const wrapperStyles = clsx(
    'flex items-center flex-wrap mb-[1.6rem] lg:mb-[2.2rem]',
    gap === 'small' && 'gap-[0.8rem]',
    gap === 'normal' && 'gap-[2rem]'
  );

  return (
    <div className={wrapperStyles}>
      {items.map((blok) => (
        <BlokProvider key={blok._uid} blok={blok} />
      ))}
    </div>
  );
};
