import { RawCode, RawCodeProps } from '@/components/nextjs/RawCode';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBRawCodeProps = RawCodeProps & BlokCommonProps;

export const SBRawCode = ({ blok }: BlokProps<SBRawCodeProps>) => {
  const { code } = blok;

  return <RawCode code={code} />;
};
