import clsx from 'clsx';
import { ReactNode } from 'react';

import { useHeaderContext } from '@/components/layout/HeaderProvider';

interface CategoryFiltersWrapperProps {
  children: ReactNode;
}

export const CategoryFiltersWrapper = ({ children }: CategoryFiltersWrapperProps) => {
  const { isHeaderSticky } = useHeaderContext();

  const wrapperStyles = clsx(
    'mb-[3.2rem] w-full sticky lg:static top-0 bg-white py-[2rem] pl-[1rem] z-40',
    isHeaderSticky && '!static'
  );

  return <div className={wrapperStyles}>{children}</div>;
};
