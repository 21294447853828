import clsx from 'clsx';
import { FC } from 'react';

import { EnvelopeIcon } from '@/components/shared/Icons/EnvelopeIcon';

interface ContactLayerButtonProps {
  count?: number;
  onClick?: () => void;
  className?: string;
}

export const ContactLayerButton: FC<ContactLayerButtonProps> = ({ count, onClick, className }) => {
  const buttonStyles = clsx(
    'bg-primary px-[2.4rem] lg:px-[1rem] pt-[2rem] lg:pt-[4.8rem] pb-[1rem] lg:pb-[4.8rem]',
    className
  );

  return (
    <button className={buttonStyles} onClick={onClick} aria-label="Kontakt">
      <div className="relative">
        {count != 0 ? (
          <span className="absolute -top-1/2 -right-1/4 flex items-center justify-center bg-white w-[2.4rem] h-[2.4rem] p-1 rounded-full text-[1.6rem] leading-[2.4rem] font-bold text-primary">
            {count}
          </span>
        ) : null}
        <EnvelopeIcon />
      </div>
    </button>
  );
};
