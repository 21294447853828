import { FC } from 'react';

import {
  FooterColumn,
  FooterColumnProps
} from '@/components/layout/Footer/components/FooterColumn';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBFooterColumnProps = FooterColumnProps & BlokCommonProps;

export const SBFooterColumn: FC<BlokProps<SBFooterColumnProps>> = ({ blok }) => {
  const { links } = blok;

  return <FooterColumn links={links} />;
};
