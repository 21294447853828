import { getCaseStudies, getFooter, getHeader } from '@/lib/api/utils';
import { CaseStudiesProps, SlugParams } from '@/lib/types';

import { getBottomSliderData } from '../../getBottomSliderData';
import { getSlugFromParams } from '../../getSlugFromParams';
import { TemplatesType } from '../../getTemplateType';
import { getAsideData } from '../getAsideData';

interface GetInsightRootTemplateDataInput {
  locale: string;
  preview: boolean;
  params?: SlugParams;
  templateType: TemplatesType;
}

interface GetInsightRootTemplateDataOutput {
  props: CaseStudiesProps;
}

export const getCaseStudiesTemplateData = async ({
  locale,
  params,
  templateType,
  preview
}: GetInsightRootTemplateDataInput): Promise<GetInsightRootTemplateDataOutput> => {
  const slug = getSlugFromParams({ params });

  const footerData = await getFooter({ slug: `${locale}/layout/footer` });
  const headerData = await getHeader({ slug: `${locale}/layout/header` });
  const data = await getCaseStudies({ slug: `${locale}/${slug}` });

  const bottomSliderData = await getBottomSliderData(data?.content?.bottomSliderType, locale);

  const withAsideLinks = data?.content?.withAsideLinks ?? true;

  const aside = await getAsideData({ slug, locale, withAsideLinks });

  return {
    props: {
      data,
      bottomSliderData,
      headerData,
      footerData,
      preview,
      templateType,
      aside
    }
  };
};
