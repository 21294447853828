import { AsideProps } from '@/components/layout/Aside';

import { getRandomAsideItems } from './utils/getRandomAsideItems';
import { getRecentAsideItems } from './utils/getRecentAsideItems';
import { getRelatedAsideItems } from './utils/getRelatedAsideItems';

interface GetAsideDataInput {
  slug: string;
  locale: string;
  withAsideLinks?: boolean;
}

export const getAsideData = async ({ slug, locale, withAsideLinks = true }: GetAsideDataInput) => {
  if (!withAsideLinks) {
    return {
      slug,
      random: null,
      recent: null,
      related: null,
      withAsideLinks
    } as AsideProps;
  }

  const recent = await getRecentAsideItems({ slug, locale });
  const related = await getRelatedAsideItems({
    slug,
    locale,
    excludingSlugs: recent?.map((item) => item.slug) || []
  });
  const random = await getRandomAsideItems({
    excludingSlugs: [...recent!, ...related!].map((item) => item.slug),
    slug
  });

  return {
    slug,
    random,
    recent,
    related,
    withAsideLinks
  } as AsideProps;
};
