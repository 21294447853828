import clsx from 'clsx';
import { ReactNode } from 'react';

import { TabID, useTabs } from '../../Tabs';

export interface TabProps {
  id: TabID;
  children?: ReactNode;
  className?: string;
}

export const Tab = ({ id, children, className }: TabProps) => {
  const { activeTab, setActiveTab } = useTabs();
  const isActive = activeTab === id;

  const buttonStyles = clsx(
    'text-[3.2rem] lg:text-[6rem] leading-[3.8rem] lg:leading-[6.4rem] font-bold transition-colors outline-none whitespace-nowrap',
    'hover:text-hoverPrimary',
    'focus:text-primary',
    !isActive && 'text-gray-200',
    isActive && 'text-black',
    className
  );

  const handleChangeTab = () => setActiveTab(id);

  return (
    <button
      className={buttonStyles}
      onClick={handleChangeTab}
      role="tab"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
    >
      {children}
    </button>
  );
};
