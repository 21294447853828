import clsx from 'clsx';
import { FC } from 'react';

import { columnGap, SpacingVariants } from '@/styles/spacing';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';
import { BlokProvider, BlokDynamicZone } from '@/components/storyblok/BlokProvider';

export interface ColumnContentProps {
  items?: BlokDynamicZone[];
  className?: string;
  contentPosition?: 'top' | 'center';
  bgColor?: BackgroundColumnColor;
  gap?: SpacingVariants;
}

export const ColumnContent: FC<ColumnContentProps> = ({
  items = [],
  className,
  contentPosition = 'top',
  bgColor = 'white',
  gap = 'none'
}) => {
  if (items.length === 0) {
    return null;
  }

  const wrapperStyles = clsx(
    'w-full',
    contentPosition === 'top' && 'flex flex-col',
    contentPosition === 'center' && 'flex flex-col justify-center',
    gap && columnGap[gap],
    className
  );

  return (
    <div className={wrapperStyles}>
      {items.map((blok) => (
        <BlokProvider key={blok._uid} blok={{ bgColor, ...blok }} />
      ))}
    </div>
  );
};
