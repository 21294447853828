import { createContext, FC, ReactNode, useContext, useState } from 'react';

export type TabID = string | number;

interface TabsContext {
  activeTab: TabID;
  setActiveTab: (id: TabID) => void;
}

interface TabsProps {
  children?: ReactNode;
  defaultActive?: TabID;
  render?: (ctx: TabsContext) => ReactNode;
}

const TabsContext = createContext<TabsContext | undefined>(undefined);
TabsContext.displayName = 'TabsContext';

/**
 * It takes a defaultActive prop and a children prop, and it returns a TabsContext.Provider with the
 * activeTab and setActiveTab values
 * @param  - defaultActive - the default active tab
 * @returns The Tabs component is being returned.
 * @example
 * <Tabs defaultActive="2">
 *  <TabsGroup>
 *    <Tab id="1">Tab 1</Tab>
 *    <Tab id="2">Tab 2</Tab>
 *  </TabsGroup>
 *  <PanelGroup>
 *    <Panel id="1">Panel 1</Panel>
 *    <Panel id="2">Panel 2</Panel>
 *  </PanelGroup>
 * </Tabs>
 */
export const Tabs: FC<TabsProps> = ({ defaultActive = '', render, children }) => {
  const [activeTab, setActiveTab] = useState<TabID>(defaultActive);

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      {render ? render({ activeTab, setActiveTab }) : children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);

  if (context === undefined) {
    throw new Error('Hook useTabs must be used within a Tabs component');
  }

  return context;
};
