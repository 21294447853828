import { useEffect } from 'react';

/**
 * "When the lock prop is true, set the overflow of the body to hidden, and when the lock prop is
 * false, set the overflow of the body to the original style."
 *
 * Now, let's use this function in our modal component
 * @param {boolean} lock - boolean - This is the boolean value that will determine whether the body is
 * locked or not.
 */
export const useLockBodyScroll = (lock: boolean) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (lock) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;

      return;
    };
  }, [lock]);
};
