// import { Api } from '@quansight/shared/storyblok-sdk';

import { DatasourceEntries } from '@/lib/api/types/basic';
import { DatasourceEntriesDocument } from '@/lib/api/types/hooks';
import {
  DatasourceEntriesQuery,
  DatasourceEntriesQueryVariables
} from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * It takes a GraphQL query and variables, and returns the data from the query
 * @param {DatasourceEntriesQueryVariables} variables - DatasourceEntriesQueryVariables
 * @returns A promise that resolves to a DatasourceEntries object.
 */
export const getDataSourceEntries = async (
  variables: DatasourceEntriesQueryVariables
): Promise<DatasourceEntries | null> => {
  const { data } = await Api.getDataSourceEntries<
    DatasourceEntriesQuery,
    DatasourceEntriesQueryVariables
  >(DatasourceEntriesDocument, variables);

  return data.DatasourceEntries;
};
