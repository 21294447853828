import clsx from 'clsx';
import { FC } from 'react';

import { Chips } from '@/components/shared/Chips';

export interface FaqFilterItemProps {
  name: string;
  toggleCategory: (category: string) => void;
  active: boolean;
  className?: string;
}

export const FaqFilterItem: FC<FaqFilterItemProps> = ({
  name,
  toggleCategory,
  active,
  className = ''
}) => {
  const classes = clsx('not-last:mr-4 select-none', className);

  const handleClick = () => {
    toggleCategory(name);
  };

  return (
    <Chips className={classes} onClick={handleClick} label={name} isSelected={active} as={'a'} />
  );
};
