import { DefaultSeo } from 'next-seo';

import { useLang } from '@/hooks/useLang';

import { domainPrefix, twitterHandler } from '@/lib/consts';

import { defaultSeoData } from './defaultSeoData';

export const SeoDefault = () => {
  const { currentDomain, locale } = useLang();

  const canonical = currentDomain && `${domainPrefix}${currentDomain.domain}`;

  const { defaultMetaDescription, defaultMetaTitle } = defaultSeoData[locale];

  return (
    <DefaultSeo
      title={defaultMetaTitle}
      description={defaultMetaDescription}
      canonical={canonical}
      openGraph={{
        locale,
        type: 'website',
        url: canonical,
        title: defaultMetaTitle,
        description: defaultMetaDescription,
        images: [
          {
            url: '/public/images/open-graph.jpeg',
            alt: defaultMetaDescription,
            width: 1200,
            height: 628
          }
        ]
      }}
      twitter={{
        handle: twitterHandler,
        site: twitterHandler,
        cardType: 'summary_large_image'
      }}
    />
  );
};
