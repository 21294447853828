import { excludingSlugs } from '@/lib/api/excludingSlugs';
import { relations } from '@/lib/api/relations';
import { getFooter, getHeader, getInsights, getInsightsPosts } from '@/lib/api/utils';
import { InsightPostProps, SlugParams } from '@/lib/types';

import { getSlugFromParams } from '../../getSlugFromParams';
import { TemplatesType } from '../../getTemplateType';
import { getAsideData } from '../getAsideData';
import { getPrevNextPost } from './getPrevNextPost';

interface GetInsightPostTemplateDataInput {
  locale: string;
  preview: boolean;
  params?: SlugParams;
  templateType: TemplatesType;
}

interface GetInsightPostTemplateDataOutput {
  props: InsightPostProps;
}

/**
 * It gets the data for the Insight Post template
 * @param {GetInsightPostTemplateDataInput}  - GetInsightPostTemplateDataInput
 * @returns an object with a property called props. The props property is an object that contains the
 * data, headerConfig, headerData, footerData, preview, templateType, insightPostEntries, and
 * topInsightsPosts.
 */
export const getInsightPostTemplateData = async ({
  locale,
  params,
  templateType,
  preview
}: GetInsightPostTemplateDataInput): Promise<GetInsightPostTemplateDataOutput> => {
  const slug = getSlugFromParams({ params });

  const footerData = await getFooter({ slug: `${locale}/layout/footer` });
  const headerData = await getHeader({ slug: `${locale}/layout/header` });
  const data = await getInsights({ slug: `${locale}/${slug}`, relations });

  const topInsightsPosts = await getInsightsPosts({
    page: 1,
    perPage: 6,
    startsWith: `${locale}/`,
    excludingSlugs,
    sortBy: 'sort_by_date:desc'
  });

  const prevAndNextPost = await getPrevNextPost({ locale, currentPostId: data?.id || null });

  const withAsideLinks = data?.content?.withAsideLinks ?? true;

  const aside = await getAsideData({ slug, locale, withAsideLinks });

  return {
    props: {
      data,
      headerData,
      footerData,
      preview,
      templateType,
      topInsightsPosts,
      prevAndNextPost,
      aside
    }
  };
};
