import { FC } from 'react';

import { DropdownProps } from '../types';

interface Props {
  errorMessage?: DropdownProps['errorMessage'];
}

export const ErrorMessage: FC<Props> = ({ errorMessage }) => (
  <span className="inline-block mt-[0.4rem] text-[1.2rem] lg:text-[1.4rem] leading=[1.6rem] lg:leading=[2.2rem] text-red-500">
    {errorMessage}
  </span>
);
