import { FaqentryItems } from '@/lib/api/types/basic';
import { FaqEntriesDocument } from '@/lib/api/types/hooks';
import { FaqEntriesQuery, FaqEntriesQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

interface GetFaqEntriesInput {
  categories: string[];
}

const isFaqEntries = (data: any): data is FaqentryItems => {
  return data !== undefined;
};

export const getFaqEntries = async ({
  categories
}: GetFaqEntriesInput): Promise<FaqentryItems | null> => {
  const { data } = await Api.getPageItems<FaqEntriesQuery, FaqEntriesQueryVariables>(
    FaqEntriesDocument,
    {
      categories
    }
  );

  return isFaqEntries(data?.FaqentryItems) ? data.FaqentryItems : null;
};
