import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { JobareaItem, JoblevelItem, JobworkplaceItem } from '@/lib/api/types/basic';

import {
  DropdownElements,
  Filters,
  GetFilteredItemsInput,
  GetInitialReducerStateInput,
  ReducerState
} from './types';

const cardsWithAvailable = ['cardJob'];

/**
 * It returns true if the component passed in is one of the cards that has filters
 * @param {string} component - The name of the component you want to check.
 */
export const isCardWithFilters = (component: string) => cardsWithAvailable.includes(component);

/**
 * It takes an array of items, and returns a new array of items that only contains unique items
 * @param {TItem} item - TItem - the current item in the array
 * @param {number} index - The index of the current element being processed in the array.
 * @param {TItem[]} self - TItem[] - this is the array that the filter is being applied to.
 */
const getUniqueItems = <TItem extends DropdownElements>(
  item: TItem,
  index: number,
  self: TItem[]
) => index === self.findIndex((t) => t.id === item.id);

/**
 * It takes an array of BlokDynamicZone objects, and a key, and returns an array of DropdownElements
 * objects
 * @param {BlokDynamicZone[]} items - BlokDynamicZone[] - the array of items that we want to filter
 * @param {string} key - string - the name of the key in the BlokDynamicZone object that contains the
 * dropdown items.
 * @returns An array of objects that have been filtered by the getFilteredItems function.
 */
const getDropdownItems = <TabItem extends DropdownElements>(
  items: BlokDynamicZone[],
  key: string
): TabItem[] | null => {
  const dropdownItems: TabItem[] = [];

  Array.from(items).forEach((item) => {
    if (!isCardWithFilters(item.component)) {
      return null;
    }

    if (item[key]) {
      Array.from(item[key]).forEach((element) => {
        dropdownItems.push(element as TabItem);
      });
    }
  });

  return dropdownItems.filter(getUniqueItems);
};

/**
 * It takes an array of BlokDynamicZone objects and returns an object with three arrays of JobareaItem,
 * JoblevelItem and JobworkplaceItem objects
 * @param {BlokDynamicZone[]} [items] - BlokDynamicZone[]
 * @returns An object with three properties: jobAreaItems, jobLevelItems, and jobWorkspaceItems.
 */
export const getFilteredDropdownItems = (items?: BlokDynamicZone[]) => {
  if (!items) {
    return {
      jobAreaItems: null,
      jobLevelItems: null,
      jobWorkspaceItems: null
    };
  }

  return {
    jobAreaItems: getDropdownItems<JobareaItem>(items, 'jobArea'),
    jobLevelItems: getDropdownItems<JoblevelItem>(items, 'jobLevel'),
    jobWorkspaceItems: getDropdownItems<JobworkplaceItem>(items, 'jobWorkspace')
  };
};

/**
 * It takes an item of type `TItem` and returns a string
 * @param {TItem | null} item - The item that is currently selected.
 */
export const itemToString = <TItem extends DropdownElements>(item: TItem | null) =>
  (item && item.content?.name) || '';

export const getInitialReducerState = ({
  items,
  maxItems
}: GetInitialReducerStateInput): ReducerState => ({
  isShowMoreLabelVisible: maxItems < (items?.length ?? 0),
  maxItems,
  items: items ?? [],
  filteredItems: items?.slice(0, maxItems) ?? [],
  filters: {
    jobLevel: null,
    jobArea: null,
    jobWorkspace: null
  }
});

/**
 * It takes a state object and a new state object, and returns a new state object that is a combination
 * of the two
 * @param {ReducerState} state - ReducerState - this is the current state of the reducer.
 * @param newState - Partial<ReducerState>
 * @returns A function that takes in a state and newState and returns a new state with the newState
 * merged into it.
 */
export const updateState = (state: ReducerState, newState: Partial<ReducerState>): ReducerState => {
  return {
    ...state,
    ...newState
  };
};

/**
 * If the length of the array of filter elements that are truthy is 0, then no filter is active.
 * @param {Filters} filters - Filters - this is the object that contains all the filters.
 */
export const anyFilterIsActive = (filters: Filters) =>
  Object.values(filters).filter((filterElement) => !!filterElement).length === 0;

/**
 * "Return an array of items that have all the active filters."
 *
 * The function takes an object with two properties: items and filters. The items property is an array
 * of objects. The filters property is an object with three properties: jobArea, jobLevel, and
 * jobWorkspace. Each of these properties is an array of objects
 * @param {GetFilteredItemsInput}  - GetFilteredItemsInput
 * @returns An array of items that match the filters.
 */
export const getFilteredItems = ({ items, filters }: GetFilteredItemsInput) => {
  const { jobArea, jobLevel, jobWorkspace } = filters;

  const activeFilters = [jobArea, jobLevel, jobWorkspace].filter((itemFilter) => itemFilter);

  const filteredItems = items.filter((item) => {
    const itemOptions = [...item.jobArea, ...item.jobLevel, ...item.jobWorkspace].map(
      (el) => el.id
    );

    const selectedItemOptions = activeFilters.filter((opt) => itemOptions.includes(opt));

    return selectedItemOptions.length === activeFilters.length;
  });

  return filteredItems;
};
