import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import {
  MobileSliderDesktopRow,
  MobileSliderDesktopRowProps
} from '@/components/nextjs/MobileSliderDesktopRow';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBMobileSliderDesktopRowProps = MobileSliderDesktopRowProps &
  BlokCommonProps &
  SpaceBottomProp;

export const SBMobileSliderDesktopRow: FC<BlokProps<SBMobileSliderDesktopRowProps>> = ({
  blok
}) => {
  const { preline, title, description, items, bottomLinks, topLinks, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <MobileSliderDesktopRow
      id={id}
      preline={preline}
      title={title}
      description={description}
      items={items}
      bottomLinks={bottomLinks}
      topLinks={topLinks}
      className={styles}
    />
  );
};
