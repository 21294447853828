import clsx from 'clsx';
import { ChangeEvent, FC, InputHTMLAttributes, forwardRef } from 'react';

type InputColorVariant = 'white' | 'gray';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  onChange?: (e: ChangeEvent) => void;
  label?: string;
  colorVariant?: InputColorVariant;
  isError?: boolean;
  helperText?: string;
  className?: string;
  classNameLabel?: string;
  classNameInput?: string;
  classNameHelperText?: string;
}

// Task: BIWR-5
export const Textarea: FC<Props> = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className,
      colorVariant = 'white',
      classNameLabel,
      classNameInput,
      classNameHelperText,
      onChange,
      id,
      placeholder,
      isError = false,
      label,
      helperText,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const labelStyles = clsx(
      'inline-block mb-[0.4rem] text-[1.4rem] leading-[2.2rem] text-black cursor-pointer',
      disabled && 'cursor-not-allowed',
      classNameLabel
    );

    const textareaStyles = clsx(
      'w-full min-h-[15rem] px-[1.6rem] py-[1.2rem] outline-none text-[1.6rem] lg:text-[1.8rem] border border-transparent bg-white text-black leading-[2.4rem]',
      'placeholder:text-black',
      'focus:border focus:border-gray-800',
      'disabled:text-gray-200 disabled:border disabled:border-gray-200 disabled:cursor-not-allowed',
      colorVariant === 'gray' && 'bg-gray-100',
      isError && 'border border-red-500 focus:border-red-500 text-red-500',
      classNameInput
    );

    const helperTextStyles = clsx(
      'inline-block mt-[0.4rem] text-black text-[1.4rem] leading-[2.2rem]',
      disabled && 'text-gray-200',
      isError && 'text-red-500',
      classNameHelperText
    );

    return (
      <fieldset className={className} disabled={disabled}>
        {label && (
          <label className={labelStyles} htmlFor={id}>
            {label}
          </label>
        )}
        <div className="relative">
          <textarea
            ref={ref}
            onChange={onChange}
            id={id}
            placeholder={placeholder}
            className={textareaStyles}
            {...rest}
          />
        </div>
        {helperText && <span className={helperTextStyles}>{helperText}</span>}
      </fieldset>
    );
  }
);

Textarea.displayName = 'Textarea';
