export type ColorVariants = 'primary' | 'light' | 'red' | 'black' | 'white' | 'default';

export interface ColorTextProp {
  colorText?: ColorVariants;
}

export const colorWithHeadingsMap: Record<ColorVariants, string> = {
  primary:
    'text-primary prose-h1:text-primary prose-h2:text-primary prose-h3:text-primary prose-h4:text-primary prose-h5:text-primary prose-h6:text-primary prose-a:text-primary',
  light:
    'text-light prose-h1:text-light prose-h2:text-light prose-h3:text-light prose-h4:text-light prose-h5:text-light prose-h6:text-light prose-a:text-light',
  red: 'text-red prose-h1:text-red prose-h2:text-red prose-h3:text-red prose-h4:text-red prose-h5:text-red prose-h6:text-red prose-a:text-red',
  black:
    'text-black prose-h1:text-black prose-h2:text-black prose-h3:text-black prose-h4:text-black prose-h5:text-black prose-h6:text-black prose-a:text-black',
  white:
    'text-white prose-h1:text-white prose-h2:text-white prose-h3:text-white prose-h4:text-white prose-h5:text-white prose-h6:text-white prose-a:text-white',
  default: 'text-black '
};

export const colorTextMap: Record<ColorVariants, string> = {
  primary: 'text-primary',
  light: 'text-light',
  red: 'text-red',
  black: 'text-black',
  white: 'text-white',
  default: 'text-black'
};
