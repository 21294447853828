import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  className?: string;
}

export const ArrowDownIcon: FC<Props> = ({ className }) => {
  const classes: string = clsx('feather feather-arrow-down', className);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classes}
    >
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>
  );
};
