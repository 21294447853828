import { FooterItem } from '@/lib/api/types/basic';
import { FooterItemDocument } from '@/lib/api/types/hooks';
import { FooterItemQuery, FooterItemQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * It gets the footer item from the API
 * @param {FooterItemQueryVariables} variables - FooterItemQueryVariables - this is the type of the
 * variables that are passed to the query.
 * @returns FooterItem
 */
export const getFooter = async (
  variables: FooterItemQueryVariables
): Promise<FooterItem | null> => {
  const { data } = await Api.getPageItem<FooterItemQuery, FooterItemQueryVariables>(
    FooterItemDocument,
    variables
  );

  return data.FooterItem;
};
