import React, { FC } from 'react';

import { USPItem, USPItemProps } from '@/components/shared/UspItem';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBUSPItemProps = USPItemProps & BlokCommonProps;

export const SBUSPItem: FC<BlokProps<SBUSPItemProps>> = ({ blok }) => {
  const { colorVariant, image, headline, text, className } = blok;

  return (
    <USPItem
      colorVariant={colorVariant}
      image={image}
      headline={headline}
      text={text}
      className={className}
    />
  );
};
