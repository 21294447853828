import { createContext, ReactNode, useContext } from 'react';

import { HeaderConfig } from '../Header/Header';

interface HeaderContext {
  headerConfig?: HeaderConfig | null;
}

interface HeaderProviderProps {
  headerConfig?: HeaderConfig | null;
  children: ReactNode;
}

/* Creating a context object that can be used to pass data through the component tree without having to
pass props down manually at every level. */
const HeaderContext = createContext<HeaderContext | undefined>(undefined);
HeaderContext.displayName = 'HeaderContext';

/**
 * It takes a headerConfig prop and passes it to the HeaderContext.Provider
 * @param {HeaderProviderProps}  - children - the children of the component
 */
export const HeaderProvider = ({ children, headerConfig }: HeaderProviderProps) => (
  <HeaderContext.Provider value={{ headerConfig }}>{children}</HeaderContext.Provider>
);

/**
 * It returns a boolean value that indicates whether the header is sticky or not
 * @returns The isSticky property is being returned.
 */
export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error('Hook useHeaderContext must be used within a HeaderProvider component');
  }

  const { headerConfig } = context || {};

  return {
    isHeaderSticky: headerConfig?.variant === 'sticky'
  };
};
