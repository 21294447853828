import { HeaderItem } from '@/lib/api/types/basic';
import { HeaderItemDocument } from '@/lib/api/types/hooks';
import { HeaderItemQuery, HeaderItemQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * It gets the header item from the API
 * @param {HeaderItemQueryVariables} variables - HeaderItemQueryVariables
 * @returns A promise that resolves to a HeaderItem or null.
 */
export const getHeader = async (
  variables: HeaderItemQueryVariables
): Promise<HeaderItem | null> => {
  const { data } = await Api.getPageItem<HeaderItemQuery, HeaderItemQueryVariables>(
    HeaderItemDocument,
    variables
  );

  return data.HeaderItem;
};
