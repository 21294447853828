import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { GlobalDataContent, GlobalDataContentProps } from '@/components/nextjs/GlobalDataContent';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBGlobalDataContentProps = GlobalDataContentProps & BlokCommonProps & SpaceBottomProp;

export const SBGlobalDataContent: FC<BlokProps<SBGlobalDataContentProps>> = ({ blok }) => {
  const { data, spaceBottom } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  if (!data?.content?.body) {
    return null;
  }

  return <GlobalDataContent className={styles} items={data.content.body} />;
};
