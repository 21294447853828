/**
 * If the number is less than 10, return a string with a leading zero, otherwise return a string with
 * the number.
 * @param {number | string} [number] - The number to be formatted.
 * @returns A string
 */
export const getNumber = (number?: number | string) => {
  if (!number) {
    return;
  }

  if (number < 10) {
    return `/0${number}`;
  }

  return `/${number}`;
};
