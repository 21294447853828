import React, { FC } from 'react';

import {
  ColumnContentChips,
  ColumnContentChipsProps
} from '@/components/nextjs/ColumnContentChips';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBColumnContentChipsProps = ColumnContentChipsProps & BlokCommonProps;

export const SBColumnContentChips: FC<BlokProps<SBColumnContentChipsProps>> = ({ blok }) => {
  const { link, name, spaceBottom } = blok;

  return <ColumnContentChips link={link} name={name} spaceBottom={spaceBottom} />;
};
