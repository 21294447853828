import clsx from 'clsx';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { JobCardItems } from '@/components/nextjs/JobCardItems';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBJobCardItemsProps = BlokCommonProps & SpaceBottomProp;

export const SBJobCardItems = ({ blok }: BlokProps<SBJobCardItemsProps>) => {
  const { items, filtersAvailable, maxItems, showMoreLabel, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <JobCardItems
      id={id}
      items={items}
      filtersAvailable={filtersAvailable}
      maxItems={maxItems}
      showMoreLabel={showMoreLabel}
      className={styles}
    />
  );
};
