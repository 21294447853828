import { ReactNode } from 'react';

import { TabID, useTabs } from '../../Tabs';

export interface PanelProps {
  id: TabID;
  children?: ReactNode;
  className?: string;
}

export const Panel = ({ children, id, className }: PanelProps) => {
  const { activeTab } = useTabs();

  if (activeTab !== id) {
    return null;
  }

  return (
    <div role="tabpanel" className={className}>
      {children}
    </div>
  );
};
