import { getLocaleUrl } from '@/components/shared/SBLink';

import { getContentNodes } from '@/lib/api/utils/getContentNodes';
import { blockedSlugsList } from '@/lib/consts';

interface GetRelatedAsideItemsInput {
  slug: string;
  locale: string;
  excludingSlugs: string[];
}

export const getRelatedAsideItems = async ({
  slug,
  locale,
  excludingSlugs
}: GetRelatedAsideItemsInput) => {
  const slugArr = slug.split('/');

  const relatedSlug = slugArr.slice(0, slugArr.length - 1).join('/');

  const excludingSlugsVariable = [slug, ...excludingSlugs, 'layout/*', 'dev/*']
    .filter(Boolean)
    .map((item) => `de/${item}`)
    .join(',');

  const relatedDataItems = await getContentNodes({
    perPage: 5,
    page: 1,
    startsWith: `${locale}/${relatedSlug}`,
    sortBy: 'first_published_at:desc',
    excludingSlugs: excludingSlugsVariable,
    filterQuery: {
      component: {
        in: 'caseStudies,insights,insightsCategory,page'
      },
      excludeFromAside: {
        is: false
      }
    }
  });

  const related = relatedDataItems?.items?.map((item) => ({
    id: item?.uuid,
    slug: getLocaleUrl(item?.full_slug || '', blockedSlugsList),
    name: item?.name
  }));

  return related || null;
};
