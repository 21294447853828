import clsx from 'clsx';
import { FC } from 'react';

import { RichText } from '@/components/shared/RichText';

import { RichText as IRichText } from '@/lib/types/richContent';
import { StoryblokLink } from '@/lib/types/utils';

import { DesktopItems } from './components/DesktopItems';
import { MobileItems } from './components/MobileItems';
import { SliderLinks } from './components/SliderLinks';

interface NavLink {
  name: string;
  link: StoryblokLink;
  variant?: 'text' | 'arrow';
  component: string;
  _uid: string;
  _editable: string;
}

export interface MobileSliderDesktopRowProps {
  preline?: string;
  title?: string;
  description?: IRichText;
  items: any[];
  bottomLinks?: NavLink[];
  topLinks?: NavLink[];
  className?: string;
  id?: string;
}

// Task: BIWR-12
export const MobileSliderDesktopRow: FC<MobileSliderDesktopRowProps> = ({
  preline,
  title,
  description,
  items,
  bottomLinks,
  topLinks,
  className,
  id
}) => {
  const withBottomLinks = bottomLinks && bottomLinks.length > 0;
  const withTopLinks = topLinks && topLinks.length > 0;
  const wrapperStyles = clsx(
    'max-w-[160rem] w-full mx-auto pl-[1.6rem] md:px-[3.6rem] overflow-x-hidden',
    className
  );

  return (
    <section className={wrapperStyles} id={id}>
      <div className="flex flex-col gap-[0.8rem] mb-[1.6rem] lg:mb-[3.2rem]">
        {preline && (
          <span className="inline-block text-[1.2rem] lg:text-[1.6rem] leading-[2rem] lg:leading-[2.8rem] text-primary tracking-[0.3em] uppercase font-heading">
            {preline}
          </span>
        )}
        {title && (
          <h3 className="font-bold text-black text-[3.2rem] lg:text-[6rem] leading-[3.8rem] lg:leading-[6.4rem]">
            {title}
          </h3>
        )}
      </div>
      {description && <RichText content={description} />}
      {withTopLinks && <SliderLinks links={topLinks} />}
      <MobileItems items={items} />
      <DesktopItems items={items} />
      {withBottomLinks && <SliderLinks links={bottomLinks} className="mt-[3.2rem] lg:mt-[4rem]" />}
    </section>
  );
};
