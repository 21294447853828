import { createContext, FC, ReactNode, useContext } from 'react';

import { DatasourceEntries } from '@/lib/api/types/basic';
import { getDataSourceEntry } from '@/lib/utils/getDataSourceEntry/getDataSourceEntry';

interface DatasourceEntriesProviderProps {
  children: ReactNode;
  datasourceEntries?: DatasourceEntries | null;
}

interface DatasourceEntriesContext {
  datasourceEntries?: DatasourceEntries | null;
}

export type TFunc = (key: string) => string | undefined;

interface UseDatasourceEntriesOutput extends DatasourceEntriesContext {
  t: TFunc;
}

const DatasourceEntriesContext = createContext<DatasourceEntriesContext | undefined>(undefined);
DatasourceEntriesContext.displayName = 'DatasourceEntriesContext';

export const DatasourceEntriesProvider: FC<DatasourceEntriesProviderProps> = ({
  children,
  datasourceEntries
}) => (
  <DatasourceEntriesContext.Provider value={{ datasourceEntries }}>
    {children}
  </DatasourceEntriesContext.Provider>
);

export const useDatasourceEntries = (): UseDatasourceEntriesOutput => {
  const context = useContext(DatasourceEntriesContext);

  if (context === undefined) {
    throw new Error(
      'Hook useDatasourceEntries must be used within a DatasourceEntriesProvider component'
    );
  }

  return {
    ...context,
    t: (key: string) => getDataSourceEntry(key, context.datasourceEntries)
  };
};
