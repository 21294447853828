import clsx from 'clsx';

import { AspectRatio, aspectRatioVariants } from '@/styles/aspectRatio';

import { Image } from '@/components/shared/Image';

import { StoryblokImage } from '@/lib/types/utils';

export interface ColumnImageProps {
  image?: StoryblokImage;
  priority?: boolean;
  figure?: boolean;
  imageObjectFit?: 'cover' | 'contain';
  imageSize?: 'small' | 'normal' | 'full';
  aspectRatio?: AspectRatio;
  caption?: string;
}

export const ColumnImage = ({
  image,
  imageObjectFit = 'cover',
  aspectRatio = 'video',
  priority = false,
  figure,
  caption
}: ColumnImageProps) => {
  if (!image?.filename) {
    return null;
  }

  const imageStyles = clsx('relative', aspectRatioVariants[aspectRatio || 'video']);
  const captionStyles = 'my-4 text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]'; // Body text styles

  if (figure) {
    const imageCaption = caption || image.alt;

    return (
      <figure>
        <div className={imageStyles}>
          <Image
            priority={priority}
            src={image.filename}
            alt={image.alt || ''}
            layout="fill"
            objectFit={imageObjectFit}
            objectPosition="center"
          />
        </div>
        {imageCaption && <figcaption className={captionStyles}>{imageCaption}</figcaption>}
      </figure>
    );
  }

  return (
    <div className={imageStyles}>
      <Image
        priority={priority}
        src={image.filename}
        alt={image.alt || ''}
        layout="fill"
        objectFit={imageObjectFit}
        objectPosition="center"
      />
    </div>
  );
};
