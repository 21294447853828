import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';

import { spacingBottom, SpaceBottomProp } from '@/styles/spacing';

import { HeroTextImage, HeroTextImageProps } from '@/components/nextjs/HeroTextImage';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBHeroTextImageProps = HeroTextImageProps & BlokCommonProps & SpaceBottomProp;

export const SBHeroTextImage: FC<BlokProps<SBHeroTextImageProps>> = ({ blok }) => {
  const {
    headline,
    preline,
    contentPosition,
    image,
    itemsPosition,
    items,
    itemsWithCounter,
    spaceBottom,
    containerPosition,
    bottomLinks,
    largeGap,
    id,
    tag,
    prelineColor,
    headlineColor
  } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  const prelineStyle = prelineColor?.value ? { '--text-sb-color': prelineColor?.value } : undefined;
  const prelineClassName = prelineColor?.value ? '!text-[var(--text-sb-color)]' : undefined;

  const headlineStyle = headlineColor?.value
    ? { '--text-sb-color': headlineColor?.value }
    : undefined;
  const headlineClassName = headlineColor?.value ? '!text-[var(--text-sb-color)]' : undefined;

  return (
    <HeroTextImage
      id={id}
      containerPosition={containerPosition}
      headline={headline}
      tag={tag}
      preline={preline}
      contentPosition={contentPosition}
      image={image}
      itemsPosition={itemsPosition}
      items={items}
      itemsWithCounter={itemsWithCounter}
      className={styles}
      bottomLinks={bottomLinks}
      largeGap={largeGap}
      prelineStyle={{ className: prelineClassName, style: prelineStyle as CSSProperties }}
      headlineStyle={{ className: headlineClassName, style: headlineStyle as CSSProperties }}
    />
  );
};
