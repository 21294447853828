import { SlugParams } from '@/lib/types';

interface GetSlugFromParamsInput {
  params?: SlugParams;
}

/**
 * It takes an object with a `params` property, and returns the `slug` property of that `params`
 * object, if it exists
 * @param {GetSlugFromParamsInput}  - GetSlugFromParamsInput
 */
export const getSlugFromParams = ({ params }: GetSlugFromParamsInput) =>
  (params && Array.isArray(params.slug) && params.slug.join('/')) || '';
