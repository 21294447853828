import { excludingSlugs } from '@/lib/api/excludingSlugs';
import { getInsightsPosts } from '@/lib/api/utils';

interface GetPrevNextPostInput {
  locale: string;
  currentPostId: number | null;
}

export interface GetPrevNextPostOutput {
  next: string | null;
  prev: string | null;
}

/**
 * It gets all the posts from Storyblok, then finds the current post in the array of posts, then
 * returns the next and previous posts
 * @param {GetPrevNextPostInput}  - GetPrevNextPostInput
 * @returns An object with two properties: next and prev.
 */
export const getPrevNextPost = async ({
  locale,
  currentPostId
}: GetPrevNextPostInput): Promise<GetPrevNextPostOutput> => {
  const perPage = 100;
  const requests = [];

  const initial = await getInsightsPosts({
    perPage: 1,
    page: 1,
    startsWith: `${locale}/`
  });

  const totalPostItems = initial?.total || 100;
  const totalPages = Math.ceil(totalPostItems / perPage);

  for (let i = 1; i <= totalPages; i++) {
    requests.push(
      getInsightsPosts({
        perPage,
        page: i,
        startsWith: `${locale}/`,
        excludingSlugs
      })
    );
  }

  const responses = await Promise.all(requests);
  const allPosts = responses.map((res) => res?.items).flat();

  const currentPostArrayIndex = allPosts.findIndex((post) => post?.id === currentPostId);

  const res = {
    prev:
      (currentPostArrayIndex < totalPostItems - 1 &&
        allPosts[currentPostArrayIndex + 1]?.full_slug) ||
      null,
    next: (currentPostArrayIndex > 0 && allPosts[currentPostArrayIndex - 1]?.full_slug) || null
  };

  return res;
};
