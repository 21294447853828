import { CasestudiesItems } from '@/lib/api/types/basic';
import { CasestudiesItemsDocument } from '@/lib/api/types/hooks';
import { CasestudiesItemsQuery, CasestudiesItemsQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

const isCaseStudiesItems = (data: any): data is CasestudiesItems => {
  return data !== undefined;
};

export const getCaseStudiesItems = async (
  variables: CasestudiesItemsQueryVariables
): Promise<CasestudiesItems | null> => {
  const { data } = await Api.getPageItems<CasestudiesItemsQuery, CasestudiesItemsQueryVariables>(
    CasestudiesItemsDocument,
    variables
  );

  return isCaseStudiesItems(data?.CasestudiesItems) ? data.CasestudiesItems : null;
};
