import clsx from 'clsx';
import React, { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { ShareWebsite, ShareWebsiteProps } from '@/components/nextjs/ShareWebsite';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBShareWebsiteProps = ShareWebsiteProps & BlokCommonProps & SpaceBottomProp;

export const SBShareWebsite: FC<BlokProps<SBShareWebsiteProps>> = ({ blok }) => {
  const { isFull, title, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return <ShareWebsite isFull={isFull} title={title} className={styles} id={id} />;
};
