import { CasestudiesItem } from '@/lib/api/types/basic';
import { CasestudiesItemDocument } from '@/lib/api/types/hooks';
import {
  CasestudiesItemQuery,
  CasestudiesItemQueryVariables,
  InsightsItemQueryVariables
} from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

const isCaseStudiesItem = (data: any): data is CasestudiesItem => {
  return data !== undefined;
};

export const getCaseStudies = async (
  variables: CasestudiesItemQueryVariables
): Promise<CasestudiesItem | null> => {
  const { data } = await Api.getPageItem<CasestudiesItemQuery, InsightsItemQueryVariables>(
    CasestudiesItemDocument,
    variables
  );

  return isCaseStudiesItem(data?.CasestudiesItem) ? data?.CasestudiesItem : null;
};
