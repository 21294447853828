import { ApolloClient, InMemoryCache } from '@apollo/client';

export const apolloClient = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_STORYBLOK_API_URL,
  headers: {
    token: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN || '',
    version: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN_VERSION || 'draft'
  },
  cache: new InMemoryCache({
    typePolicies: {
      InsightsItem: {
        keyFields: ['content'] // fix for apollo cache issue
      }
    }
  })
});
