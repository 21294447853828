import clsx from 'clsx';
import React, { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { useSearchContext } from '@/components/layout/SearchProvider';
import { QuestionSelect, QuestionSelectProps } from '@/components/shared/QuestionSelect';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBQuestionSelect = QuestionSelectProps & BlokCommonProps & SpaceBottomProp;

export const SBQuestionSelect: FC<BlokProps<SBQuestionSelect>> = ({ blok }) => {
  const { searchedItems } = useSearchContext();
  const { isFull, title, inputLabel, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <QuestionSelect
      id={id}
      isFull={isFull}
      title={title}
      inputLabel={inputLabel}
      items={searchedItems}
      className={styles}
    />
  );
};
