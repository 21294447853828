import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';
import { FC } from 'react';

interface Props {
  variants: Variants;
}

const lineStyles = clsx(
  'block h-[0.4rem] bg-black w-full',
  'group-hover:bg-hoverPrimary transition-colors',
  'group-focus:bg-hoverPrimary'
);

export const Line: FC<Props> = ({ variants }) => (
  <motion.div className={lineStyles} variants={variants} />
);
