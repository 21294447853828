import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { SearchedItem } from '@/components/layout/SearchProvider';

import { SBLink } from '../SBLink';

const DropdownCombobox = dynamic<any>(() =>
  import('@/components/shared/FormComponents/DropdownCombobox').then((mod) => mod.DropdownCombobox)
);

export interface QuestionSelectProps {
  isFull?: boolean;
  title?: string;
  inputLabel?: string;
  items: SearchedItem[];
  className?: string;
  id?: string;
}

const itemToString = (item: SearchedItem | null) => (item ? item.name : '');

export const QuestionSelect: FC<QuestionSelectProps> = ({
  isFull = false,
  title,
  inputLabel,
  items,
  className,
  id
}) => {
  const wrapperStyles = clsx(
    'bg-gray-100 py-[5rem] px-[1.6rem] md:px-[3.6rem] mx-auto max-w-[160rem]',
    isFull && 'max-w-full',
    className
  );

  const handleRenderDropdownComboboxItem = ({ item }: { item: SearchedItem }) =>
    item && (
      <SBLink
        link={{
          cached_url: item.full_slug,
          linktype: 'story',
          fieldtype: '',
          url: '',
          id: item.full_slug
        }}
      >
        <a className="block">{item.name}</a>
      </SBLink>
    );

  return (
    <div className={wrapperStyles} id={id}>
      <div className="max-w-[160rem] mx-auto flex flex-col lg:items-center lg:flex-row lg:justify-between gap-[3.2rem]">
        <span className="text-[3.2rem] leading-[3.8rem] font-bold inline-block lg:w-1/2">
          {title}dasdas
        </span>
        <DropdownCombobox
          id={inputLabel}
          itemToString={itemToString}
          defaultLabel={inputLabel}
          items={items}
          className="lg:w-1/2"
          render={handleRenderDropdownComboboxItem}
        />
      </div>
    </div>
  );
};
