import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { GridImageRight, GridImageRightProps } from '@/components/nextjs/GridImageRight';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBGridImageRightProps = GridImageRightProps & BlokCommonProps & SpaceBottomProp;

export const SBGridImageRight: FC<BlokProps<SBGridImageRightProps>> = ({ blok }) => {
  const { image, items, spaceBottom, id, priority } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <GridImageRight priority={priority} image={image} items={items} className={styles} id={id} />
  );
};
