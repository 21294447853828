import { FC } from 'react';

import { getDynamicAttributes } from '@/lib/utils/getDynamicAttributes';

import { NavLink } from '../../Footer';
import { FooterColumnTitle } from '../FooterColumnTitle/FooterColumnTitle';
import { FooterLink } from '../FooterLink';

export interface FooterNavigationProps {
  title: string;
  items: NavLink[];
}

export const FooterNavigation: FC<FooterNavigationProps> = ({ title, items }) => (
  <div>
    <FooterColumnTitle>{title}</FooterColumnTitle>
    <ul className="flex flex-col gap-[1.6rem]">
      {items.map(({ _uid, link, name, id }) => (
        <li key={_uid}>
          {link.cached_url === '' && link.linktype !== 'email' ? (
            <span className="text-[1.6rem] lg:text-[1.8rem]">{name}</span>
          ) : (
            <FooterLink link={link} {...getDynamicAttributes({ id: id })}>
              {name}
            </FooterLink>
          )}
        </li>
      ))}
    </ul>
  </div>
);
