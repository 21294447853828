import { FC } from 'react';

import {
  FooterNavigation,
  FooterNavigationProps
} from '@/components/layout/Footer/components/FooterNavigation';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBFooterNavigationProps = FooterNavigationProps & BlokCommonProps;

export const SBFooterNavigation: FC<BlokProps<SBFooterNavigationProps>> = ({ blok }) => {
  const { title, items } = blok;

  return <FooterNavigation title={title} items={items} />;
};
