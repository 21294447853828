import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

import { ErrorMessage } from '../ErrorMessage';
import { DropdownProps } from '../types';
import { getItemContent } from '../utils';

// Task: BIWR-5
export const DropdownMobile = <T,>({
  items,
  itemToString,
  defaultLabel,
  defaultSelectedItem,
  labelText,
  errorMessage,
  id,
  className,
  onItemChange,
  disabled
}: DropdownProps<T>) => {
  const defaultValue =
    (defaultSelectedItem && getItemContent(defaultSelectedItem, itemToString)) ?? defaultLabel;

  const [isOpen, setIsOpen] = useState(false);
  const [counter, setCounter] = useState(1);

  const selectStyles = clsx(
    'py-[1.2rem] px-[1.6rem] flex justify-between items-center bg-white w-full outline-none',
    'text-black text-[1.6rem] lg:text-[1.8rem] leading=[2.4rem] lg:leading-[2.6rem]',
    'appearance-none',
    'focus:border focus:border-gray-200',
    'disabled:border disabled:border-gray-200 disabled:cursor-not-allowed',
    errorMessage && 'border border-red-500 text-red-500'
  );

  const arrowStyles = clsx(
    'text-primary select-arrow',
    isOpen && '-rotate-90',
    !isOpen && 'rotate-90'
  );

  const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const { value } = target;
    const selectedItem = items.find((item) => getItemContent(item, itemToString) === value);

    if (onItemChange && selectedItem) {
      onItemChange(selectedItem);
      setIsOpen(false);
    }
  };

  const handleCounter = () => {
    setCounter(counter + 1);

    if (counter == 1) setIsOpen(true);
    if (counter == 2) {
      setCounter(1);
      setIsOpen(false);
    }
  };

  return (
    <div className={className}>
      {labelText && (
        <label
          htmlFor={id}
          className="inline-block mb-[0.4rem] text-black text-[1.2rem] leading-[1.6rem]"
        >
          {labelText}
        </label>
      )}
      {/* TODO add arrow icon -> w8 Button PR  */}
      <div className="flex flex-row select-with-arrow">
        <select
          name={id}
          id={id}
          className={selectStyles}
          onChange={handleChange}
          onFocus={() => {
            setIsOpen(true);
          }}
          onBlur={() => {
            setIsOpen(false);
          }}
          onClick={handleCounter}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {defaultLabel && <option value="">{defaultLabel}</option>}

          {items.map((item) => {
            const itemLabel = getItemContent(item, itemToString);

            if (!itemLabel) {
              return null;
            }

            return (
              <option key={itemLabel} value={itemLabel}>
                {itemLabel}
              </option>
            );
          })}
        </select>
        <ArrowIcon className={arrowStyles} />
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  );
};
