import { ContentItems } from '@/lib/api/types/basic';
import { ContentNodesDocument } from '@/lib/api/types/hooks';
import { ContentNodesQuery, ContentNodesQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

export const getContentNodes = async (
  variables: ContentNodesQueryVariables
): Promise<ContentItems | null> => {
  const { data } = await Api.getPageItems<ContentNodesQuery, ContentNodesQueryVariables>(
    ContentNodesDocument,
    variables
  );

  return data.ContentNodes as ContentItems;
};
