import { DomainLocale } from 'next/dist/server/config';
import { useRouter } from 'next/router';

export type Locale = 'de' | 'de-at' | 'en';

interface UseLocaleOutput {
  locale: Locale;
  locales: Locale[];
  domainLocales?: DomainLocale[];
  currentDomain?: DomainLocale;
}

export const defaultLang: Locale = 'de';

/**
 * It returns the current locale, all locales, all domain locales, and the current domain
 */
export const useLang = (): UseLocaleOutput => {
  const { locale, locales, domainLocales } = useRouter();

  const activeLocale = (locale as Locale) || defaultLang;
  const allLocales = (locales as Locale[]) || [];

  const currentDomain = domainLocales?.find((domain) => domain.defaultLocale === locale);

  return {
    locale: activeLocale,
    locales: allLocales,
    domainLocales,
    currentDomain:
      currentDomain ??
      domainLocales?.find((dom) => dom.locales?.find((currentLocale) => currentLocale === locale))
  };
};
