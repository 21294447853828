import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { FaqFilters } from '@/components/nextjs/FaqAccordion/Components/FaqFilters';
import { FaqItem } from '@/components/nextjs/FaqAccordion/Components/FaqItem';

import { FaqentryItem } from '@/lib/api/types/basic';
import { getFaqEntries } from '@/lib/api/utils/getFaqEntries';
import { filterInvalids } from '@/lib/utils/filterInvalids';

export interface FaqAccordionProps {
  showFilters?: boolean;
  resetLabel?: string;
  minEntries?: number;
  categories?: string[];
}

export const FaqAccordion: FC<FaqAccordionProps> = ({
  showFilters,
  minEntries,
  categories,
  resetLabel = 'reset'
}) => {
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [items, setItems] = useState<FaqentryItem[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const [filteredItems, setFilteredItems] = useState<FaqentryItem[]>([]);

  useEffect(() => {
    const fetchEntries = async () => {
      if (!categories) return;

      const result = await getFaqEntries({ categories });
      setItems(filterInvalids(result?.items ?? []));

      setFilteredCategories(
        filterInvalids(Array.from(new Set(items.flatMap((item) => item.content?.categories ?? []))))
      );

      setFilteredItems(items);
    };

    fetchEntries();
  }, [categories]);

  const handleFilter = useCallback(
    (filtered: string[]) => {
      setFilteredCategories(filtered);
      setFilteredItems(
        items.filter((item) =>
          (item.content?.categories ?? []).some(
            (category) => typeof category === 'string' && filtered.includes(category)
          )
        )
      );
    },
    [items]
  );

  const onToggle = useCallback(
    (index: number) => {
      setOpenIndex(openIndex === index ? -1 : index);
    },
    [openIndex]
  );

  if (minEntries !== undefined && items.length < minEntries) return null;

  const accordionClasses = clsx(
    'w-full text-primary text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]'
  );

  return (
    <div className={accordionClasses}>
      {showFilters && (
        <FaqFilters
          categories={categories ?? []}
          filteredCategories={filteredCategories}
          handleFilter={handleFilter}
          resetLabel={resetLabel}
        />
      )}

      {filteredItems.map((item, index) => (
        <FaqItem
          key={item.uuid}
          question={item.content?.question}
          categories={filterInvalids(item.content?.categories ?? [])}
          answer={item.content?.answer}
          isOpen={openIndex === index}
          onToggle={() => onToggle(index)}
        />
      ))}
    </div>
  );
};
