import { AppProps } from 'next/app';
import { ReactNode } from 'react';

import { SeoDefault } from '@/components/shared/Seo';

import { getHeaderConfigData } from '@/lib/utils/getHeaderConfigData';

import { DatasourceEntriesProvider } from '../DatasourceEntriesProvider';
import { GoogleTagMenagerProvider } from '../GoogleTagMenagerProvider';
import { HeaderProvider } from '../HeaderProvider';
import { SearchProvider } from '../SearchProvider';

interface MainProviderProps {
  children: ReactNode;
  pageProps: AppProps['pageProps'];
}

// This is the place responsible for grouping all providers from the app
export const MainProvider = ({ children, pageProps }: MainProviderProps) => {
  const headerConfig = getHeaderConfigData(pageProps.data);

  return (
    <>
      <GoogleTagMenagerProvider gtmID={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />
      <DatasourceEntriesProvider datasourceEntries={pageProps.datasourceEntries}>
        <SeoDefault />
        <SearchProvider searchedItems={pageProps.searchedItems}>
          <HeaderProvider headerConfig={headerConfig}>{children}</HeaderProvider>
        </SearchProvider>
      </DatasourceEntriesProvider>
    </>
  );
};
