import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';

import { useLang } from '@/hooks/useLang';

export function CookiebotDeclaration(): any {
  const domain = useLang()?.currentDomain?.domain;
  const slug = useRouter().asPath ?? '';

  const cookieToken = process.env.COOKIEBOT_TOKEN ?? '';

  return (
    <div className="mx-auto max-w-[160rem] w-full overflow-hidden px-[1.6rem] md:px-[3.6rem] grid grid-cols-none gap-[3.2rem] lg:grid-cols-1 text-[1.6rem] lg:text-[1.8rem]">
      <div id="CookieDeclaration"></div>
      {domain === 'bestit.de' && slug.includes('datenschutzerklaerung') && (
        <Script
          id="CookieDeclaration"
          strategy="worker"
          src="https://consent.cookiebot.com/6728c617-d98c-4712-b66e-be0982db518a/cd.js"
          type="text/javascript"
          async
        />
      )}
      {domain === 'bestit.at' && slug.includes('datenschutzerklaerung') && (
        <Script
          strategy="worker"
          id="CookieDeclaration"
          src={'https://consent.cookiebot.com/' + cookieToken + '/cd.js'}
          type="text/javascript"
          async
        />
      )}
    </div>
  );
}
