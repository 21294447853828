import { useRouter } from 'next/router';
import { FC } from 'react';
import { BreadcrumbList, WithContext } from 'schema-dts';

import { NavigationItem } from '@/components/layout/Header/types';
import { StructuredData } from '@/components/shared/StructuredData';

import { useLang } from '@/hooks/useLang';

import { getLastBreadcrumb, IBreadcrumb } from '@/lib/utils/getLastBreadcrumb';

interface Props {
  pageTitle?: string;
  title?: string;
  navigation: NavigationItem[];
}

const getBreadcrumbSchema = (data: IBreadcrumb): WithContext<BreadcrumbList> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: '/'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: data.name,
        item: data.url
      }
    ]
  };
};

export const HeaderTitle: FC<Props> = ({ title, pageTitle, navigation }) => {
  const { asPath } = useRouter();
  const { locale } = useLang();

  const titleData = getLastBreadcrumb(asPath, navigation, locale);
  if (!title && !(pageTitle || titleData)) {
    return null;
  }

  pageTitle = titleData ? titleData.name : pageTitle;

  return (
    <>
      {titleData && <StructuredData data={getBreadcrumbSchema(titleData)} />}

      <div className="font-heading text-black text-center">
        {title && (
          <span className="hidden lg:inline-block mr-[4rem] text-[1.2rem] lg:text-[1.6rem] leading-[2rem] tracking-[0.35em] uppercase">
            {title}
          </span>
        )}
        {pageTitle && (
          <span className="text-primary text-[1.2rem] lg:text-[1.6rem] leading-[2rem] tracking-[0.35em] uppercase">
            {titleData ? <a href={titleData.url}>{titleData.name}</a> : pageTitle}
          </span>
        )}
      </div>
    </>
  );
};
