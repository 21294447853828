import { LinkEntry } from '@/lib/api/types/basic';
import { LinksDocument } from '@/lib/api/types/hooks';
import { LinksQuery, LinksQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * If the data is not undefined, then it is a LinkEntry array.
 * @param {any} data - any - this is the data that we're checking.
 * @returns A function that takes in a parameter of type any and returns a boolean.
 */
const isLinkEntry = (data: any): data is LinkEntry[] => {
  return data !== undefined && data.length > 0;
};

/**
 * It makes a GraphQL request to the API, and returns the response if it's valid
 * @returns An array of LinkEntry objects or null
 */
export const getLinks = async (variables: LinksQueryVariables): Promise<LinkEntry[] | null> => {
  const res = await Api.getLinks<LinksQuery, LinksQueryVariables>(LinksDocument, variables);

  const items = res.data.Links?.items;

  return isLinkEntry(items) ? items : null;
};
