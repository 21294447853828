import { FC, useCallback } from 'react';

import { FaqFilterItem } from '@/components/nextjs/FaqAccordion/Components/FaqFilterItem';

export interface FaqFiltersProps {
  categories: string[];
  filteredCategories: string[];
  handleFilter: (filtered: string[]) => void;
  resetLabel: string;
}

export const FaqFilters: FC<FaqFiltersProps> = ({
  categories = [],
  filteredCategories,
  handleFilter,
  resetLabel
}) => {
  const handleFilterClick = useCallback(
    (category: string) => {
      const isCategoryFiltered = filteredCategories.includes(category);

      const updatedCategories = isCategoryFiltered
        ? filteredCategories.filter((cat) => cat !== category)
        : [...filteredCategories, category];

      handleFilter(updatedCategories);
    },
    [filteredCategories, handleFilter]
  );

  const resetFilters = useCallback(() => {
    handleFilter(categories);
  }, [categories, handleFilter]);

  if (categories.length < 1) {
    return null;
  }

  return (
    <div className={'mb-4'}>
      <FaqFilterItem
        key={'reset-categories'}
        name={resetLabel}
        toggleCategory={() => resetFilters()}
        active={true}
        className={'bg-gray-800'}
      />

      {categories.map((category) => {
        return (
          <FaqFilterItem
            key={category}
            name={category}
            toggleCategory={() => handleFilterClick(category)}
            active={filteredCategories.includes(category)}
          />
        );
      })}
    </div>
  );
};
