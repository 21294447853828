import clsx from 'clsx';
import { FC } from 'react';

import { RichText } from '@/components/shared/RichText';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { RichText as IRichText } from '@/lib/types/richContent';
import { StoryblokLink } from '@/lib/types/utils';

import { DesktopItems } from './components/DesktopItems';
import { MobileItems } from './components/MobileItems';

export interface NavLink {
  name: string;
  link: StoryblokLink;
  component: string;
  _uid: string;
  _editable: string;
}

export interface MobileSliderDesktopColProps {
  preline?: string;
  title?: string;
  description?: IRichText;
  items: BlokDynamicZone[];
  className?: string;
  id?: string;
}

// Task: BIWR-12
export const MobileSliderDesktopCol: FC<MobileSliderDesktopColProps> = ({
  preline,
  title,
  description,
  items,
  className,
  id
}) => {
  const wrapperStyles = clsx(
    'max-w-[160rem] w-full mx-auto pl-[1.6rem] md:px-[3.6rem] overflow-x-hidden flex gap-[3.2rem] flex-col lg:flex-row',
    className
  );

  return (
    <section className={wrapperStyles} id={id}>
      <div className="flex flex-col gap-[0.8rem] mb-[1.6rem] lg:mb-[3.2rem] lg:min-w-[36rem]">
        {preline && (
          <span className="inline-block text-[1.2rem] lg:text-[1.6rem] leading-[2rem] lg:leading-[2.8rem] text-primary tracking-[0.3em] uppercase font-heading">
            {preline}
          </span>
        )}
        {title && (
          <h3 className="font-bold text-black text-[3.2rem] lg:text-[6rem] leading-[3.8rem] lg:leading-[6.4rem]">
            {title}
          </h3>
        )}
        {description && <RichText content={description} />}
      </div>
      <div className="w-full">
        <MobileItems items={items} />
        <DesktopItems items={items} />
      </div>
    </section>
  );
};
