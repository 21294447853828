/**
 * If the page is less than the min, add the range size to the page. If the page is greater than the
 * max, subtract the range size from the page. Otherwise, return the page.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum number in the range.
 * @param {number} page - The current page number.
 * @returns the remainder of the division of the page number by the range size.
 */
export const wrap = (min: number, max: number, page: number) => {
  const rangeSize = max - min;

  return ((((page - min) % rangeSize) + rangeSize) % rangeSize) + min;
};
