import { FC } from 'react';

import { RichText, RichTextProps } from '@/components/shared/RichText';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBRichTextProps = RichTextProps & BlokCommonProps;

export const SBRichText: FC<BlokProps<SBRichTextProps>> = ({ blok }) => {
  const { content, bgColor, bottomSpace = 'normal', colorText = 'default' } = blok;

  return (
    <RichText content={content} bgColor={bgColor} bottomSpace={bottomSpace} colorText={colorText} />
  );
};
