import { FC } from 'react';

import { ImageText, ImageTextItemProps } from '@/components/shared/ImageText';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBImageTextProps = ImageTextItemProps & BlokCommonProps;

export const SBImageText: FC<BlokProps<SBImageTextProps>> = ({ blok }) => {
  const { image, description, links, title, priority } = blok;

  return (
    <ImageText
      priority={priority}
      image={image}
      description={description}
      links={links}
      title={title}
    />
  );
};
