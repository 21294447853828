import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';
import SbEditable from 'storyblok-react';

import { Aside, AsideProps } from '@/components/layout/Aside';
import { Header, HeaderConfig } from '@/components/layout/Header';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import {
  FooterItem,
  HeaderItem,
  InsightscategoryItem,
  InsightsItem,
  PageItem
} from '@/lib/api/types/basic';
import { getContactLayerData } from '@/lib/utils/getContactLayerData';
import { getHeaderConfigData } from '@/lib/utils/getHeaderConfigData';

import { ContactLayer } from '../ContactLayer';
import { ContactLayerForm } from '../ContactLayer/components/ContactLayerForm';
import { isFooterType } from '../Footer/utils';
import { isHeaderType } from '../Header/utils';

const Footer = dynamic<any>(() => import('@/components/layout/Footer').then((mod) => mod.Footer));

interface Props {
  children: ReactNode;
  footerData: FooterItem | null;
  headerData: HeaderItem | null;
  headerConfig: HeaderConfig | null;
  data: PageItem | InsightsItem | InsightscategoryItem | null;
  aside: AsideProps;
}

// This is the place responsible for wrapping your app.
// Add here components like Footer, Nav etc.
export const MainLayout: FC<Props> = ({ children, footerData, headerData, data, aside }) => {
  const headerConfig = getHeaderConfigData(data);
  const contactLayer = getContactLayerData(data);

  return (
    <div className="flex flex-col min-h-screen">
      {headerData && isHeaderType(headerData.content) && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <SbEditable content={headerData.content} key={headerData.content._uid}>
          <Header
            headerConfig={headerConfig}
            defaultFullSlug={data?.default_full_slug}
            {...headerData.content}
          />
          {headerData?.content?.globalScripts?.map((script: BlokDynamicZone) => (
            <BlokProvider key={script._uid} blok={script} />
          ))}
        </SbEditable>
      )}
      <main className="flex-1">
        {children}
        <Aside {...aside} />
      </main>
      {footerData && isFooterType(footerData.content) && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <SbEditable content={footerData.content} key={footerData.content._uid}>
          <Footer {...footerData?.content} />
        </SbEditable>
      )}
      <ContactLayerForm className="hidden" />
      {contactLayer && <ContactLayer items={contactLayer.items} count={contactLayer.count} />}
    </div>
  );
};
