import { motion } from 'framer-motion';
import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';
import { Typography } from '@/components/shared/Typography';

import { InsightscategoryItem } from '@/lib/api/types/basic';
import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';
import { getNumber } from '@/lib/utils';

const variants = {
  initial: {
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)'
  },
  animate: { boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)' }, // fix for boxShadow with LayoutGroup & AnimatePresence components
  hover: {
    scale: 1.05,
    boxShadow: '0px 16px 28px rgba(0, 0, 0, 0.08)',
    transition: {
      duration: 0.2
    }
  }
};
export interface CardAwardProps {
  image?: StoryblokImage;
  label?: string;
  arrowLink?: StoryblokLink;
  number?: number;
  categories?: InsightscategoryItem[];
}

export const CardAward: FC<CardAwardProps> = ({ label, image, number, arrowLink }) => {
  const cardNumber = getNumber(number);

  const withTopContainer = !!cardNumber || !!arrowLink;

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="w-full h-full min-h-[12.5rem] bg-white p-[1.6rem] lg:px-[3.2rem] lg:py-[4.2rem] relative flex flex-col"
    >
      {withTopContainer && (
        <div className="flex justify-between items-center mb-[1.6rem] lg:mb-[2rem]">
          {cardNumber && (
            <Typography variant="preline" tag="p">
              {cardNumber}
            </Typography>
          )}

          {arrowLink && (arrowLink.cached_url || arrowLink.url) && (
            <SBLink link={arrowLink}>
              <ButtonLink variant="arrow" className="ml-auto" />
            </SBLink>
          )}
        </div>
      )}

      <div className="flex justify-center items-end gap-[3.2rem] w-full my-auto">
        {label && (
          <span className="text-[4.8rem] lg:text-[6rem] font-bold leading-[4.8rem] lg:leading-[6.4rem]">
            {label}
          </span>
        )}

        {image && image.filename && (
          <div className="relative w-full aspect-video  basis-1/2">
            <Image src={image.filename} alt={image.alt || ''} layout="fill" objectFit="contain" />
          </div>
        )}
      </div>
    </motion.div>
  );
};
