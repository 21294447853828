import { useDatasourceEntries } from '@/components/layout/DatasourceEntriesProvider';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { FilterChangeInput, ReducerDispatch } from '../../types';
import { getFilteredDropdownItems } from '../../utils';
import { FilterItem } from '../FilterItem/FilterItem';

interface JobCardFiltersProps {
  items: BlokDynamicZone[];
  dispatch: ReducerDispatch;
}

export const JobCardFilters = ({ items, dispatch }: JobCardFiltersProps) => {
  const { t } = useDatasourceEntries();

  const workspaceSelectLabel = t('workspace_select_label');
  const alleLabel = t('job_items_default_filter_label');

  const workspaceLabel = t('job_workspace_label');
  const levelLabel = t('job_level_label');
  const areaLabel = t('job_area_label');

  const workspaceDefaultItem: any = {
    id: null,
    content: {
      name: workspaceSelectLabel
    }
  };

  const defaultFilterItem: any = {
    id: null,
    content: {
      name: alleLabel
    }
  };

  const { jobAreaItems, jobLevelItems, jobWorkspaceItems } = getFilteredDropdownItems(items);

  const handleChange = ({ type, id }: FilterChangeInput) => {
    dispatch({
      type: 'SET_FILTER_VALUE',
      payload: {
        filterType: type,
        value: id
      }
    });
  };

  return (
    <div className="bg-gray-100 px-[2.4rem] py-[4.4rem] mb-[4.4rem] lg:mb-[5.6rem] flex flex-col lg:flex-row items-center justify-between gap-[2.4rem] lg:gap-[5rem]">
      {jobWorkspaceItems && (
        <FilterItem
          type="jobWorkspace"
          label={workspaceLabel}
          items={[workspaceDefaultItem, ...jobWorkspaceItems]}
          onItemChange={handleChange}
        />
      )}
      {jobLevelItems && (
        <FilterItem
          type="jobLevel"
          label={levelLabel}
          items={[defaultFilterItem, ...jobLevelItems]}
          onItemChange={handleChange}
        />
      )}
      {jobAreaItems && (
        <FilterItem
          type="jobArea"
          label={areaLabel}
          items={[defaultFilterItem, ...jobAreaItems]}
          onItemChange={handleChange}
        />
      )}
    </div>
  );
};
