import { useRouter } from 'next/router';
import { Children, cloneElement, ReactElement, useEffect, useState } from 'react';

import { Link } from '@/components/shared/Link';

import { blockedSlugsList } from '@/lib/consts';
import { StoryblokLink } from '@/lib/types/utils';
import { getUrl } from '@/lib/utils';

export interface StoryblokLinkProps {
  link?: StoryblokLink;
  children: ReactElement;
}

/**
 * It takes a link and a list of locales, and returns the link without the locales
 * @param {string} link - The link to the page you want to get the locale URL for.
 * @param {string[]} locales - An array of locales that you want to support.
 * @returns A function that takes in a link and locales and returns a string.
 */
export const getLocaleUrl = (link: string, locales: string[]) => {
  const slugs = link.split('/').filter((path) => path && !locales.includes(path));

  return slugs.join('/');
};

export const SBLink = ({ link, children, ...rest }: StoryblokLinkProps) => {
  const { asPath, isReady } = useRouter();

  const [queryParams, setQueryParams] = useState('');

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const params = asPath.split('?')?.[1] || '';
    setQueryParams(params);
  }, [isReady, asPath]);

  if (!link) {
    return null;
  }

  if (!link.cached_url && !link.url && link.linktype !== 'email') {
    return null;
  }

  const { anchor, target } = link || {};

  const href = getUrl(link);
  const child = Children.only(children);

  const url = getLocaleUrl(link.cached_url, blockedSlugsList);

  if (href.startsWith('tel')) {
    return cloneElement(child, { ...child.props, href });
  }

  const anchorID = anchor ? `#${anchor}` : '';

  const path = `/${url}${anchorID}`.replace('landingpages', 'lp');
  const hasQueryParams = href.includes('?');
  const hrefWithParams = [
    href,
    hasQueryParams ? '&' : '',
    queryParams && !hasQueryParams ? '?' : '',
    queryParams
  ].join('');

  switch (link.linktype) {
    case 'story':
      return (
        <Link href={path} passHref {...rest}>
          {cloneElement(child, { ...child.props, target })}
        </Link>
      );
    case 'email':
      return cloneElement(child, { ...child.props, href, target });
    case 'url':
      return cloneElement(child, { ...child.props, href: hrefWithParams, target });

    default:
      return null;
  }
};
