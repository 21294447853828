import clsx from 'clsx';
import { ChangeEvent, FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import { TickIcon } from '@/components/shared/Icons/TickIcon';

import { useToggle } from '@/hooks/useToggle';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  classNameInput?: string;
  classNameHelperText?: string;
  helperText?: ReactNode;
  isError?: boolean;
}

const inputStyles = clsx(
  'absolute inset-0 opacity-0 cursor-pointer z-10 w-full h-full',
  'disabled:cursor-not-allowed'
);

// Task BIWR-5
export const Checkbox: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      className,
      classNameInput,
      classNameHelperText,
      defaultChecked = false,
      helperText,
      disabled = false,
      isError = false,
      ...rest
    },
    ref
  ) => {
    const [checked, toggleCheckbox] = useToggle(defaultChecked);

    const handleToggle = () => {
      if (!disabled) {
        toggleCheckbox();
      }
    };

    const wrapperStyles = clsx('group relative flex items-start max-w-max', className);
    const checkboxDivStyles = clsx(
      'relative inline-block w-[2.4rem] h-[2.4rem] rounded-[0.4rem] border border-primary cursor-pointer',
      'group-hover:border-hoverPrimary',
      'group-focus:border-black',
      disabled && 'border-gray-200 hover:border-gray-200 cursor-not-allowed',
      isError && 'border-red-500 focus:border-red-500',
      classNameInput
    );
    const tickIconStyles = clsx(
      'absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-primary pointer-events-none	',
      disabled && 'text-gray-200'
    );
    const labelStyles = clsx(
      'group inline-block ml-[0.8rem] lg:ml-[1.6rem] text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] text-black',
      disabled && 'text-gray-200',
      classNameHelperText
    );

    return (
      <fieldset className={wrapperStyles} disabled={disabled} onClick={handleToggle}>
        <div className={checkboxDivStyles}>
          {checked && <TickIcon className={tickIconStyles} />}
        </div>
        <input ref={ref} className={inputStyles} id={name} name={name} type="checkbox" {...rest} />
        {helperText && (
          <label htmlFor={name} className={labelStyles}>
            {helperText}
          </label>
        )}
      </fieldset>
    );
  }
);

Checkbox.displayName = 'Checkbox';
