export interface RawCodeProps {
  code?: string;
}

export const RawCode = ({ code }: RawCodeProps) => {
  if (!code) {
    return null;
  }

  return <div className="relative" dangerouslySetInnerHTML={{ __html: code }} />;
};
