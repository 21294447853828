import { ContentItem } from '@/lib/api/types/basic';
import { GetContentNodeDocument } from '@/lib/api/types/hooks';
import { GetContentNodeQueryVariables, GetContentNodeQuery } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

const isContentNode = (data: any): data is ContentItem => {
  return data !== undefined;
};

export const getContentNode = async (
  variables: GetContentNodeQueryVariables
): Promise<ContentItem | null> => {
  const { data } = await Api.getPageItem<GetContentNodeQuery, GetContentNodeQueryVariables>(
    GetContentNodeDocument,
    variables
  );

  return isContentNode(data?.ContentNode) ? data?.ContentNode : null;
};
