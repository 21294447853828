import clsx from 'clsx';
import { LayoutGroup, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';

import { Typography } from '@/components/shared/Typography';
import { BlokProvider } from '@/components/storyblok/BlokProvider';

import { wrap } from '@/lib/utils';

import { HeroBannerProps } from '../../HeroBanner';
import { HeroRollingTexts } from '../HeroRollingTexts';

interface HeroBannerContentProps {
  title: HeroBannerProps['title'];
  rollingTexts: HeroBannerProps['rollingTexts'];
  contentItems?: HeroBannerProps['items'];
  className?: string;
  tag: HeroBannerProps['tag'];
}

const nextSlideTime = 5000;

export const HeroBannerContent: FC<HeroBannerContentProps> = ({
  title,
  rollingTexts,
  contentItems,
  className,
  tag
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const currentIndex = wrap(0, rollingTexts.length, activeSlide);

  const wrapperStyles = clsx('min-h-[20rem]', className);

  useEffect(() => {
    const intervalId = setInterval(() => setActiveSlide((prev) => prev + 1), nextSlideTime);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <LayoutGroup>
      <div className={wrapperStyles}>
        <motion.div>
          <Typography tag={tag} variant="h1" isBold className="flex flex-col">
            <span className="inline-block mb-[0.8rem] lg:mb-[1.6rem]">{title}</span>
            <HeroRollingTexts item={rollingTexts[currentIndex]} />
          </Typography>
          {contentItems && contentItems.length > 0 && (
            <motion.div className="py-[3.2rem] flex flex-col lg:pt-[6.2rem] max-w-[60rem]">
              {contentItems?.map((blok) => (
                <BlokProvider key={blok._uid} blok={{ ...blok }} />
              ))}
            </motion.div>
          )}
        </motion.div>
      </div>
    </LayoutGroup>
  );
};
