export const LinkedinIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99935 12H2.66602V28H7.99935V12Z"
        stroke="#0000FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.334 10.6666C23.4557 10.6666 25.4905 11.5095 26.9908 13.0098C28.4911 14.5101 29.334 16.5449 29.334 18.6666V28H24.0007V18.6666C24.0007 17.9594 23.7197 17.2811 23.2196 16.781C22.7195 16.2809 22.0412 16 21.334 16C20.6267 16 19.9485 16.2809 19.4484 16.781C18.9483 17.2811 18.6673 17.9594 18.6673 18.6666V28H13.334V18.6666C13.334 16.5449 14.1768 14.5101 15.6771 13.0098C17.1774 11.5095 19.2123 10.6666 21.334 10.6666V10.6666Z"
        stroke="#0000FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33268 7.99996C6.80544 7.99996 7.99935 6.80605 7.99935 5.33329C7.99935 3.86053 6.80544 2.66663 5.33268 2.66663C3.85992 2.66663 2.66602 3.86053 2.66602 5.33329C2.66602 6.80605 3.85992 7.99996 5.33268 7.99996Z"
        stroke="#0000FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
