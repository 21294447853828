import { excludingSlugs } from '@/lib/api/excludingSlugs';
import { CasestudiesItems, Maybe } from '@/lib/api/types/basic';
import { getCaseStudiesItems } from '@/lib/api/utils';

/**
 * It returns a list of case studies items
 * @param [type] - The type of data you want to fetch.
 * @param {string} locale - String - this is the locale of the page.
 * @returns An object with the following properties:
 * - type: string
 * - locale: Locale
 * - bottomSliderData: CasestudiesItems | null
 */
export const getBottomSliderData = async (type?: Maybe<string>, locale?: string) => {
  let bottomSliderData: CasestudiesItems | null = null;

  if (type === 'caseStudies') {
    bottomSliderData = await getCaseStudiesItems({
      page: 1,
      perPage: 6,
      startsWith: `${locale}/`,
      excludingSlugs
    });
  }

  return bottomSliderData;
};
