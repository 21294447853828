export interface ImageLabelProps {
  label?: string;
  number?: string;
  accesses: boolean;
  id: string;
}

/**
 * It takes an object with 6 properties, and returns an array of 3 objects, each with 3 properties
 * @param  - Record<string, string | undefined>
 * @returns An array of objects with the following properties:
 *   label: string
 *   number: string
 *   accesses: boolean
 */
export const getImageLabelsProps = ({
  labelOne,
  labelTwo,
  labelThree,
  numberOne,
  numberTwo,
  numberThree
}: Record<string, string | undefined>): ImageLabelProps[] => {
  return [
    { id: '0', label: labelOne, number: numberOne, accesses: !!(labelOne || numberOne) },
    { id: '1', label: labelTwo, number: numberTwo, accesses: !!(labelTwo || numberTwo) },
    { id: '2', label: labelThree, number: numberThree, accesses: !!(labelThree || numberThree) }
  ];
};
