import { FC } from 'react';

import { VideoYoutube, VideoYoutubeProps } from '@/components/shared/VideoYoutube';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBVideoYoutubeProps = VideoYoutubeProps & BlokCommonProps;

export const SBVideoYoutube: FC<BlokProps<SBVideoYoutubeProps>> = ({ blok }) => {
  const { url } = blok;

  return <VideoYoutube url={url} />;
};
