import clsx from 'clsx';
import { FC } from 'react';

import { BlokProvider, BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { BackgroundColumnColor } from './types';

export interface GridColumnsContentProps {
  columnsPerRow?: '1' | '2' | '3';
  spaceBetween?: 'normal' | 'large' | 'text';
  containerSize?: 'full' | 'normal';
  columns?: BlokDynamicZone[];
  background?: BackgroundColumnColor;
  className?: string;
  id?: string;
}

export const GridColumnsContent: FC<GridColumnsContentProps> = ({
  columns,
  columnsPerRow,
  spaceBetween,
  containerSize,
  background,
  className,
  id
}) => {
  if (!columns) {
    return null;
  }

  const space = spaceBetween ?? 'normal';
  const perRow = columnsPerRow ?? '4';
  const width = containerSize ?? 'normal';
  const bgColor = background ?? 'white';

  const wrapperStyles = clsx(
    width === 'full' && 'w-full',
    width === 'normal' && 'max-w-[160rem] mx-auto w-full',
    bgColor === 'white' && 'bg-white',
    bgColor === 'primary' && 'bg-primary py-[3rem] lg:pt-[9rem] lg:pb-[3rem] text-white',
    className
  );

  const gridStyles = clsx(
    'mx-auto max-w-[160rem] w-full overflow-hidden px-[1.6rem] md:px-[3.6rem] pt-6 pb-6',
    'grid grid-cols-none',
    space === 'text' && 'gap[1rem] lg:gap-[3.2rem]',
    space === 'normal' && 'gap-[3.2rem]',
    space === 'large' && 'gap-[7rem]',
    perRow === '1' && 'lg:grid-cols-1',
    perRow === '2' && 'lg:grid-cols-2',
    perRow === '3' && 'lg:grid-cols-3',
    perRow === '4' && 'lg:grid-cols-4'
  );

  return (
    <div className={wrapperStyles} id={id}>
      <div className={gridStyles}>
        {columns.map((column) => (
          <BlokProvider key={column._uid} blok={{ bgColor, ...column }} />
        ))}
      </div>
    </div>
  );
};
