import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const FooterColumnTitle: FC<Props> = ({ children }) => (
  <span className="block text-[1.2rem] lg:text-[1.6rem] leading-[2rem] pb-[2rem] lg:pb-[2.4rem] lg:leading-[2.8rem] font-heading font-bold lg:font-normal tracking-[0.3em] uppercase">
    {children}
  </span>
);
