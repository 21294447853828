import { BlokProvider, BlokDynamicZone } from '@/components/storyblok/BlokProvider';

export interface GlobalDataContentProps {
  items: BlokDynamicZone[];
  className?: string;
}

export const GlobalDataContent = ({ items, className }: GlobalDataContentProps) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div className={className}>
      {items.map((blok) => (
        <BlokProvider key={blok._uid} blok={blok} />
      ))}
    </div>
  );
};
