import clsx from 'clsx';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useReducer } from 'react';

import { ShowMore } from '@/components/shared/ShowMore';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { JobCardFilters } from './components/JobCardFilters';
import { reducer } from './reducer';
import { getInitialReducerState } from './utils';

export interface JobCardItemsProps {
  items?: BlokDynamicZone[];
  filtersAvailable?: boolean;
  showMoreLabel?: string;
  maxItems?: number;
  className?: string;
  id?: string;
}

const cardVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4
    }
  }
};

export const JobCardItems = ({
  items,
  filtersAvailable = false,
  maxItems = 8,
  showMoreLabel,
  className,
  id
}: JobCardItemsProps) => {
  const [{ filteredItems, isShowMoreLabelVisible }, dispatch] = useReducer(
    reducer,
    getInitialReducerState({ items, maxItems })
  );

  if (!items) {
    return null;
  }

  const handleShowMore = () => {
    dispatch({
      type: 'SET_SHOW_MORE',
      payload: {
        isShowMoreLabelVisible: false
      }
    });
  };

  const stylesWrapper = clsx('max-w-[160rem] w-full mx-auto', className);

  return (
    <div className={stylesWrapper} id={id}>
      {filtersAvailable && <JobCardFilters items={items} dispatch={dispatch} />}
      <div className="grid grid-cols-responsive-fill-card gap-[1.6rem] lg:gap-[3.2rem] px-[1.6rem] md:px-[3.6rem]">
        <LayoutGroup>
          <AnimatePresence initial={false} mode="popLayout">
            {filteredItems?.map((blok) => (
              <motion.div
                key={blok._uid}
                layout="position"
                variants={cardVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <BlokProvider blok={{ ...blok }} />
              </motion.div>
            ))}
          </AnimatePresence>
        </LayoutGroup>
      </div>
      {showMoreLabel && isShowMoreLabelVisible && (
        <ShowMore
          className="mt-[1.6rem] lg:mt-[4.5rem]"
          label={showMoreLabel}
          onClick={handleShowMore}
          isOpen={false}
        />
      )}
    </div>
  );
};
