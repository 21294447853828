import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { CaseStudy, CaseStudyProps } from '@/components/nextjs/CaseStudy';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBCaseStudyProps = CaseStudyProps & BlokCommonProps & SpaceBottomProp;

export const SBCaseStudy: FC<BlokProps<SBCaseStudyProps>> = ({ blok }) => {
  const {
    image,
    labelOne,
    labelTwo,
    labelThree,
    numberOne,
    numberTwo,
    numberThree,
    items,
    bottomLinks,
    spaceBottom,
    id,
    priority
  } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <CaseStudy
      id={id}
      image={image}
      labelOne={labelOne}
      labelTwo={labelTwo}
      labelThree={labelThree}
      numberOne={numberOne}
      numberTwo={numberTwo}
      numberThree={numberThree}
      items={items}
      bottomLinks={bottomLinks}
      className={styles}
      priority={priority}
    />
  );
};
