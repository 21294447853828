import { FC } from 'react';

import { ColumnImage, ColumnImageProps } from '@/components/nextjs/ColumnImage';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBColumnImageProps = ColumnImageProps & BlokCommonProps;

export const SBColumnImage: FC<BlokProps<SBColumnImageProps>> = ({ blok }) => {
  const { image, imageObjectFit, imageSize, aspectRatio, priority, figure, caption } = blok;

  return (
    <ColumnImage
      priority={priority}
      image={image}
      imageObjectFit={imageObjectFit}
      imageSize={imageSize}
      aspectRatio={aspectRatio}
      figure={figure}
      caption={caption}
    />
  );
};
