import Head from 'next/head';
import { FC } from 'react';
import { Thing } from 'schema-dts';

interface Props {
  data: Thing;
}

export const StructuredData: FC<Props> = ({ data }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      ></script>
    </Head>
  );
};
