import clsx from 'clsx';
import { AnchorHTMLAttributes, FC, forwardRef, ReactNode } from 'react';

import { ArrowIcon } from '../Icons/ArrowIcon';

export type ButtonLinkVariant = 'text' | 'arrow';
export type Tag = 'span' | 'a';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  disabled?: boolean;
  withUnderline?: boolean;
  variant?: ButtonLinkVariant;
  as?: any;
}

const buttonLinkVariantStyles: Record<ButtonLinkVariant, string> = {
  text: clsx(
    'text-primary text-[1.6rem] lg:text-[1.8rem] inline-block',
    'hover:text-hoverPrimary',
    'focus:decoration-black',
    'active:visited:decoration-gray-200'
  ),
  arrow: clsx('text-primary inline-block', ' hover:text-hoverPrimary', 'focus:text-black')
};

// Task: BIWR-4
export const ButtonLink: FC<Props> = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      href,
      disabled = false,
      withUnderline = true,
      variant = 'text',
      className,
      as: Tag = 'a',
      ...rest
    },
    ref
  ) => {
    const anchorStyles = clsx(
      'transition-colors',
      buttonLinkVariantStyles[variant],
      variant === 'arrow' && 'group flex items-center justify-between',
      disabled && 'text-gray-200 cursor-not-allowed pointer-events-none',
      withUnderline && 'underline underline-offset-4',
      className
    );

    return (
      <Tag
        ref={ref}
        href={href}
        className={anchorStyles}
        aria-label={variant === 'arrow' ? 'Arrow link' : 'Page link'}
        {...rest}
      >
        {variant === 'arrow' ? <ArrowIcon /> : children}
      </Tag>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';
