import { FC } from 'react';

import { Button, ButtonVariant } from '@/components/shared/Button';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokLink } from '@/lib/types/utils';
import { getDynamicAttributes } from '@/lib/utils/getDynamicAttributes';

export interface ButtonProps {
  id?: string;
  label?: string;
  withIcon?: boolean;
  disabled?: false;
  variant?: ButtonVariant;
  link?: StoryblokLink;
}

export const ButtonElement: FC<ButtonProps> = ({
  id,
  disabled = false,
  withIcon = false,
  label,
  variant = 'primary',
  link
}) => {
  if (!link) {
    return null;
  }

  return (
    <SBLink link={link}>
      <Button
        as="a"
        variant={variant}
        withIcon={withIcon}
        disabled={disabled}
        className="max-w-max"
        {...getDynamicAttributes({ id: id })}
      >
        {label}
      </Button>
    </SBLink>
  );
};
