import { FC } from 'react';

import { FaqAccordion, FaqAccordionProps } from '@/components/nextjs/FaqAccordion';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBFaqAccordionProps = FaqAccordionProps & BlokCommonProps;

export const SBFaqAccordion: FC<BlokProps<SBFaqAccordionProps>> = ({ blok }) => {
  const { showFilters, minEntries, categories, resetLabel } = blok;

  return (
    <FaqAccordion
      showFilters={showFilters}
      minEntries={minEntries}
      categories={categories}
      resetLabel={resetLabel}
    />
  );
};
