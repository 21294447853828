import clsx from 'clsx';
import { FC } from 'react';

import { LinkProps } from '@/components/nextjs/Link';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';

import { CaseStudyContent } from './components/CaseStudyContent';
import { CaseStudyImage } from './components/CaseStudyImage';
import { getImageLabelsProps } from './utils';

export interface CaseStudyProps {
  image: StoryblokImage;
  labelOne?: string;
  labelTwo?: string;
  labelThree?: string;
  numberOne?: string;
  numberTwo?: string;
  numberThree?: string;
  items?: BlokDynamicZone[];
  bottomLinks?: LinkProps[];
  className?: string;
  id?: string;
  priority?: boolean;
}

export const CaseStudy: FC<CaseStudyProps> = ({
  image,
  labelOne,
  labelTwo,
  labelThree,
  numberOne,
  numberTwo,
  numberThree,
  items,
  bottomLinks,
  priority,
  className,
  id
}) => {
  const imageLabels = getImageLabelsProps({
    labelOne,
    labelTwo,
    labelThree,
    numberOne,
    numberTwo,
    numberThree
  });

  const wrapperStyles = clsx('max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem]', className);

  return (
    <section className={wrapperStyles} id={id}>
      <div className="flex flex-col lg:flex-row items-center gap-[2rem] lg:gap-[8rem]">
        <CaseStudyImage image={image} imageLabels={imageLabels} priority={priority} />
        <CaseStudyContent items={items} links={bottomLinks} />
      </div>
    </section>
  );
};
