import { FC } from 'react';

import { Link, LinkProps } from '@/components/nextjs/Link';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBLinkProps = LinkProps & BlokCommonProps;

export const SBLink: FC<BlokProps<SBLinkProps>> = ({ blok }) => {
  const { variant, name, link, bgColor } = blok;

  return <Link variant={variant} name={name} link={link} bgColor={bgColor} />;
};
