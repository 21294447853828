import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

interface ContactLayerContentProps {
  isOpen?: boolean;
  items: BlokDynamicZone[];
  setIsOpen: any;
}

const contentStyles = clsx(
  'bg-gray-100 w-full max-w-[50rem] min-w-[30rem] h-full max-h-[60rem] sm:max-h-[80rem] lg:max-h-[90rem] 2xl:max-h-[110rem] overflow-auto border border-transparent rounded-2xl',
  'flex flex-col'
);

const titleHolderStyle = 'flex flex-row justify-between bg-primary text-white p-[1.6rem]';

export const ContactLayerContent = ({ isOpen, items, setIsOpen }: ContactLayerContentProps) => (
  <AnimatePresence mode="wait">
    {isOpen && (
      <motion.div className={contentStyles} exit={{ opacity: 0 }}>
        <div className={titleHolderStyle}>
          <p className="text-2xl font-bold">Unterstützung benötigt?</p>
          <button
            aria-label="Kontakt"
            className="text-2xl text-white"
            type="button"
            title="kontakt"
            onClick={() => setIsOpen()}
          >
            X
          </button>
        </div>
        <div className="p-[1.6rem] lg:p-[3.2rem]">
          {items.map((blok) => (
            <BlokProvider key={blok._uid} blok={blok} />
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);
