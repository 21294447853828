import clsx from 'clsx';
import { FC } from 'react';

import { StoryblokImage } from '@/lib/types/utils';

export interface BackgroundImageProps {
  background: StoryblokImage;
}

export const BackgroundImage: FC<BackgroundImageProps> = ({ background }) => {
  if (!background || background.filename === '') {
    return null;
  }

  let src: string = background.filename;
  if (background.focus) {
    src += '/m/1620x0/filters:focal(' + background.focus + ')';
  }

  const imageClasses: string = clsx('w-full absolute h-full z-[-1] object-cover');
  const dimmingClasses: string = clsx('w-full absolute h-full z-[-1] top-0 bg-black-15');

  return (
    <>
      <img src={src} alt={background.title ?? 'best it'} className={imageClasses} />
      <div className={dimmingClasses}></div>
    </>
  );
};
