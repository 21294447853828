import { getLocaleUrl } from '@/components/shared/SBLink';

import { getLinks } from '@/lib/api/utils/getLinks';
import { getRandom } from '@/lib/api/utils/getRandom';
import { blockedSlugsList } from '@/lib/consts';

interface GetRandomAsideItemsInput {
  excludingSlugs: string[];
  slug: string;
}

export const getRandomAsideItems = async ({ excludingSlugs, slug }: GetRandomAsideItemsInput) => {
  const links = await getLinks({ startsWith: 'de/' });
  const excludedCurrentSlug = `${slug}/`;

  const paths = links
    ?.filter(({ isFolder, slug }) => {
      const isExcluded = [
        'de/layout',
        'de/dev',
        '/de/layout',
        '/de/dev',
        excludedCurrentSlug,
        ...excludingSlugs
      ]
        .filter(Boolean)
        .some((excluded) => (slug as string).startsWith(excluded));

      if (isExcluded) return false;

      return slug && !isFolder;
    })
    .map((link) => ({ ...link, slug: getLocaleUrl(link.slug || '', blockedSlugsList) }));

  const random = getRandom(paths, 5);

  return random || null;
};
