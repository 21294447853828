import { FC } from 'react';

import { JobPostingSchema, JobPostingSchemaProps } from '@/components/nextjs/JobPostingSchema';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBJobPostingSchemaProps = BlokCommonProps & JobPostingSchemaProps;

export const SBJobPostingSchema: FC<BlokProps<SBJobPostingSchemaProps>> = ({ blok }) => {
  const { title, description, date_posted, validThrough } = blok;

  return (
    <JobPostingSchema
      title={title}
      description={description}
      date_posted={date_posted}
      validThrough={validThrough}
    />
  );
};
