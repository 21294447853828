import { FC } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

SwiperCore.use([Navigation]);

interface Props {
  items: BlokDynamicZone[];
}

export const MobileItems: FC<Props> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="mt-[4rem] xl:hidden">
      <Swiper
        speed={500}
        grabCursor
        autoHeight={false}
        slidesPerView={1.3}
        spaceBetween={16}
        breakpoints={{
          520: {
            slidesPerView: 1.8
          },
          620: {
            slidesPerView: 2.6
          }
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item._uid} style={{ height: 'auto' }}>
            <BlokProvider blok={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
