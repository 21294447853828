import clsx from 'clsx';
import { FC } from 'react';

import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';

import { HeroBannerContent } from './components/HeroBannerContent';
import { HeroBannerImage } from './components/HeroBannerImage';
import { RollingText } from './types';

type HeroBannerTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeroBannerProps {
  title: string;
  rollingTexts: RollingText[];
  items?: BlokDynamicZone[];
  image: StoryblokImage;
  className?: string;
  id?: string;
  priority?: boolean;
  tag?: HeroBannerTag;
}

export const HeroBanner: FC<HeroBannerProps> = ({
  title,
  rollingTexts,
  items,
  image,
  className,
  priority,
  id,
  tag = 'h2'
}) => {
  const wrapperStyles = clsx(
    'mx-auto w-full max-w-[160rem] flex flex-col lg:flex-row lg:relative px-[1.6rem] md:px-[3.6rem] gap-[2rem]',
    className
  );

  return (
    <section className={wrapperStyles} id={id}>
      <HeroBannerContent
        title={title}
        rollingTexts={rollingTexts ?? []}
        contentItems={items}
        tag={tag}
        className="2xl:absolute 2xl:z-10 2xl:top-1/2 2xl:-translate-y-1/2"
      />
      <HeroBannerImage image={image} priority={priority} />
    </section>
  );
};
