import clsx from 'clsx';

import { Image } from '@/components/shared/Image';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';

import { MobileSliderDesktopRow } from '../MobileSliderDesktopRow';

export interface ColumnsWithCardsAndBackgroundImageProps {
  image?: StoryblokImage;
  column?: BlokDynamicZone[];
  items?: BlokDynamicZone[];
  className?: string;
  priority?: boolean;
  id?: string;
}

// TODO add spacing -> W8 PR
export const ColumnsWithCardsAndBackgroundImage = ({
  image,
  column,
  items,
  className,
  id,
  priority
}: ColumnsWithCardsAndBackgroundImageProps) => {
  const wrapperStyles = clsx('max-w-[160rem] w-full mx-auto px-[1.6rem] md:px-[3.6rem]', className);

  return (
    <div className={wrapperStyles} id={id}>
      <div className="flex flex-col md:flex-row-reverse md:gap-[10%]">
        <div className="relative pl-[20%] md:pl-0 flex-1 3xl:-mr-[16rem]">
          {image && image.filename && (
            <Image
              priority={priority}
              src={image.filename}
              alt={image.alt || ''}
              width={800}
              height={950}
              objectFit="contain"
            />
          )}
        </div>
        {column && (
          <div className="flex-1 max-w-[72rem] px-[1.6rem] 3xl:px-0">
            {column.map((blok) => (
              <BlokProvider key={blok._uid} blok={blok} />
            ))}
          </div>
        )}
      </div>
      {items && (
        <div>
          <MobileSliderDesktopRow items={items} />
        </div>
      )}
    </div>
  );
};
