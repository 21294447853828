import { FC } from 'react';

import {
  FooterNewsletter,
  FooterNewsletterProps
} from '@/components/layout/Footer/components/FooterNewsletter';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBFooterNewsletterProps = FooterNewsletterProps & BlokCommonProps;

export const SBFooterNewsletter: FC<BlokProps<SBFooterNewsletterProps>> = ({ blok }) => {
  const { title, content, inputPlaceholder } = blok;

  return <FooterNewsletter title={title} content={content} inputPlaceholder={inputPlaceholder} />;
};
