import clsx from 'clsx';
import { FC } from 'react';

import { ColumnContent } from '@/components/nextjs/ColumnContent';
import { Image } from '@/components/shared/Image';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';

export interface GridImageRightProps {
  image?: StoryblokImage;
  items?: BlokDynamicZone[];
  className?: string;
  id?: string;
  priority?: boolean;
}

export const GridImageRight: FC<GridImageRightProps> = ({
  image,
  items,
  className,
  priority,
  id
}) => {
  const wrapperStyles = clsx(
    'mx-auto max-w-[160rem] w-full flex flex-col lg:flex-row-reverse gap-[2.6rem] lg:gap-[23rem] px-[1.6rem] md:px-[3.6rem]',
    className
  );

  return (
    <section className={wrapperStyles} id={id}>
      {image && image.filename && (
        <div className="w-full relative h-[30rem] lg:h-[95rem]">
          <Image
            priority={priority}
            src={image.filename}
            alt={image.alt || ''}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      )}
      <ColumnContent items={items} />
    </section>
  );
};
