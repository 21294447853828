/**
 * If the string is longer than the length, return the string truncated to the length, otherwise return
 * the string.
 * @param {string} string - The string to truncate.
 * @param [length=22] - The length of the string to truncate.
 * @returns a string.
 */
export const truncateString = (string: string, length = 22): string => {
  if (string.length > length) {
    return string.substring(0, length) + '...';
  } else {
    return string;
  }
};
