import clsx from 'clsx';
import React, { FC } from 'react';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';

import { ListVariant } from '../../List';

export interface ListItemProps {
  content?: string;
  isBold?: boolean;
  variant?: ListVariant;
  index?: number;
  bgColor?: BackgroundColumnColor;
}

/**
 * It takes a number and returns a string
 * @param {number} index - The index of the current item in the list.
 * @returns A string with the index number.
 */
const getNumberLabel = (index: number) => {
  if (index > 9) {
    return `${index}.`;
  }

  return `0${index}.`;
};

export const ListItem: FC<ListItemProps> = ({
  variant = 'dot',
  isBold = false,
  content,
  bgColor = 'white',
  index = 0
}) => {
  const listItemStyles = clsx(
    ' text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] flex gap-[0.8rem]',
    isBold && 'font-bold'
  );
  const markerStyles = clsx(
    variant === 'dot' &&
      bgColor === 'white' &&
      'inline-block w-[0.7rem] h-[0.7rem] mt-[1rem] rounded-full border border-black shrink-0',
    variant === 'dot' &&
      bgColor === 'primary' &&
      'inline-block w-[0.7rem] h-[0.7rem] mt-[1rem] rounded-full border border-white shrink-0',
    variant === 'number' &&
      bgColor === 'primary' &&
      'text-white text-[1.8rem] lg:text-[2.4rem] leading-[2.6rem] lg:leading-[3.4rem] font-bold shrink-0',
    variant === 'number' &&
      bgColor === 'white' &&
      'text-primary text-[1.8rem] lg:text-[2.4rem] leading-[2.6rem] lg:leading-[3.4rem] font-bold shrink-0'
  );

  return (
    <li className={listItemStyles}>
      {variant === 'number' && <span className={markerStyles}>{getNumberLabel(index + 1)}</span>}
      {variant === 'dot' && <span className={markerStyles} />}

      <span>{content}</span>
    </li>
  );
};
