import { FC } from 'react';

interface Props {
  className?: string;
}

export const PlayIcon: FC<Props> = ({ className }) => (
  <svg viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M51 93.5C74.4721 93.5 93.5 74.4721 93.5 51C93.5 27.5279 74.4721 8.5 51 8.5C27.5279 8.5 8.5 27.5279 8.5 51C8.5 74.4721 27.5279 93.5 51 93.5Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.5 34L68 51L42.5 68V34Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
