import clsx from 'clsx';
import { CSSProperties } from 'react';

import { Image } from '@/components/shared/Image';
import { Typography } from '@/components/shared/Typography';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { StoryblokImage } from '@/lib/types/utils';
import { cn } from '@/lib/utils';

type ContentPositionVariants = 'left' | 'right';
type ItemsPositionVariants = 'left' | 'right';
type ContainerPositionVariants = 'left' | 'right' | 'center';
type HeroTextImageTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeroTextImageProps {
  image: StoryblokImage;
  headline: string;
  preline: string;
  contentPosition?: ContentPositionVariants;
  itemsPosition?: ItemsPositionVariants;
  className?: string;
  items?: BlokDynamicZone[];
  containerPosition?: ContainerPositionVariants;
  bottomLinks?: BlokDynamicZone[];
  itemsWithCounter?: boolean;
  largeGap?: boolean;
  id?: string;
  priority?: boolean;
  tag?: HeroTextImageTag;
  prelineStyle?: {
    style?: CSSProperties;
    className?: string;
  };
  headlineStyle?: {
    style?: CSSProperties;
    className?: string;
  };
}

const textContentStyles: Record<ContentPositionVariants, string> = {
  left: 'md:left-[9rem]',
  right: 'right-0 lg:right-[13.8rem]'
};

//Task: BIWR-17
export const HeroTextImage = ({
  headline,
  preline,
  contentPosition,
  image,
  itemsPosition,
  items,
  itemsWithCounter,
  containerPosition,
  bottomLinks,
  largeGap,
  className,
  id,
  priority,
  tag = 'h3',
  prelineStyle,
  headlineStyle
}: HeroTextImageProps) => {
  const containerPos = containerPosition ?? 'center';
  const position = contentPosition ?? 'left';
  const itemsPos = itemsPosition ?? 'left';
  const gap = largeGap ?? true;
  const sectionsStyles = clsx(
    'w-full max-w-[1920px] mx-auto',
    gap === false && 'h-[85rem]',
    className
  );
  const wrapperStyles = clsx(
    'w-full relative max-w-[170.6rem]',
    containerPos === 'left' && 'mr-auto',
    containerPos === 'center' && 'mx-auto',
    containerPos === 'right' && 'ml-auto'
  );

  const textContentWrapperStyles = clsx(
    'absolute top-[17rem] lg:top-[38.5rem]',
    'px-[1.6rem] text-white',
    textContentStyles[position]
  );

  const cardContentWrapperStyles = clsx(
    'flex items-stretch justify-center flex-wrap gap-[1.6rem] lg:gap-[3.2rem] px-[1.6rem] md:px-[3rem] max-w-[160rem] w-full',
    '-translate-y-[2.4rem] lg:-translate-y-[12rem]',
    itemsPos === 'right' && 'ml-auto',
    itemsPos === 'left' && 'mr-auto'
  );

  return (
    <section className={sectionsStyles} id={id}>
      <div className={wrapperStyles}>
        <div className="relative w-full h-[30rem] lg:h-[69rem] bg-gradient-to-r from-black-15 to-black-15">
          {image.filename && (
            <Image
              priority={priority}
              src={image.filename}
              alt={image.alt || ''}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          )}
        </div>
        <div className={textContentWrapperStyles}>
          <Typography tag="span" variant="preline">
            <span className={cn('text-white', prelineStyle?.className)} style={prelineStyle?.style}>
              {preline}
            </span>
          </Typography>
          <Typography
            tag={tag}
            variant="h2"
            isBold
            style={headlineStyle?.style}
            className={headlineStyle?.className}
          >
            {headline}
          </Typography>
        </div>
        <div className={cardContentWrapperStyles}>
          {items?.map((blok, index) => (
            <BlokProvider
              key={blok._uid}
              blok={{
                ...blok,
                number: itemsWithCounter ? index + 1 : undefined,
                className: '!mb-0 px-0'
              }}
            />
          ))}

          <ul className="flex flex-col sm:flex-row flex-wrap gap-[2.4rem] mt-[2.4rem] w-full">
            {bottomLinks?.map((blok) => (
              <li key={blok._uid}>
                <BlokProvider blok={blok} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
