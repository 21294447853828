/**
 * If the number is less than 10, return a string with a 0 in front of it, otherwise return the number.
 * @param {number} num - number - this is the number that we want to convert to a two digit number.
 * @returns A string
 */
const getTwoDigitNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
};

/**
 * It takes a date string in the format of "YYYY-MM-DD" and returns a date string in the format of
 * "DD.MM.YYYY"
 * @param {string} [dateToFormat] - The date to format.
 * @returns A string with the date in the format dd.mm.yyyy
 */
export const getDate = (dateToFormat?: string) => {
  if (!dateToFormat) {
    return null;
  }

  const date = new Date(dateToFormat);

  const month = date.getUTCMonth() + 1; //months from 1-12
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${getTwoDigitNumber(day)}.${getTwoDigitNumber(month)}.${getTwoDigitNumber(year)}`;
};
