import React, { FC } from 'react';

import {
  FooterSocialMedia,
  FooterSocialMediaProps
} from '@/components/layout/Footer/components/FooterSocialMedia';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBFooterSocialMediaProps = FooterSocialMediaProps & BlokCommonProps;

export const SBFooterSocialMedia: FC<BlokProps<SBFooterSocialMediaProps>> = ({ blok }) => {
  const { items, title } = blok;

  return <FooterSocialMedia items={items} title={title} />;
};
