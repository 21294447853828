/**
 * It takes a start and end number and returns an array of numbers from start to end
 * @param {number} start - The starting number of the range.
 * @param {number} end - The end of the range.
 * @returns An array of numbers from start to end.
 */
export const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => index + start);
};
