export const TwitterIcon = () => (
  <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.6673 1.99998C29.3905 2.90062 27.9768 3.58946 26.4807 4.03998C25.6776 3.11666 24.6104 2.46224 23.4234 2.16521C22.2363 1.86819 20.9866 1.94291 19.8434 2.37925C18.7002 2.81559 17.7186 3.59251 17.0313 4.60494C16.344 5.61736 15.9842 6.81643 16.0007 8.03998V9.37331C13.6575 9.43407 11.3357 8.9144 9.242 7.86057C7.14831 6.80675 5.34774 5.25149 4.00065 3.33331C4.00065 3.33331 -1.33268 15.3333 10.6673 20.6666C7.92135 22.5306 4.65019 23.4652 1.33398 23.3333C13.334 30 28.0006 23.3333 28.0006 7.99998C27.9994 7.62858 27.9637 7.25811 27.894 6.89331C29.2548 5.5513 30.2151 3.85693 30.6673 1.99998V1.99998Z"
      stroke="#0000FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
