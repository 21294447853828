import clsx from 'clsx';
import { FC } from 'react';

import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { useToggle } from '@/hooks/useToggle';

import { ContactLayerButton } from './components/ContactLayerButton';
import { ContactLayerContent } from './components/ContactLayerContent';

interface ContactLayerProps {
  items?: BlokDynamicZone[];
  count?: number;
}

export const ContactLayer: FC<ContactLayerProps> = ({ items, count }) => {
  const [isContactLayerOpen, toggleContactLayer] = useToggle(false);

  if ((!items && !Array.isArray(items)) || items.length === 0) {
    return null;
  }

  const wrapperStyles = clsx(
    'fixed bottom-0 lg:bottom-1/2 lg:translate-y-1/2 right-1/2 translate-x-1/2 lg:translate-x-0 lg:right-0 z-10',
    isContactLayerOpen && 'z-50'
  );

  const boxStyles = clsx(
    'flex flex-col lg:flex-row items-center',
    'transition-transform',
    !isContactLayerOpen &&
      'translate-y-[calc(100%-5.3rem)] lg:translate-y-0 lg:translate-x-[calc(100%-5.6rem)]',
    isContactLayerOpen && 'translate-y-0 lg:translate-x-0'
  );

  return (
    <div className={wrapperStyles}>
      <div className={boxStyles}>
        <ContactLayerButton count={count} onClick={toggleContactLayer} />
        <ContactLayerContent
          isOpen={isContactLayerOpen}
          setIsOpen={toggleContactLayer}
          items={items}
        />
      </div>
    </div>
  );
};
