import { FC } from 'react';

interface Props {
  className?: string;
}

export const TickIcon: FC<Props> = ({ className }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.960247 6.55942C0.740578 6.31597 0.384422 6.31597 0.164752 6.55942C-0.0549175 6.80286 -0.0549175 7.19756 0.164752 7.441L5.78975 13.6748C6.00942 13.9182 6.36558 13.9182 6.58525 13.6748L17.8352 1.20724C18.0549 0.963794 18.0549 0.569093 17.8352 0.325649C17.6156 0.0822055 17.2594 0.0822055 17.0398 0.325649L6.1875 12.3524L0.960247 6.55942Z"
      fill="currentColor"
    />
  </svg>
);
