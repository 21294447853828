import { PageItem } from '@/lib/api/types/basic';

/**
 * "If the data is not null and the data's content's component is equal to 'page', then the data is a
 * PageItem."
 *
 * The above function is a type guard. It's a function that takes a value and returns a boolean. If the
 * boolean is true, then the value is of the type that the type guard is guarding
 * @param {PageItem | null} data - PageItem | null
 */
export const isPageType = (data?: PageItem | null): data is PageItem =>
  data !== undefined && data !== null && data.content?.component === 'page';
