import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { useTabs } from '../../Tabs';
export interface PanelGroupProps {
  children?: ReactNode;
  className?: string;
}

export const panelVariant = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.4 }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 }
  }
};

export const PanelGroup = ({ children, className }: PanelGroupProps) => {
  const [isInit, setIsInit] = useState(true);
  const { activeTab } = useTabs();

  useEffect(() => {
    setIsInit(false);
  }, []);

  return (
    <motion.div
      key={activeTab}
      variants={panelVariant}
      initial={isInit ? false : 'hidden'}
      animate="visible"
      className={className}
    >
      {children}
    </motion.div>
  );
};
