import { FC } from 'react';

import { CardAward, CardAwardProps } from '@/components/shared/CardAward';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBCardAwardProps = CardAwardProps & BlokCommonProps;

export const SBCardAward: FC<BlokProps<SBCardAwardProps>> = ({ blok }) => {
  const { label, image, number, arrowLink } = blok;

  return <CardAward label={label} image={image} number={number} arrowLink={arrowLink} />;
};
