import clsx from 'clsx';
import { FC, forwardRef, MouseEventHandler } from 'react';

import { HeartIcon } from '@/components/shared/Icons/HeartIcon';

type ChipsVariant = 'text' | 'icon';

interface Props {
  as?: 'a' | 'button' | 'span' | any; // TODO check;
  onClick?: MouseEventHandler<HTMLElement>;
  label?: string;
  variant?: ChipsVariant;
  isSelected?: boolean;
  className?: string;
  role?: 'button';
}

// Task: BIWR-6
export const Chips: FC<Props> = forwardRef<HTMLElement, Props>(
  (
    {
      as: Tag = 'button',
      onClick,
      label,
      className,
      isSelected = false,
      variant = 'text',

      ...rest
    },
    ref
  ) => {
    if (variant === 'icon') {
      const iconVariantStyle = clsx(
        'cursor-pointer text-primary bg-white border border-transparent transition-colors rounded-[3rem] px-[1.6rem] py-[0.7rem]',
        'hover:border-gray-200 hover:text-hoverPrimary',
        'focus:border-black',
        isSelected && 'border-transparent',
        className
      );
      const heartIconStyle = clsx(isSelected && 'fill-primary');

      return (
        <button onClick={onClick} className={iconVariantStyle} {...rest}>
          <HeartIcon className={heartIconStyle} />
        </button>
      );
    }

    const textVariantStyle = clsx(
      'cursor-pointer inline-block px-[1.6rem] pt-[0.5rem] lg:pt-[0.4rem] pb-[0.3rem] lg:pb-[0.2rem] border border-gray-200 rounded-[3rem] transition-colors',
      'text-[1.6rem] ld leading-[2.4rem] lg:text-[1.8rem] lg:leading-[2.6rem]',
      Tag === 'button' && 'text-gray-200',
      (Tag === 'a' || Tag === 'span') &&
        !isSelected &&
        'text-black hover:text-hoverPrimary focus:text-primary focus:border-black',
      isSelected && (Tag === 'a' || Tag === 'span') && 'bg-primary text-white border-transparent',
      className
    );

    return (
      <Tag ref={ref} onClick={onClick} className={textVariantStyle} {...rest}>
        {label}
      </Tag>
    );
  }
);

Chips.displayName = 'Chips';
