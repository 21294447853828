import { InsightscategoryItem } from '@/lib/api/types/basic';
import { InsightscategoryItemDocument } from '@/lib/api/types/hooks';
import {
  InsightscategoryItemQuery,
  InsightscategoryItemQueryVariables
} from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * It checks if the data is not undefined and if it is, it returns true
 * @param {any} data - any - this is the data that we're checking to see if it's an
 * InsightscategoryItem
 * @returns The function isInsightsCategoryItem is being returned.
 */
const isInsightsCategoryItem = (data: any): data is InsightscategoryItem => {
  return data !== undefined;
};

/**
 * It gets a single item from the CMS and returns it as a strongly typed object
 * @param {InsightscategoryItemQueryVariables} variables - InsightscategoryItemQueryVariables
 * @returns a promise that resolves to an InsightscategoryItem or null.
 */
export const getInsightsCategory = async (
  variables: InsightscategoryItemQueryVariables
): Promise<InsightscategoryItem | null> => {
  const { data } = await Api.getPageItem<
    InsightscategoryItemQuery,
    InsightscategoryItemQueryVariables
  >(InsightscategoryItemDocument, variables);

  return isInsightsCategoryItem(data?.InsightscategoryItem) ? data?.InsightscategoryItem : null;
};
