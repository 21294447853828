export const cardItemsCategory = 'cardItems.allCategory';

export const cardJobWorkspace = 'cardJob.jobWorkspace';
export const cardJobArea = 'cardJob.jobArea';
export const cardJobLevel = 'cardJob.jobLevel';

export const cardDefaultCategories = 'cardDefault.categories';
export const cardAwardCategories = 'cardAward.categories';
export const cardPartnerCategories = 'cardPartner.categories';
export const cardQualificationsCategories = 'cardQualifications.categories';

export const globalDataContent = 'globalDataContent.data';

export const relations = [
  cardDefaultCategories,
  cardItemsCategory,
  cardJobWorkspace,
  cardJobArea,
  cardJobLevel,
  cardAwardCategories,
  cardPartnerCategories,
  cardQualificationsCategories,
  globalDataContent
].join(',');
