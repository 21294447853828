import { FC } from 'react';

import { Image } from '@/components/shared/Image';

import { CaseStudyProps } from '../../CaseStudy';
import { ImageLabelProps } from '../../utils';
import { ImageLabel } from '../ImageLabel/ImageLabel';

interface Props {
  image: CaseStudyProps['image'];
  imageLabels?: ImageLabelProps[];
  priority?: boolean;
}

export const CaseStudyImage: FC<Props> = ({ image, imageLabels, priority }) => {
  const labels = imageLabels?.filter(({ accesses }) => accesses);

  return (
    <div className="w-full lg:w-[50%]">
      <div className="relative w-auto h-[41.6rem] lg:h-[90.6rem]">
        <Image
          src={image.filename}
          alt={image.alt || ''}
          layout="fill"
          objectFit="contain"
          priority={priority}
          objectPosition="center"
        />
        <ul className="flex flex-col gap-[0.8rem] max-w-[17rem] lg:max-w-[25.6rem] absolute top-[6rem] lg:top-[24rem] right-[10%] md:right-[25%] lg:right-[5%]">
          {labels?.map((item) => (
            <ImageLabel key={item.id} {...item} />
          ))}
        </ul>
      </div>
    </div>
  );
};
