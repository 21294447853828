import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC, MouseEventHandler } from 'react';

import { burgerVariants } from './animations';
import { Line } from './Line';

interface Props {
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isOpen?: boolean;
  className?: string;
}

const { top, bottom } = burgerVariants;

const labelStyles = clsx(
  'hidden lg:block text-black text-[2rem] leading-[2.4rem] font-bold',
  'group-hover:text-hoverPrimary transition-colors',
  'group-focus:text-primary'
);

export const Hamburger: FC<Props> = ({ label, onClick, isOpen = false, className }) => {
  const wrapperStyles = clsx(
    'group m-0 p-[0.4rem] flex items-center gap-[1.5rem] outline-0',
    className
  );

  const variant = isOpen ? 'opened' : 'closed';

  return (
    <motion.button
      aria-label="menu burger"
      onClick={onClick}
      initial="closed"
      animate={variant}
      className={wrapperStyles}
    >
      {label && <span className={labelStyles}>{label}</span>}
      <div className="flex flex-col justify-center gap-[0.8rem]  w-[4rem] h-[2rem] z-30 lg:z-10">
        <Line variants={top} />
        <Line variants={bottom} />
      </div>
    </motion.button>
  );
};
