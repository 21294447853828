import { Dropdown } from '@/components/shared/FormComponents/Dropdown';

import { DropdownElements, FilterChangeInput, FilterType } from '../../types';
import { itemToString } from '../../utils';

interface FilterItemProps<TFilteredItem> {
  label?: string;
  items: TFilteredItem[];
  onItemChange: (data: FilterChangeInput) => void;
  type: FilterType;
}

export const FilterItem = <TFilteredItem extends DropdownElements>({
  label,
  items,
  onItemChange,
  type
}: FilterItemProps<TFilteredItem>) => {
  if (!items) {
    return null;
  }

  const handleOnItemChange = ({ id }: TFilteredItem) => onItemChange({ type, id });

  return (
    <div className="w-full flex flex-col gap-[1.6rem]">
      {label && (
        <span className="text-[3.2rem] font-bold leading-[3.8rem] text-black">{label}</span>
      )}
      <Dropdown
        defaultSelectedItem={items[0]}
        items={items}
        defaultLabel=""
        itemToString={itemToString}
        onItemChange={handleOnItemChange}
      />
    </div>
  );
};
