import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';

import { RichText as IRichText } from '@/lib/types/richContent';
import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';

import { RichText } from '../RichText';

interface NavLink {
  name: string;
  link: StoryblokLink;
  component: string;
  _uid: string;
  _editable: string;
}

export interface ImageTextItemProps {
  image: StoryblokImage;
  title?: string;
  description?: IRichText;
  links?: NavLink[];
  className?: string;
  priority?: boolean;
}

// Task: BIWR-12
export const ImageText: FC<ImageTextItemProps> = ({
  image,
  description,
  links,
  title,
  className,
  priority
}) => {
  const { filename, alt } = image;

  const withLinks = links && links.length > 0;

  return (
    <div className={className}>
      <div className="w-auto h-[44rem] lg:h-[61rem] relative mb-[2.4rem]">
        <Image priority={priority} src={filename} alt={alt || ''} layout="fill" objectFit="cover" />
      </div>
      <div className="px-[1.6rem] lg:px-0">
        {title && (
          <h4 className="text-black font-bold text-[1.8rem] lg:text-[2.4rem] leading-[2.6rem] lg:leading-[3.4rem] mb-[0.8rem]">
            {title}
          </h4>
        )}
        {description && <RichText content={description} />}

        {withLinks && (
          <ul className="mt-[1.6rem] lg:mt-[2.4rem] flex items-center gap-[2.4rem]">
            {links.map((link) => (
              <li key={link._uid}>
                <SBLink link={link.link}>
                  <ButtonLink>{link.name}</ButtonLink>
                </SBLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
