import { FC } from 'react';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

export type ListVariant = 'number' | 'dot';

export interface ListProps {
  items?: BlokDynamicZone[];
  variant?: ListVariant;
  bgColor?: BackgroundColumnColor;
}

export const List: FC<ListProps> = ({ items = [], variant = 'dot', bgColor = 'white' }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <ul className="flex flex-col gap-[0.8rem mb-[3.2rem]">
      {items.map((blok, index) => (
        <BlokProvider key={blok._uid} blok={{ ...blok, index, variant, bgColor }} />
      ))}
    </ul>
  );
};
