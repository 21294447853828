import React, { FC } from 'react';

import { List, ListProps } from '@/components/nextjs/List';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBListProps = ListProps & BlokCommonProps;

export const SBList: FC<BlokProps<SBListProps>> = ({ blok }) => {
  const { items, variant, bgColor } = blok;

  return <List items={items} variant={variant} bgColor={bgColor} />;
};
