import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { GallerySlider, GallerySliderProps } from '@/components/nextjs/GallerySlider';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBGallerySliderProps = GallerySliderProps & BlokCommonProps & SpaceBottomProp;

export const SBGallerySlider: FC<BlokProps<SBGallerySliderProps>> = ({ blok }) => {
  const { images, linkLabel, link, spaceBottom, topContant, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <GallerySlider
      id={id}
      uuid={blok._uid}
      topContant={topContant}
      images={images}
      linkLabel={linkLabel}
      link={link}
      className={styles}
    />
  );
};
