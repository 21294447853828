import { FC } from 'react';

interface Props {
  className?: string;
}

export const ArrowIcon: FC<Props> = ({ className }) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.888916 5.25C0.474702 5.25 0.138916 5.58579 0.138916 6C0.138916 6.41421 0.474702 6.75 0.888916 6.75V5.25ZM17.4192 6.53033C17.7121 6.23744 17.7121 5.76256 17.4192 5.46967L12.6463 0.696699C12.3534 0.403806 11.8785 0.403806 11.5856 0.696699C11.2927 0.989593 11.2927 1.46447 11.5856 1.75736L15.8283 6L11.5856 10.2426C11.2927 10.5355 11.2927 11.0104 11.5856 11.3033C11.8785 11.5962 12.3534 11.5962 12.6463 11.3033L17.4192 6.53033ZM0.888916 6.75L16.8889 6.75V5.25L0.888916 5.25V6.75Z"
      fill="currentColor"
    />
  </svg>
);
