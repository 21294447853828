import { FC } from 'react';

import { GallerySliderItem, GallerySliderItemProps } from '@/components/shared/GallerySliderItem';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBGallerySliderItemProps = GallerySliderItemProps & BlokCommonProps;

export const SBGallerySliderItem: FC<BlokProps<SBGallerySliderItemProps>> = ({ blok }) => {
  const { image, title } = blok;

  return <GallerySliderItem image={image} title={title} />;
};
