import clsx from 'clsx';
import { ReactNode } from 'react';

export interface TabsGroupProps {
  children?: ReactNode;
  className?: string;
}

export const TabsGroup = ({ children, className }: TabsGroupProps) => {
  const tabsGroupStyles = clsx('flex gap-[1.6rem] lg:gap-[3.2rem]', className);

  return (
    <div className={tabsGroupStyles} aria-label="Tabs" role="tablist">
      {children}
    </div>
  );
};
