/**
 * It returns a string that is a combination of the current time in base 36 and a random number in base
 * 36
 * @returns A string of 36 characters.
 */
export const uid = () => {
  const head = Date.now().toString(36);
  const tail = Math.random().toString(36).substring(2);

  return head + tail;
};
