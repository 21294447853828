import { FC } from 'react';

import {
  ContactLayerPersonContact,
  ContactLayerPersonContactProps
} from '@/components/layout/ContactLayer/components/ContactLayerPersonContact';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBContactLayerPersonContactProps = ContactLayerPersonContactProps & BlokCommonProps;

export const SBContactLayerPersonContact: FC<BlokProps<SBContactLayerPersonContactProps>> = ({
  blok
}) => {
  const { image, name, occupation, contact, contactLabel } = blok;

  return (
    <ContactLayerPersonContact
      image={image}
      name={name}
      occupation={occupation}
      contact={contact}
      contactLabel={contactLabel}
    />
  );
};
