import clsx from 'clsx';
import dynamic from 'next/dynamic';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useHeaderContext } from '@/components/layout/HeaderProvider';
import { Panel, PanelGroup, Tabs, TabsGroup } from '@/components/shared/Tabs';
import 'swiper/css/bundle';
import { BlokProvider } from '@/components/storyblok/BlokProvider';

import type { TabItem } from '../../TabsSection';

const Tab = dynamic<any>(() => import('@/components/shared/Tabs').then((mod) => mod.Tab));

SwiperCore.use([Navigation]);

interface TabsContentProps {
  items?: TabItem[];
  isSticky?: boolean;
  panelsWithFilters?: string[];
}

export const TabsContent = ({ items, isSticky = false, panelsWithFilters }: TabsContentProps) => {
  const { isHeaderSticky } = useHeaderContext();

  if (!items) {
    return null;
  }

  return (
    <Tabs
      defaultActive={Array.isArray(items) && items.length > 0 ? items[0]._uid : undefined}
      render={({ activeTab }) => (
        <>
          <TabsGroup
            className={clsx(
              'px-[1.6rem] 3xl:px-0 pt-[2.4rem] pb-[2.4rem] w-full bg-white lg:shadow-none',
              isSticky && 'shadow-bestIt lg:shadow-none sticky lg:static z-10 !pb-[2.4rem]',
              isSticky &&
                panelsWithFilters?.includes(activeTab as string) &&
                '!pb-[8rem] lg:!pb-[2.4rem]',
              isHeaderSticky ? '!static' : 'top-0'
            )}
          >
            <Swiper
              style={{
                width: '100%',
                height: '100%'
              }}
              speed={500}
              grabCursor
              slidesPerView="auto"
              spaceBetween={0}
            >
              {items.map((item) => (
                <SwiperSlide key={item?._uid} style={{ maxWidth: 'max-content' }}>
                  <Tab
                    key={item._uid}
                    id={item._uid!}
                    className="mr-[1.6rem] lg:mr-[3.2rem] min-h-[10rem]"
                  >
                    {item.label}
                  </Tab>
                </SwiperSlide>
              ))}
            </Swiper>
          </TabsGroup>

          <PanelGroup>
            {items.map((item) => (
              <Panel key={item._uid} id={item._uid!}>
                {item?.tabPanel?.map((panel) => (
                  <BlokProvider key={panel._uid} blok={panel} />
                ))}
              </Panel>
            ))}
          </PanelGroup>
        </>
      )}
    />
  );
};
