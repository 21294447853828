import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';

import { Chips } from '@/components/shared/Chips';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { useLang } from '@/hooks/useLang';

import { InsightscategoryItem } from '@/lib/api/types/basic';

import { getFilteredCards } from '../../utils';

interface CategoryFiltersProps {
  categories: InsightscategoryItem[];
  activeCategoryID: null | number;
  onCategoryChange: (id: number | null) => void;
  items?: BlokDynamicZone[];
  maxItems?: number;
  changeShowMoreVisible: Dispatch<SetStateAction<boolean>>;
  setCardItems: Dispatch<SetStateAction<BlokDynamicZone[] | undefined>>;
}

SwiperCore.use([Navigation]);

const allLabel = {
  en: 'All',
  de: 'Alle',
  'de-at': 'Alle'
};

export const CategoryFilters: FC<CategoryFiltersProps> = ({
  categories,
  activeCategoryID,
  onCategoryChange,
  items,
  maxItems,
  changeShowMoreVisible,
  setCardItems
}) => {
  const { locale } = useLang();
  /* Filtering the cards based on the active category. */
  useEffect(() => {
    if (!items) {
      return;
    }
    const filteredCards = getFilteredCards(items, activeCategoryID);
    const maxFilteredItems = maxItems ?? filteredCards.length;

    changeShowMoreVisible(maxFilteredItems < filteredCards.length);
    setCardItems(filteredCards.slice(0, maxFilteredItems));
  }, [activeCategoryID]);

  return (
    <Swiper
      style={{
        width: '100%',
        height: '100%'
      }}
      speed={500}
      grabCursor
      slidesPerView="auto"
      spaceBetween={8}
      breakpoints={{
        800: {
          grid: { rows: 1 }
        },
        1280: {
          grid: { rows: 2 }
        }
      }}
    >
      <SwiperSlide style={{ maxWidth: 'max-content' }} className="mt-[20px]">
        <Chips
          onClick={() => onCategoryChange(null)}
          label={allLabel[locale]}
          as="span"
          isSelected={!activeCategoryID}
          role="button"
        />
      </SwiperSlide>
      {categories.map((item) => (
        <SwiperSlide key={item?.id} style={{ maxWidth: 'max-content' }} className="mt-[20px]">
          <Chips
            onClick={() => onCategoryChange(item.id)}
            label={item.content?.name || ''}
            as="span"
            isSelected={activeCategoryID === item.id}
            role="button"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
