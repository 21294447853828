import { InsightscategoryItems } from '@/lib/api/types/basic';
import { InsightsCategoryItemsDocument } from '@/lib/api/types/hooks';
import {
  InsightsCategoryItemsQueryVariables,
  InsightsCategoryItemsQuery
} from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * The function isInsightsCategories takes in a parameter called data and returns true if data is not
 * undefined
 * @param {any} data - any - this is the data that we're checking to see if it's an
 * InsightscategoryItems object.
 * @returns The function isInsightsCategories is being returned.
 */
const isInsightsCategories = (data: any): data is InsightscategoryItems => {
  return data !== undefined;
};

/**
 * It gets the data from the API and returns it if it's valid
 * @param {InsightsCategoryItemsQueryVariables} variables - InsightsCategoryItemsQueryVariables
 * @returns An array of objects with the following properties:
 */
export const getInsightsCategories = async (
  variables: InsightsCategoryItemsQueryVariables
): Promise<InsightscategoryItems | null> => {
  const { data } = await Api.getPageItems<
    InsightsCategoryItemsQuery,
    InsightsCategoryItemsQueryVariables
  >(InsightsCategoryItemsDocument, variables);

  return isInsightsCategories(data?.InsightscategoryItems) ? data.InsightscategoryItems : null;
};
