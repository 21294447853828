import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

interface GetAccessibilityLabelInput {
  alt?: string | null;
  items?: BlokDynamicZone[];
}

/**
 * This TypeScript function returns the title or alt text of an item, or a default value if neither are
 * available.
 * @param {string} defaultValue - The default value to be returned if no suitable title or alt text is
 * found.
 * @param {GetAccessibilityLabelInput}  - The function `getAccessibilityLabel` takes two parameters:
 * @returns The function `getAccessibilityLabel` returns a string value which is either the `title` of
 * the first item in the `items` array that has a `component` property equal to `'typography'`, or the
 * `alt` value passed as an argument, or the `defaultValue` passed as an argument.
 */
export const getAccessibilityLabel = (
  defaultValue: string,
  { items, alt }: GetAccessibilityLabelInput
): string => {
  const title = items?.find((item) => item.component === 'typography')?.children;

  return title || alt || defaultValue;
};
