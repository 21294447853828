import { FC } from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

interface Props {
  items: BlokDynamicZone[];
}

export const DesktopItems: FC<Props> = ({ items = [] }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="hidden xl:block lg:mt-[4rem]">
      <ul className="grid grid-cols-responsive-fill-card gap-[3.4rem] ">
        {items.map((blok) => (
          <li key={blok._uid} className="w-full">
            <BlokProvider blok={blok} />
          </li>
        ))}
      </ul>
    </div>
  );
};
