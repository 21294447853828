import clsx from 'clsx';
import { motion } from 'framer-motion';

import { ColumnContent } from '@/components/nextjs/ColumnContent';
import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';
import { Typography } from '@/components/shared/Typography';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { InsightscategoryItem } from '@/lib/api/types/basic';
import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';
import { getNumber } from '@/lib/utils';

import { getAccessibilityLabel } from './utils';
import { isChipWithLink } from './utils/isChipWithLink';

export interface CardDefaultProps {
  image?: StoryblokImage;
  arrowLink?: StoryblokLink;
  items?: BlokDynamicZone[];
  number?: number | string;
  categories?: InsightscategoryItem[];
}

const variants = {
  initial: {
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)'
  },
  animate: { boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)' }, // fix for boxShadow with LayoutGroup & AnimatePresence components
  hover: {
    scale: 1.05,
    boxShadow: '0px 16px 28px rgba(0, 0, 0, 0.08)',
    transition: {
      duration: 0.2
    }
  }
};

export const CardDefault = ({ image, arrowLink, items, number }: CardDefaultProps) => {
  const cardNumber = getNumber(number);
  const hasChipsWithLinks = items?.some(
    (item) => isChipWithLink(item) || item?.items?.some(isChipWithLink)
  );

  const withArrow = arrowLink && (arrowLink.cached_url || arrowLink.url);

  const hasArrow = !!withArrow;
  const hasImage = image && image?.filename;

  const withTopContainer = !!cardNumber || !!withArrow;
  const withWholeCardLink = hasArrow && !hasChipsWithLinks;

  const columnContentStyles = clsx(
    image?.filename && 'mt-[1.6rem]',
    !image?.filename && withArrow && 'pr-[0.5rem]'
  );

  const cardClasses = clsx(
    'w-full h-full bg-white p-[1.6rem] lg:px-[3.2rem] lg:py-[4.2rem] relative',
    withWholeCardLink && 'cursor-pointer'
  );

  const ariaLabelLink = getAccessibilityLabel('Card Link', { items, alt: image?.alt });

  const topTypographyItem =
    items?.[0]?.component === 'typography' && !hasImage && withArrow ? [items[0]] : [];
  const restItems = items?.slice(topTypographyItem.length);

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      whileHover="hover"
      className={cardClasses}
    >
      {withTopContainer && (
        <div className="flex justify-between items-center mb-[1.6rem] lg:mb-[2rem]">
          {cardNumber && (
            <Typography variant="preline" tag="p">
              {cardNumber}
            </Typography>
          )}
          {withArrow && (
            <SBLink link={arrowLink}>
              <ButtonLink variant="arrow" as="a" aria-hidden="true" className="ml-auto" />
            </SBLink>
          )}
        </div>
      )}

      {!!hasImage && withArrow && (
        <SBLink link={arrowLink}>
          <a aria-label={ariaLabelLink}>
            <div className="relative aspect-video max-h-[130px] pointer-events-none">
              <Image src={image.filename} alt={image.alt || ''} layout="fill" objectFit="contain" />
            </div>
          </a>
        </SBLink>
      )}
      {!!hasImage && !withArrow && (
        <div className="relative aspect-video max-h-[130px] pointer-events-none">
          <Image src={image.filename} alt={image.alt || ''} layout="fill" objectFit="contain" />
        </div>
      )}
      {!!topTypographyItem.length && (
        <SBLink link={arrowLink}>
          <a aria-label={ariaLabelLink}>
            <ColumnContent items={topTypographyItem} className={columnContentStyles} />
          </a>
        </SBLink>
      )}

      <ColumnContent items={restItems} className={columnContentStyles} />
      {withWholeCardLink && (
        <SBLink link={arrowLink}>
          <a className="absolute inset-0 w-full h-full z-10" aria-label={ariaLabelLink} />
        </SBLink>
      )}
    </motion.div>
  );
};
