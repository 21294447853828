import { Dispatch, FC, SetStateAction } from 'react';

import { ShowMore } from '@/components/shared/ShowMore';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { getFilteredCards } from '../../utils';

interface ShowMoreCardItemsProps {
  showMoreLabel: string;
  items?: BlokDynamicZone[];
  activeCategoryID: number | null;
  setCardItems: Dispatch<SetStateAction<BlokDynamicZone[] | undefined>>;
  setIsShowMoreVisible: Dispatch<SetStateAction<boolean>>;
}

export const ShowMoreCardItems: FC<ShowMoreCardItemsProps> = ({
  showMoreLabel,
  items,
  activeCategoryID,
  setCardItems,
  setIsShowMoreVisible
}) => {
  const handleShowAllCards = () => {
    if (!items) {
      return;
    }

    const filteredCards = getFilteredCards(items, activeCategoryID);

    setCardItems(filteredCards);
    setIsShowMoreVisible(false);
  };

  return (
    <ShowMore
      className="mt-[1.6rem] lg:mt-[4.5rem]"
      label={showMoreLabel}
      onClick={handleShowAllCards}
      isOpen={false}
    />
  );
};
