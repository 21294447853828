import { excludingSlugs } from '@/lib/api/excludingSlugs';
import { relations } from '@/lib/api/relations';
import {
  getFooter,
  getHeader,
  getInsightsCategories,
  getInsightsCategory,
  getInsightsPosts
} from '@/lib/api/utils';
import { INSIGHTS_BLOG_POSTS_PER_PAGE } from '@/lib/consts';
import { InsightRootProps, SlugParams } from '@/lib/types';

import { getBottomSliderData } from '../../getBottomSliderData';
import { getSlugFromParams } from '../../getSlugFromParams';
import { TemplatesType } from '../../getTemplateType';
import { getAsideData } from '../getAsideData';

interface GetInsightRootTemplateDataInput {
  locale: string;
  preview: boolean;
  params?: SlugParams;
  templateType: TemplatesType;
}

interface GetInsightRootTemplateDataOutput {
  props: InsightRootProps;
}

/**
 * It gets the data for the Insight Root Template
 * @param {GetInsightRootTemplateDataInput}  - GetInsightRootTemplateDataInput
 * @returns An object with a property called props.
 */
export const getInsightRootTemplateData = async ({
  locale,
  params,
  templateType,
  preview
}: GetInsightRootTemplateDataInput): Promise<GetInsightRootTemplateDataOutput> => {
  let slug = getSlugFromParams({ params });
  let page: string | undefined = undefined;

  if (params && Array.isArray(params.slug) && params.slug.includes('page')) {
    const [root, number] = params.slug.filter((url) => url !== 'page');
    page = number;
    slug = getSlugFromParams({ params: { slug: [root] } });
  } else {
    slug = getSlugFromParams({ params });
  }

  const footerData = await getFooter({ slug: `${locale}/layout/footer` });
  const headerData = await getHeader({ slug: `${locale}/layout/header` });
  const data = await getInsightsCategory({ slug: `${locale}/${slug}`, relations });

  const categories = await getInsightsCategories({
    startsWith: `${locale}/`,
    sortBy: 'content.name:asc',
    excludingSlugs
  });

  const posts = await getInsightsPosts({
    perPage: data?.is_startpage ? INSIGHTS_BLOG_POSTS_PER_PAGE : 100,
    page: page ? +page : null,
    categoryFilter: data?.is_startpage ? [] : [data?.uuid ?? ''],
    startsWith: `${locale}/`,
    sortBy: 'sort_by_date:desc'
  });

  const bottomSliderData = await getBottomSliderData(data?.content?.bottomSliderType, locale);

  const withAsideLinks = data?.content?.withAsideLinks ?? true;

  const aside = await getAsideData({ slug, locale, withAsideLinks });

  return {
    props: {
      data,
      headerData,
      footerData,
      preview,
      templateType,
      categories,
      posts,
      bottomSliderData,
      aside
    }
  };
};
