const germanyDefaultSeo = {
  defaultMetaTitle: 'Best it Digitalagentur für E-Commerce Solutions & UX',
  defaultMetaDescription:
    'Wir als best it Digitalagentur & E-Commerce Agentur kreieren für Sie einzigartige Einkaufserlebnisse mit innovativen Technologien & End-to-End Prozessen!'
};

const englishDefaultSeo = {
  defaultMetaTitle: 'E-Commerce & Customer Experience | best it',
  defaultMetaDescription:
    'We create unique shopping experiences with innovative technologies and end-to-end processes.'
};

export const defaultSeoData = {
  de: germanyDefaultSeo,
  'de-at': germanyDefaultSeo,
  en: englishDefaultSeo
};
