import clsx from 'clsx';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

export type ButtonVariant = 'primary' | 'secondary';
export type Tag = 'button' | 'a';

interface Props {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  variant?: ButtonVariant;
  className?: string;
  classNameIcon?: string;
  disabled?: boolean;
  withIcon?: boolean;
  as?: Tag;
  type?: 'button' | 'submit';
}

const buttonVariantStyles: Record<ButtonVariant, string> = {
  primary: `px-[6rem] py-[1.2rem] bg-primary text-white text-[1.6rem] font-bold inline-block
    hover:bg-hoverPrimary
    focus:border-black focus:border-2
    visited:border visited:border-black
    disabled:bg-gray-200 disabled:text-white disabled:cursor-not-allowed`,
  secondary: `px-[6rem] py-[1.2rem] bg-white text-primary border text-[1.6rem] font-bold border-primary inline-block
    hover:text-hoverPrimary hover:border-hoverPrimary  
    focus:border-black focus:border-2
    active:text-black 
    visited:border visited:border-black
    disabled:bg-white disabled:text-gray-200 disabled:border disabled:border-current disabled:cursor-not-allowed`
};

// Task: BIWR-4
export const Button = forwardRef<HTMLElement, Props>(
  (
    {
      children,
      onClick,
      as: Tag = 'button',
      className,
      classNameIcon,
      variant = 'primary',
      disabled = false,
      withIcon = false,
      type,
      ...rest
    },
    ref
  ) => {
    const buttonStyles = clsx(
      'transition-colors',
      buttonVariantStyles[variant],
      withIcon && 'group flex items-center justify-between',
      className
    );
    const arrowStyles = clsx('ml-2 group-hover:translate-x-1 transition-transform', classNameIcon);

    return (
      <Tag
        // TODO check
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        className={buttonStyles}
        type={type}
        {...rest}
      >
        {children}
        {withIcon && <ArrowIcon className={arrowStyles} />}
      </Tag>
    );
  }
);

Button.displayName = 'Button';
