export type SpacingVariants = 'none' | 'sm' | 'xs' | 'lg' | 'default';

export interface SpaceBottomProp {
  spaceBottom?: SpacingVariants;
}

export const spacingBottom: Record<SpacingVariants, string | undefined> = {
  none: '',
  xs: 'mb-[1.6rem] lg:mb-[2.4rem]',
  sm: 'mb-[1.6rem] lg:[3.2rem]',
  lg: 'mb-[3.2rem] lg:[4.4rem]',
  default: 'mb-[4.4rem] lg:mb-[8.8rem]'
};

export const columnGap: Record<SpacingVariants, string> = {
  none: '',
  xs: 'gap-[0.4rem] lg:mb-[0.6rem]',
  sm: 'gap-[0.6rem] lg:mb-[1rem]',
  lg: 'gap-[1rem] lg:mb-[1.6rem]',
  default: 'gap-[0.4rem] lg:mb-[0.6rem]'
};
