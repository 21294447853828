import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { FC, ReactElement, ReactNode } from 'react';

import '@/styles/globals.css';
import '@/styles/fonts.css';

import { MainLayout } from '@/components/layout/MainLayout';
import { MainProvider } from '@/components/layout/MainProvider';

const Cookiebot = dynamic<object>(
  () => import('@/lib/Cookiebot/Cookiebot').then((mod) => mod.Cookiebot),
  {
    ssr: false
  }
);

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, pageProps?: AppProps['pageProps']) => ReactNode;
};

interface Props extends AppProps {
  Component: NextPageWithLayout;
}

const MyApp: FC<Props> = ({ Component, pageProps }) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? getDefaultLayout;

  return (
    <MainProvider pageProps={pageProps}>
      <Cookiebot />
      {getLayout(<Component {...pageProps} />, pageProps)}
    </MainProvider>
  );
};

const getDefaultLayout = (page: ReactNode, pageProps?: AppProps['pageProps']) => (
  <MainLayout {...pageProps}>{page}</MainLayout>
);

export default MyApp;
