import axios from 'axios';
import { useState, useRef } from 'react';

import { Button } from '@/components/shared/Button';
import { Checkbox } from '@/components/shared/FormComponents/Checkbox';
import { Input } from '@/components/shared/FormComponents/Input';

export const WhitePaperForm = () => {
  // Main Fields
  const [amrede, setAmrede] = useState('');
  const [vorname, setVorname] = useState('');
  const [nachname, setNachname] = useState('');
  const [firma, setFirma] = useState('');
  const [email, setEmail] = useState('');
  const [ecommerce, setEcommerce] = useState('');
  const [terms, setTerms] = useState('');
  const [hiddenField, setHiddenField] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  //Error Messages
  const [amredeErr, setAmredeErr] = useState('');
  const [vornameErr, setVornameErr] = useState('');
  const [nachnameErr, setNachnameErr] = useState('');
  const [firmaErr, setFirmaErr] = useState('');
  const [emailErr, setEmailErr] = useState('');

  const requiredField = 'Feld ist erforderlich';

  const lenghtText = 'Das Feld muss eine Länge von mindestens 3 haben';

  const successMessage = 'Vielen Dank! Nachricht gesendet!';

  const isValidEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const resetValues = (): void => {
    setAmrede('nada');
    setVorname('');
    setNachname('');
    setFirma('');
    setEcommerce('Nein');
    setTerms('Y');
    setHiddenField('');
    setAmredeErr('');
    setVornameErr('');
    setNachnameErr('');
    setFirmaErr('');
  };

  const validate = (): boolean => {
    if (hiddenField.length > 0) {
      return false;
    }

    if (amrede === 'nada') {
      setAmredeErr(requiredField);

      return false;
    }

    if (vorname.length === 0) {
      setVornameErr(requiredField);

      return false;
    }

    if (vorname.length <= 3) {
      setVornameErr(lenghtText);

      return false;
    }

    if (nachname.length === 0) {
      setNachnameErr(requiredField);

      return false;
    }

    if (nachname.length <= 3) {
      setNachnameErr(lenghtText);

      return false;
    }

    if (firma.length === 0) {
      setFirmaErr(requiredField);

      return false;
    }

    if (nachname.length <= 3) {
      setFirmaErr(lenghtText);

      return false;
    }

    if (email.length === 0) {
      setEmailErr(requiredField);

      return false;
    }

    if (email.length <= 3) {
      setEmailErr(lenghtText);

      return false;
    } else {
      if (!isValidEmail(email)) {
        setEmailErr('Die E-Mail-adresse ist nicht gültig');
      }
    }

    return true;
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);
    const validation = validate();
    if (validation) {
      axios
        .post('/user', {
          params: {
            u: '126c6250e1fb4224225a3c2d9',
            id: 'dabfe88b7e',
            v_id: '3385',
            f_id: '00326de0f0',
            ANREDE: amrede,
            FNAME: vorname,
            LNAME: nachname,
            MMERGE4: firma,
            EMAIL: email,
            ECOMMERCE: ecommerce,
            'gdpr[27346]': terms
          }
        })
        .then(function () {
          resetValues();
          setLoading(false);
          setSuccess(true);
        })
        .catch(function () {
          setSuccess(false);
        })
        .finally(function () {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {success ? (
        <div>
          <p>{successMessage}</p>
        </div>
      ) : (
        <form
          className="px-[1.6rem] md:px-[3.6rem] mb-[100px]"
          ref={form}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div
            className={
              'sm:grid grid-rows-2 grid-flow-col gap-4 max-w-[160rem] mx-auto w-full bg-white mb-[1.4rem]'
            }
          >
            <div>
              <select
                value={amrede}
                onChange={(e) => setAmrede((e.target as HTMLSelectElement).value)}
                name={'ANREDE'}
                className={
                  'mt-10 lg:mt-0 w-full border border-primary  h-[52px] text-[19px] pl-[15px]'
                }
              >
                <option value="nada">Anrede {amredeErr ? '*' : ''}</option>
                <option value="her">Herr</option>
                <option value="frau">Frau</option>
                <option value="diverse">Divers</option>
              </select>
              {amredeErr ? <span>{amredeErr}</span> : null}
            </div>

            <div>
              <Input
                value={vorname}
                onChange={(e) => setVorname((e.target as HTMLInputElement).value)}
                name={'FNAME'}
                required={true}
                className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
              />
              {vornameErr ? <span>{vornameErr}</span> : null}
            </div>

            <div>
              <Input
                placeholder={'Nnachname'}
                value={nachname}
                onChange={(e) => setNachname((e.target as HTMLInputElement).value)}
                name={'LNAME'}
                required={true}
                className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
              />

              {nachnameErr ? <span>{nachnameErr}</span> : null}
            </div>

            <div>
              <Input
                placeholder={'Firma'}
                value={firma}
                onChange={(e) => setFirma((e.target as HTMLInputElement).value)}
                name={'MMERGE4'}
                required={true}
                className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
              />

              {firmaErr ? <span>{firmaErr}</span> : null}
            </div>

            <div>
              <Input
                placeholder={'Email'}
                value={email}
                onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                name={'MMERGE4'}
                required={true}
                className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
              />

              {emailErr ? <span>{emailErr}</span> : null}
            </div>

            <div>
              <h4>Planen Sie in demnächst ein E-Commerce Projekt?</h4>
              <input
                type={'radio'}
                name={'ECOMMERCE'}
                value={'JA'}
                onChange={(e) => setEcommerce((e.target as HTMLInputElement).value)}
              />

              <input
                type={'radio'}
                name={'ECOMMERCE'}
                value={'NEIN'}
                onChange={(e) => setEcommerce((e.target as HTMLInputElement).value)}
              />
            </div>

            <div>
              <Checkbox name={'gdpr[27346]'} required={true} onChange={() => true} />

              <span>Ich akzeptiere die Datenschutzerklärung.</span>
            </div>

            <p>
              Sie können sich jederzeit abmelden, indem Sie auf den Link in der Fußzeile unserer
              E-Mails klicken. Informationen zu unseren Datenschutzpraktiken finden Sie auf unserer
              Website.
            </p>

            <p>
              Wir verwenden Mailchimp als unsere Marketingplattform. Indem Sie unten zum Abonnieren
              klicken, bestätigen Sie, dass Ihre Informationen werden zur Verarbeitung an Mailchimp
              übertragen.
              <a href="https://mailchimp.com/legal/terms" target="_blank" rel="noreferrer">
                Erfahren Sie hier mehr über die Datenschutzpraktiken von Mailchimp.
              </a>
            </p>

            <div>
              <Input
                name="b_126c6250e1fb4224225a3c2d9_dabfe88b7e"
                value={hiddenField}
                onChange={(e) => setHiddenField((e.target as HTMLInputElement).value)}
              />
            </div>

            <div className="text-center mt-10">
              <Button type="submit" className="mt-[2.6rem]">
                {loading ? 'Senden' : 'Schicken'}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
