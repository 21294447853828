import { FC } from 'react';

import {
  ContactLayerForm,
  ContactLayerFormProps
} from '@/components/layout/ContactLayer/components/ContactLayerForm';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBContactLayerFormProps = ContactLayerFormProps & BlokCommonProps;

export const SBContactLayerForm: FC<BlokProps<SBContactLayerFormProps>> = ({ blok }) => {
  const { checkboxLabel } = blok;

  return <ContactLayerForm checkboxLabel={checkboxLabel} />;
};
