import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { TabsSection, TabsSectionProps } from '@/components/nextjs/TabsSection';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBTabsSectionProps = TabsSectionProps & BlokCommonProps & SpaceBottomProp;

export const SBTabsSection: FC<BlokProps<SBTabsSectionProps>> = ({ blok }) => {
  const { bottomContent, items, topContent, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <TabsSection
      id={id}
      items={items}
      topContent={topContent}
      bottomContent={bottomContent}
      className={styles}
    />
  );
};
