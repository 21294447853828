import clsx from 'clsx';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useMemo, useState } from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { InsightscategoryItem } from '@/lib/api/types/basic';

import { CategoryFilters } from './components/CategoryFilters';
import { CategoryFiltersWrapper } from './components/CategoryFilters/components/CategoryFiltersWrapper';
import { ShowMoreCardItems } from './components/ShowMoreCardItems';
import { getCategories, getInitialItems, getInitialItemsShowMoreVisibleState } from './utils';

export interface CardItemsProps {
  filtersAvailable?: boolean;
  itemsWithCounter?: boolean;
  showMoreLabel?: string;
  maxItems?: number;
  allCategory?: InsightscategoryItem;
  items?: BlokDynamicZone[];
  topContent?: BlokDynamicZone[];
  className?: string;
  id?: string;
}

const cardVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4
    }
  }
};

export const CardItems = ({
  items,
  itemsWithCounter,
  filtersAvailable,
  showMoreLabel,
  maxItems,
  topContent,
  className,
  id
}: CardItemsProps) => {
  const [cardItems, setCardItems] = useState(getInitialItems(items, maxItems));
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(
    getInitialItemsShowMoreVisibleState(items, maxItems)
  );
  const [activeCategoryID, setActiveCategoryID] = useState<null | number>(null);

  /* A React hook that returns a memoized value. Pass a “create” function and an array of dependencies.
  useMemo will only recompute the memoized value when one of the dependencies has changed. This
  optimization helps to avoid expensive calculations on every render. */
  const categories = useMemo(() => getCategories(items), [items]);

  /**
   * It takes an id of type number or null and sets the activeCategoryID state to that id
   * @param {number | null} id - number | null
   */
  const handleChangeCategory = (id: number | null) => setActiveCategoryID(id);

  const wrapperStyles = clsx(
    'w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] 3xl:px-0 mb-[4.4rem] lg:mb-[8.8rem]',
    className
  );

  return (
    <div className={wrapperStyles} id={id}>
      {topContent && Array.isArray(topContent) && (
        <div className="mb-[1.6rem] lg:mb-[3.2rem]">
          {topContent.map((blok, index) => (
            <BlokProvider
              key={blok._uid}
              blok={{ ...blok, number: itemsWithCounter ? index + 1 : undefined }}
            />
          ))}
        </div>
      )}

      {filtersAvailable && categories && (
        <CategoryFiltersWrapper>
          <CategoryFilters
            items={items}
            maxItems={maxItems}
            categories={categories}
            activeCategoryID={activeCategoryID}
            onCategoryChange={handleChangeCategory}
            changeShowMoreVisible={setIsShowMoreVisible}
            setCardItems={setCardItems}
          />
        </CategoryFiltersWrapper>
      )}

      <div className="grid grid-cols-responsive-fill-card gap-[1.6rem] lg:gap-[3.2rem]">
        <LayoutGroup>
          <AnimatePresence initial={false} mode="popLayout">
            {cardItems?.map((blok, index) => (
              <motion.div
                key={blok._uid}
                layout="position"
                variants={cardVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <BlokProvider
                  blok={{ ...blok, number: itemsWithCounter ? index + 1 : undefined }}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </LayoutGroup>
      </div>

      {showMoreLabel && isShowMoreVisible && (
        <ShowMoreCardItems
          showMoreLabel={showMoreLabel}
          items={items}
          activeCategoryID={activeCategoryID}
          setCardItems={setCardItems}
          setIsShowMoreVisible={setIsShowMoreVisible}
        />
      )}
    </div>
  );
};
