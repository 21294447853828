import { DatasourceEntries } from '@/lib/api/types/basic';

/**
 * If the entries are not null, return the value of the entry with the given key, otherwise return
 * null.
 * @param {string} key - The key of the data source entry you want to retrieve.
 * @param {DatasourceEntries | null} [entries] - DatasourceEntries | null
 * @returns The value of the entry with the name matching the key.
 */
export const getDataSourceEntry = (key: string, entries?: DatasourceEntries | null) => {
  if (!entries) {
    return;
  }

  const item = entries?.items.find(({ name }) => name === key) ?? undefined;

  return item ? item.dimensionValue ?? item.value : undefined;
};
