interface IDynamicAttribute {
  [key: string]: string | number | undefined;
}

/**
 * Helper to validate attributes which can be added dynamically via the rest property
 * @param attributes
 */
export const getDynamicAttributes = (attributes: IDynamicAttribute) => {
  const result = Object.entries(attributes).filter((value) => {
    return value[1] !== undefined && value[1] !== '';
  });

  return Object.fromEntries(result);
};
