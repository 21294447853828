import { ComponentProps, ElementType, FC, ReactNode } from 'react';

import { cn } from '@/lib/utils';

type TypographyComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'strong' | 'p';
type ComponentVariant = ElementType & TypographyComponents;

type TypographyVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'bodySmall'
  | 'preline';
type BottomSpace = 'small' | 'normal' | 'large' | 'none';
export interface TypographyProps extends ComponentProps<'p'> {
  tag?: ComponentVariant;
  variant?: TypographyVariants;
  children?: ReactNode;
  bottomSpace?: BottomSpace;
  isBold?: boolean;
  isUppercase?: boolean;
  className?: string;
}

const typographyStyles: Record<TypographyVariants, string> = {
  h1: 'text-[4.8rem] lg:text-[12rem] leading-[5rem] lg:leading-[13rem]',
  h2: 'text-[3.2rem] lg:text-[6rem] leading-[3.8rem] lg:leading-[6.4rem]',
  h3: 'text-[2.4rem] lg:text-[3.2rem] leading-[3rem] lg:leading-[4.4rem]',
  h4: 'text-[1.6rem] lg:text-[3.2rem] leading-[2.4rem] lg:leading-[4.4rem]',
  h5: 'text-[1.6rem] lg:text-[2.4rem] leading-[2.4rem] lg:leading-[3.4rem]',
  h6: 'text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]',
  body: 'text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]',
  bodySmall: 'text-[1.4rem] lg:text-[1.4rem] leading-[2.2rem] lg:leading-[2.2rem]',
  preline:
    'text-[1.2rem] lg:text-[1.6rem] leading-[2rem] lg:leading-[2.8rem] !text-primary tracking-[0.35em] uppercase font-heading'
};

const bottomSpaceStyles: Record<BottomSpace, string> = {
  none: '',
  small: 'mb-[0.6rem] lg:mb-[0.8rem]',
  normal: 'mb-[1.6rem] lg:mb-[2rem]',
  large: 'mb-[2rem] lg:mb-[3.4rem]'
};

export const Typography: FC<TypographyProps> = ({
  tag = 'span',
  variant = 'body',
  isBold = false,
  isUppercase = false,
  bottomSpace = 'none',
  children,
  className,
  ...rest
}) => {
  const Component = tag;
  const variantStyles = cn(
    'transition-colors',
    typographyStyles[variant],
    bottomSpaceStyles[bottomSpace],
    isBold && 'font-bold',
    isUppercase && 'uppercase',
    className
  );

  return (
    <Component className={variantStyles} {...rest}>
      {children}
    </Component>
  );
};
