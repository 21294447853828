import clsx from 'clsx';

import { uid } from '@/lib/utils';

import { DropdownDesktop } from './DropdownDesktop';
import { DropdownMobile } from './DropdownMobile';
import { DropdownProps } from './types';

// Task: BIWR-5
export const Dropdown = <T,>({
  items,
  itemToString,
  defaultLabel,
  defaultSelectedItem,
  className,
  labelText,
  onItemChange,
  errorMessage,
  id = 'custom-id',
  disabled = false,
  render
}: DropdownProps<T>) => {
  const mobileStyles = clsx('lg:hidden', className);
  const desktopStyles = clsx('hidden lg:block', className);
  const uniqueID = uid();

  return (
    <>
      <DropdownMobile
        className={mobileStyles}
        onItemChange={onItemChange}
        items={items}
        id={`${uniqueID}-${id}-m`}
        defaultLabel={defaultLabel}
        defaultSelectedItem={defaultSelectedItem}
        labelText={labelText}
        errorMessage={errorMessage}
        itemToString={itemToString}
        disabled={disabled}
      />
      <DropdownDesktop
        className={desktopStyles}
        onItemChange={onItemChange}
        items={items}
        id={`${uniqueID}-${id}-d`}
        render={render}
        defaultLabel={defaultLabel}
        defaultSelectedItem={defaultSelectedItem}
        labelText={labelText}
        errorMessage={errorMessage}
        itemToString={itemToString}
        disabled={disabled}
      />
    </>
  );
};
