import dynamic from 'next/dynamic';
import { FC } from 'react';
import type { FormHooks } from 'react-mailchimp-subscribe';

import { RichText } from '@/components/shared/RichText';

import { RichText as IRichText } from '@/lib/types/richContent';

import { FooterColumnTitle } from '../FooterColumnTitle/FooterColumnTitle';
import { CustomForm } from './CustomForm';

const MailchimpSubscribe = dynamic(() => import('react-mailchimp-subscribe'), { ssr: false });

export interface FooterNewsletterProps {
  title: string;
  content: IRichText;
  inputPlaceholder?: string;
}

interface formRender {
  subscribe: any;
  status: string;
  message: string;
}

export const FooterNewsletter: FC<FooterNewsletterProps> = ({
  title,
  content,
  inputPlaceholder
}) => {
  const url = `${process.env['NEXT_PUBLIC_MAILCHIMP_URL']}?u=${process.env['NEXT_PUBLIC_MAILCHIMP_U']}&id=${process.env['NEXT_PUBLIC_MAILCHIMP_ID']}`;

  return (
    <div className="max-w-[32rem]">
      <FooterColumnTitle>{title}</FooterColumnTitle>
      <RichText content={content} bgColor="primary" />
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }: FormHooks<formRender>) =>
          (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
              inputPlaceholder={inputPlaceholder}
            />
          ) as any
        }
      />
    </div>
  );
};
