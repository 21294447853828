// THIS FILE IS GENERATED WITH `yarn codegen`
// eslint-disable @typescript-eslint/no-explicit-any
// eslint-disable-next-line import-helpers/order-imports
import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const CasestudiesItemDocument = gql`
  query CasestudiesItem($slug: ID!, $relations: String = "") {
    CasestudiesItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        headerConfig
        withAsideLinks
        component
        seo
        bottomSliderType
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type CasestudiesItemQueryResult = Apollo.QueryResult<
  Types.CasestudiesItemQuery,
  Types.CasestudiesItemQueryVariables
>;
export const CasestudiesItemsDocument = gql`
  query CasestudiesItems(
    $sortBy: String = ""
    $relations: String = ""
    $perPage: Int = 25
    $page: Int
    $startsWith: String = ""
    $excludingSlugs: String = ""
  ) {
    CasestudiesItems(
      sort_by: $sortBy
      page: $page
      resolve_relations: $relations
      per_page: $perPage
      starts_with: $startsWith
      excluding_slugs: $excludingSlugs
    ) {
      items {
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        content {
          _editable
          _uid
          body
          headerConfig
          cardTitle
          cardDescription
          mainImage {
            filename
            alt
            copyright
            focus
            name
            title
          }
          component
        }
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
      total
    }
  }
`;
export type CasestudiesItemsQueryResult = Apollo.QueryResult<
  Types.CasestudiesItemsQuery,
  Types.CasestudiesItemsQueryVariables
>;
export const GetContentNodeDocument = gql`
  query GetContentNode($slug: ID!) {
    ContentNode(id: $slug) {
      content
    }
  }
`;
export type GetContentNodeQueryResult = Apollo.QueryResult<
  Types.GetContentNodeQuery,
  Types.GetContentNodeQueryVariables
>;
export const ContentNodesDocument = gql`
  query ContentNodes(
    $resolveRelations: String = ""
    $perPage: Int = 12
    $page: Int = 1
    $startsWith: String = ""
    $excludingSlugs: String = ""
    $withTag: String = ""
    $searchTerm: String = ""
    $filterQuery: JsonScalar = {}
    $sortBy: String = ""
  ) {
    ContentNodes(
      page: $page
      resolve_relations: $resolveRelations
      per_page: $perPage
      starts_with: $startsWith
      excluding_slugs: $excludingSlugs
      search_term: $searchTerm
      with_tag: $withTag
      filter_query: $filterQuery
      sort_by: $sortBy
    ) {
      total
      items {
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        content
        tag_list
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
    }
  }
`;
export type ContentNodesQueryResult = Apollo.QueryResult<
  Types.ContentNodesQuery,
  Types.ContentNodesQueryVariables
>;
export const DatasourceEntriesDocument = gql`
  query datasourceEntries($locale: String = "de") {
    DatasourceEntries(dimension: $locale) {
      items {
        name
        id
        value
        dimensionValue
      }
      total
    }
  }
`;
export type DatasourceEntriesQueryResult = Apollo.QueryResult<
  Types.DatasourceEntriesQuery,
  Types.DatasourceEntriesQueryVariables
>;
export const FaqEntriesDocument = gql`
  query FaqEntries($categories: [String!]) {
    FaqentryItems(filter_query_v2: { categories: { in_array: $categories } }) {
      items {
        content {
          categories
          answer
          question
          _uid
          _editable
          component
        }
      }
      total
    }
  }
`;
export type FaqEntriesQueryResult = Apollo.QueryResult<
  Types.FaqEntriesQuery,
  Types.FaqEntriesQueryVariables
>;
export const FooterItemDocument = gql`
  query FooterItem($slug: ID!) {
    FooterItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        component
        columns
        copyright
        bottomLinks
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type FooterItemQueryResult = Apollo.QueryResult<
  Types.FooterItemQuery,
  Types.FooterItemQueryVariables
>;
export const HeaderItemDocument = gql`
  query HeaderItem($slug: ID!) {
    HeaderItem(id: $slug) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
      content {
        _editable
        _uid
        component
        title
        globalScripts
        hamburgerLabel
        backLabel
        variant
        navigation
        logo {
          focus
          alt
          copyright
          filename
          id
          name
          title
        }
      }
    }
  }
`;
export type HeaderItemQueryResult = Apollo.QueryResult<
  Types.HeaderItemQuery,
  Types.HeaderItemQueryVariables
>;
export const InsightsCategoryItemsDocument = gql`
  query InsightsCategoryItems(
    $sortBy: String = ""
    $relations: String = ""
    $perPage: Int = 100
    $page: Int = 1
    $startsWith: String = ""
    $excludingSlugs: String = ""
  ) {
    InsightscategoryItems(
      sort_by: $sortBy
      page: $page
      resolve_relations: $relations
      per_page: $perPage
      starts_with: $startsWith
      excluding_slugs: $excludingSlugs
    ) {
      items {
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        content {
          _editable
          _uid
          body
          headerConfig
          name
          seo
          component
        }
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
      total
    }
  }
`;
export type InsightsCategoryItemsQueryResult = Apollo.QueryResult<
  Types.InsightsCategoryItemsQuery,
  Types.InsightsCategoryItemsQueryVariables
>;
export const InsightsItemDocument = gql`
  query InsightsItem($slug: ID!, $relations: String = "") {
    InsightsItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        headerConfig
        contactLayer
        component
        scripts
        seo
        withAsideLinks
        categories {
          name
          fullSlug
          id
          uuid
        }
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type InsightsItemQueryResult = Apollo.QueryResult<
  Types.InsightsItemQuery,
  Types.InsightsItemQueryVariables
>;
export const InsightsItemsDocument = gql`
  query InsightsItems(
    $sortBy: String = ""
    $relations: String = ""
    $perPage: Int = 25
    $categoryFilter: [String] = []
    $page: Int
    $startsWith: String = ""
    $excludingSlugs: String = ""
  ) {
    InsightsItems(
      sort_by: $sortBy
      page: $page
      filter_query_v2: { categories: { in_array: $categoryFilter } }
      resolve_relations: $relations
      per_page: $perPage
      starts_with: $startsWith
      excluding_slugs: $excludingSlugs
    ) {
      items {
        alternates {
          fullSlug
          id
          isFolder
          name
          parentId
          published
          slug
        }
        content {
          _editable
          _uid
          body
          headerConfig
          cardTitle
          cardDescription
          mainImage {
            filename
            alt
            copyright
            focus
            name
            title
          }
          component
        }
        created_at
        default_full_slug
        first_published_at
        full_slug
        group_id
        id
        is_startpage
        lang
        meta_data
        name
        parent_id
        path
        position
        published_at
        release_id
        slug
        sort_by_date
        tag_list
        translated_slugs {
          lang
          name
          path
        }
        uuid
      }
      total
    }
  }
`;
export type InsightsItemsQueryResult = Apollo.QueryResult<
  Types.InsightsItemsQuery,
  Types.InsightsItemsQueryVariables
>;
export const InsightscategoryItemDocument = gql`
  query InsightscategoryItem($slug: ID!, $relations: String = "") {
    InsightscategoryItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        scripts
        headerConfig
        bottomSliderType
        contactLayer
        withAsideLinks
        name
        seo
        component
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type InsightscategoryItemQueryResult = Apollo.QueryResult<
  Types.InsightscategoryItemQuery,
  Types.InsightscategoryItemQueryVariables
>;
export const LinksDocument = gql`
  query links($startsWith: String = "") {
    Links(starts_with: $startsWith) {
      items {
        id
        isFolder
        isStartpage
        name
        slug
        parentId
        position
        published
        uuid
      }
    }
  }
`;
export type LinksQueryResult = Apollo.QueryResult<Types.LinksQuery, Types.LinksQueryVariables>;
export const PageItemDocument = gql`
  query PageItem($slug: ID!, $relations: String = "") {
    PageItem(id: $slug, resolve_relations: $relations) {
      alternates {
        fullSlug
        id
        isFolder
        name
        parentId
        published
        slug
      }
      content {
        _editable
        _uid
        body
        scripts
        headerConfig
        contactLayer
        component
        bottomSliderType
        seo
        withAsideLinks
      }
      created_at
      default_full_slug
      first_published_at
      full_slug
      group_id
      id
      is_startpage
      lang
      meta_data
      name
      parent_id
      path
      position
      published_at
      release_id
      slug
      sort_by_date
      tag_list
      translated_slugs {
        lang
        name
        path
      }
      uuid
    }
  }
`;
export type PageItemQueryResult = Apollo.QueryResult<
  Types.PageItemQuery,
  Types.PageItemQueryVariables
>;
export const SearchDocument = gql`
  query Search($searchTerm: String, $startsWith: String = "", $excludingSlugs: String = "") {
    ContentNodes(
      search_term: $searchTerm
      starts_with: $startsWith
      per_page: 100
      excluding_slugs: $excludingSlugs
    ) {
      items {
        id
        name
        full_slug
      }
    }
  }
`;
export type SearchQueryResult = Apollo.QueryResult<Types.SearchQuery, Types.SearchQueryVariables>;
