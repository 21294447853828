import dynamic from 'next/dynamic';
import React, { useState, useEffect, CSSProperties, useRef } from 'react';

const BeatLoader = dynamic(() => import('react-spinners/BeatLoader'), { ssr: false });

const Input = dynamic<any>(
  () => import('@/components/shared/FormComponents/Input').then((mod) => mod.Input),
  { ssr: false }
);

interface newsLetterProps {
  status: string | null;
  message: any;
  onValidated: any;
  inputPlaceholder?: string;
}

export const CustomForm = ({ status, message, onValidated, inputPlaceholder }: newsLetterProps) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showInputs, setShowInputs] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    email &&
      email.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: 'BESTIT', // first name static data for the moment
        MERGE2: 'BESTIT' // last name static data for the moment
      });

    return false;
  };

  useEffect(() => {
    switch (status) {
      case 'success':
        clearFields();
        setLoading(false);
        setShowInputs(false);
        break;
      case 'error':
        setLoading(false);
        setShowInputs(true);
        break;
      case 'sending':
        setLoading(true);
        setShowInputs(false);
        break;
    }
  }, [status]);

  const clearFields = () => {
    setEmail('');
  };

  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'black',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center'
  };

  return (
    <form
      className="mc__form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      ref={formRef}
    >
      {status === 'sending' ? (
        <BeatLoader
          color="black"
          loading={loading}
          cssOverride={override}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : null}
      {status === 'error' || status === 'success' ? <p className="text-2xl">{message}</p> : null}
      {showInputs ? (
        <>
          <Input
            withArrow
            placeholder={inputPlaceholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail((e.target as HTMLInputElement).value)
            }
            onArrowClick={() => {
              handleSubmit();
            }}
          />
        </>
      ) : null}
    </form>
  );
};
