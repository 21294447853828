const transition = { type: 'spring', stiffness: 260, damping: 20 };

export const burgerVariants = {
  top: {
    opened: {
      rotate: 22,
      y: 6,
      transition
    },
    closed: {
      rotate: 0,
      y: 0,
      transition
    }
  },

  bottom: {
    opened: {
      rotate: -22,
      y: -6,
      transition
    },

    closed: {
      rotate: 0,
      y: 0,
      transition
    }
  }
};
