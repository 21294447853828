import { ApolloQueryResult, DocumentNode } from '@apollo/client';

import { apolloClient } from '../sdk/clients/apolloClient';

export const getPageItem = <ResultType, VariablesType>(
  query: DocumentNode,
  variables: VariablesType
): Promise<ApolloQueryResult<ResultType>> => {
  return apolloClient.query({
    // TODO remove this comment
    // TODO change this later to 'cache-first'
    fetchPolicy: 'network-only',
    query,
    variables
  });
};
