import { Locale } from '@/hooks/useLang';

import { excludingSlugs } from '@/lib/api/excludingSlugs';
import { getInsightsPosts } from '@/lib/api/utils';
import { INSIGHTS_BLOG_POSTS_PER_PAGE } from '@/lib/consts';

import { range } from '../range';

interface GetInsightsPaginationPathsInput {
  locales: Locale[];
}

export const insightRootSlugs = [
  { slug: 'insights', locale: 'de' },
  { slug: 'insights', locale: 'de-AT' },
  { slug: 'insights', locale: 'en' }
];

/**
 * It takes an array of locales, and returns an array of objects that contain the locale and the slug
 * of the pagination page
 * @param {GetInsightsPaginationPathsInput}  - `locales` - an array of locales that we want to get
 * pagination paths for.
 * @returns An array of objects with the following shape:
 * {
      params: {
        slug: ['insight', 'page', '1']
      },
      locale: 'de'
    }
 */
export const getInsightsPaginationPaths = async ({ locales }: GetInsightsPaginationPathsInput) => {
  const paths = locales.map(async (lang) => {
    const initial = await getInsightsPosts({
      perPage: 1,
      page: 1,
      startsWith: `${lang}/`,
      excludingSlugs
    });

    const insightRoot = insightRootSlugs.find(({ locale }) => locale === lang);

    if (!insightRoot) {
      return [];
    }

    const totalPostItems = initial?.total || 1;
    const totalPages = Math.ceil(totalPostItems / INSIGHTS_BLOG_POSTS_PER_PAGE);

    /* Creating an array of numbers from 1 to totalPages. */
    const pagesNumbers = range(1, totalPages);

    const res = pagesNumbers.map((number) => ({
      params: {
        slug: [insightRoot.slug, 'page', number.toString()]
      },
      locale: lang
    }));

    return res;
  });

  const responses = await Promise.all(paths);
  const paginationPaths = responses.flat();

  return paginationPaths;
};
