import clsx from 'clsx';
import { ChangeEvent, FC, InputHTMLAttributes, forwardRef, useCallback } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

type InputColorVariant = 'white' | 'gray';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  onChange?: (e: ChangeEvent) => void;
  label?: string;
  colorVariant?: InputColorVariant;
  withArrow?: boolean;
  isError?: boolean;
  onArrowClick?: () => void;
  helperText?: string;
  className?: string;
  classNameLabel?: string;
  classNameInput?: string;
  classNameHelperText?: string;
}

// Task: BIWR-5
export const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      className,
      colorVariant = 'white',
      classNameLabel,
      classNameInput,
      classNameHelperText,
      onChange,
      id,
      placeholder,
      isError = false,
      onArrowClick,
      label,
      helperText,
      disabled = false,
      withArrow,
      ...rest
    },
    ref
  ) => {
    const handleOnArrowClick = useCallback(() => {
      if (!disabled && onArrowClick) {
        onArrowClick();
      }
    }, [disabled, onArrowClick]);

    const labelStyles = clsx(
      'inline-block mb-[0.4rem] text-[1.4rem] leading-[2.2rem] text-black cursor-pointer',
      disabled && 'cursor-not-allowed',
      classNameLabel
    );
    const inputWrapperStyles = clsx(withArrow && 'relative');
    const inputStyles = clsx(
      'w-full px-[1.6rem] py-[1.2rem] outline-none text-[1.6rem] lg:text-[1.8rem] border border-transparent bg-white text-black leading-[2.4rem]',
      'placeholder:text-black',
      'focus:border focus:border-gray-800',
      'disabled:text-gray-200 disabled:border disabled:border-gray-200 disabled:cursor-not-allowed',
      withArrow && 'pr-[4.6rem]',
      colorVariant === 'gray' && 'bg-gray-100',
      isError && 'border border-red-500 focus:border-red-500 text-red-500',
      classNameInput
    );
    const helperTextStyles = clsx(
      'inline-block mt-[0.4rem] text-black text-[1.4rem] leading-[2.2rem]',
      disabled && 'text-gray-200',
      isError && 'text-red-500',
      classNameHelperText
    );
    const arrowStyles = clsx(
      'h-[2.4rem] text-primary w-[2.4rem] absolute right-[1.6rem] top-1/2 -translate-y-1/2',
      disabled && 'bg-gray-200'
    );

    return (
      <fieldset className={className} disabled={disabled}>
        {label && (
          <label className={labelStyles} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={inputWrapperStyles}>
          <input
            ref={ref}
            type={type}
            onChange={onChange}
            id={id}
            placeholder={placeholder}
            className={inputStyles}
            {...rest}
          />
          {withArrow && (
            <button onClick={handleOnArrowClick} role="button" aria-label="Go to page">
              <ArrowIcon className={arrowStyles} />
            </button>
          )}
        </div>
        {helperText && <span className={helperTextStyles}>{helperText}</span>}
      </fieldset>
    );
  }
);

Input.displayName = 'Input';
