/* eslint-disable react/display-name */
import { ElementType } from 'react';
import LazyHydrate, { LazyProps } from 'react-lazy-hydration';

type WithLazyHydrationConfig = Omit<LazyProps, 'children'>;

export const withLazyHydration = (Component: ElementType, config?: WithLazyHydrationConfig) =>
  function <T>(props: T) {
    return (
      <LazyHydrate {...config}>
        <Component {...props} />
      </LazyHydrate>
    );
  };
