import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';

export interface ContactLayerPersonContactProps {
  image?: StoryblokImage;
  name?: string;
  occupation?: string;
  contact?: StoryblokLink;
  contactLabel?: string;
}

export const ContactLayerPersonContact: FC<ContactLayerPersonContactProps> = ({
  image,
  name,
  occupation,
  contact,
  contactLabel
}) => (
  <div className="flex gap-[1.6rem] mb-[2.4rem]">
    {image && image.filename && (
      <div className="relative w-full max-w-[9rem] h-[10.6rem]">
        <Image src={image.filename} alt={image.alt ?? ''} layout="fill" objectFit="contain" />
      </div>
    )}
    <div className="flex flex-col">
      {name && <span className="text-[1.8rem] leading-[2.6rem] font-bold">{name}</span>}
      {occupation && <span className="text-[1.8rem] leading-[2.6rem]">{occupation}</span>}
      {contact && (
        <SBLink link={contact}>
          <ButtonLink className="mt-[0.8rem]">{contactLabel}</ButtonLink>
        </SBLink>
      )}
    </div>
  </div>
);
