import clsx from 'clsx';
import Link from 'next/link';

import { AsideLink } from '../types';

const footerLinkStyles = clsx(
  'text-[1.6rem] w-max lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]',
  'hover:text-hoverPrimary transition-colors',
  'focus:text-gray-300',
  'visited:underline visited:underline-offset-4'
);

interface AsideListProps {
  title?: string;
  items?: AsideLink[] | null;
}

export const AsideList = ({ title, items }: AsideListProps) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <div className="basis-1/3 flex flex-col">
      <h3 className="font-bold text-[1.8rem] lg:text-[2.4rem] leading-[2.6rem] lg:leading-[3.4rem] mb-[0.8rem]">
        {title}
      </h3>
      <ul className="list-outside flex flex-col pl-[1.4rem] gap-2 list-disc marker:text-4xl">
        {items.map((link: AsideLink) => (
          <li className="ml-4" key={link.id}>
            <Link href={link.slug} passHref>
              <a className={footerLinkStyles}>
                <span className="first-letter:uppercase inline-block align-top">{link.name}</span>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
