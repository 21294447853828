import { FC } from 'react';

import { Image } from '@/components/shared/Image';
import { Typography } from '@/components/shared/Typography';

import { StoryblokImage } from '@/lib/types/utils';

export interface GallerySliderItemProps {
  image: StoryblokImage;
  title?: string;
  className?: string;
}

export const GallerySliderItem: FC<GallerySliderItemProps> = ({ image, title, className }) => {
  const { alt, filename } = image;

  return (
    <div className={className}>
      <div className="relative  h-[25rem] lg:h-[42rem]">
        {filename && (
          <Image
            src={filename}
            alt={alt || ''}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        )}
      </div>
      {title && <Typography className="mt-[0.8rem] lg:mt-[1.6rem]">{title}</Typography>}
    </div>
  );
};
