// import { TRichText } from '@quansight/shared/types';

import { Storyblok } from '@/lib/storyblok-sdk';
import { RichText } from '@/lib/types/richContent';

export const createMarkup = (storyblokHTML: RichText): { __html: string } => {
  return {
    __html: Storyblok.richTextResolver.render(storyblokHTML)
  };
};
