import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

export interface GoogleTagMenagerProviderProps {
  gtmID?: string;
}

export const GoogleTagMenagerProvider = ({ gtmID }: GoogleTagMenagerProviderProps) => {
  const { asPath } = useRouter();

  useEffect(() => {
    if (!gtmID) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: asPath,
      visitorType: 'guest'
    });
  }, [asPath, gtmID]);

  // If GTM does not exist return children and don't run scripts
  if (!gtmID) {
    return null;
  }

  return (
    <>
      <Script id={`gtm-${gtmID}`} strategy="afterInteractive">{`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtmID}');
    `}</Script>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};
