import { ReducerActions, ReducerState, SetFilterValueAction, SetShowMoreAction } from './types';
import { anyFilterIsActive, getFilteredItems, updateState } from './utils';

export const reducer = (state: ReducerState, { type, payload }: ReducerActions) => {
  const setFilterValue = ({ filterType, value }: SetFilterValueAction['payload']) => {
    const { filters, items, maxItems } = state;

    filters[filterType] = value;

    const withoutFilters = anyFilterIsActive(filters);

    /* If there are no filters selected, then we return the original items array. */
    if (withoutFilters) {
      const withItemsShowMore = maxItems < items.length;

      return updateState(state, {
        filters,
        filteredItems: withItemsShowMore ? items.slice(0, maxItems) : items,
        isShowMoreLabelVisible: withItemsShowMore
      });
    }

    /* Filtering the items array based on the jobArea, jobLevel and jobWorkspace. */
    const filteredItems = getFilteredItems({ items, filters });

    const withFiltersShowMore = maxItems < filteredItems.length;

    return updateState(state, {
      filters,
      filteredItems: withFiltersShowMore ? filteredItems.slice(0, maxItems) : filteredItems,
      isShowMoreLabelVisible: withFiltersShowMore
    });
  };

  const setShowMore = ({ isShowMoreLabelVisible }: SetShowMoreAction['payload']) => {
    const { filters, items } = state;

    const withoutFilters = anyFilterIsActive(filters);

    /* If there are no filters selected, then we return the original items array. */
    if (withoutFilters) {
      return updateState(state, {
        filters,
        filteredItems: items,
        isShowMoreLabelVisible: isShowMoreLabelVisible
      });
    }

    const filteredItems = getFilteredItems({ items, filters });

    return updateState(state, {
      filters,
      filteredItems,
      isShowMoreLabelVisible: isShowMoreLabelVisible
    });
  };

  switch (type) {
    case 'SET_FILTER_VALUE':
      return setFilterValue(payload);

    case 'SET_SHOW_MORE':
      return setShowMore(payload);

    default:
      throw new Error('Filter reducer - invalid action');
  }
};
