import { motion } from 'framer-motion';

import { ColumnContent } from '@/components/nextjs/ColumnContent';
import { JobPostingSchema } from '@/components/nextjs/JobPostingSchema';
import { SBJobPostingSchemaProps } from '@/components/sb/SBJobPostingSchema/SBJobPostingSchema';
import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';
import { Typography } from '@/components/shared/Typography';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { JobworkplaceItem } from '@/lib/api/types/basic';
import { StoryblokLink } from '@/lib/types/utils';
import { getNumber } from '@/lib/utils';

export interface CardJobProps {
  link: StoryblokLink;
  arrowLink?: boolean;
  items?: BlokDynamicZone[];
  jobWorkspace?: JobworkplaceItem[];
  number?: number;
  job_posting?: SBJobPostingSchemaProps[];
}

const variants = {
  initial: {
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)'
  },
  animate: { boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.08)' }, // fix for boxShadow with LayoutGroup & AnimatePresence components
  hover: {
    scale: 1.05,
    boxShadow: '0px 16px 28px rgba(0, 0, 0, 0.08)',
    transition: {
      duration: 0.2
    }
  }
};

export const CardJob = ({
  arrowLink,
  items,
  jobWorkspace,
  link,
  number,
  job_posting
}: CardJobProps) => {
  const cardNumber = getNumber(number);

  const withTopContainer = !!cardNumber || !!arrowLink;

  return (
    <>
      {job_posting?.map((job: SBJobPostingSchemaProps, key) => {
        return (
          <JobPostingSchema
            key={key}
            title={job.title}
            description={job.description}
            date_posted={job.date_posted}
            validThrough={job.validThrough}
            jobWorkplace={jobWorkspace}
          />
        );
      })}

      <SBLink link={link}>
        <motion.a
          variants={variants}
          initial="initial"
          animate="animate"
          whileHover="hover"
          className="w-full h-full block bg-white p-[1.6rem] lg:px-[3.2rem] lg:py-[4.2rem] relative"
        >
          {withTopContainer && (
            <div className="flex justify-between items-center">
              {cardNumber && (
                <Typography variant="preline" tag="p">
                  {cardNumber}
                </Typography>
              )}
              {arrowLink && <ButtonLink as="span" variant="arrow" className="ml-auto" />}
            </div>
          )}
          <div className="flex flex-col justify-between gap-[1.6rem]">
            {jobWorkspace && (
              <ul className="flex flex-col gap-[0.4rem]">
                {jobWorkspace.map((workspace) => (
                  <li key={workspace.id}>
                    <Typography variant="preline">{workspace.name}</Typography>
                  </li>
                ))}
              </ul>
            )}

            <ColumnContent items={items} />
          </div>
        </motion.a>
      </SBLink>
    </>
  );
};
