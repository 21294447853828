import { InsightsItem } from '@/lib/api/types/basic';
import { InsightsItemDocument } from '@/lib/api/types/hooks';
import { InsightsItemQuery, InsightsItemQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * If the data is not undefined, then it is an InsightsItem.
 * @param {any} data - any - this is the data that we're checking to see if it's an InsightsItem.
 * @returns A function that takes in a parameter of any type and returns a boolean.
 */
const isInsightsItem = (data: any): data is InsightsItem => {
  return data !== undefined;
};

/**
 * It gets the data for a single Insights item from the API
 * @param {InsightsItemQueryVariables} variables - InsightsItemQueryVariables - this is the type of the
 * variables that the query expects.
 * @returns An object with the following shape:
 */
export const getInsights = async (
  variables: InsightsItemQueryVariables
): Promise<InsightsItem | null> => {
  const { data } = await Api.getPageItem<InsightsItemQuery, InsightsItemQueryVariables>(
    InsightsItemDocument,
    variables
  );

  return isInsightsItem(data?.InsightsItem) ? data?.InsightsItem : null;
};
