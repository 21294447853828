import React, { FC } from 'react';

import { Breadcrumb, BreadcrumbProps } from '@/components/nextjs/Breadcrumb';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBBreadcrumbProps = BreadcrumbProps & BlokCommonProps;

export const SBBreadcrumb: FC<BlokProps<SBBreadcrumbProps>> = ({ blok }) => {
  const { date, breadcrumbLabel, title, arrowLink, bgColor } = blok;

  return (
    <Breadcrumb
      date={date}
      breadcrumbLabel={breadcrumbLabel}
      title={title}
      arrowLink={arrowLink}
      bgColor={bgColor}
    />
  );
};
