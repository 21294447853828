import clsx from 'clsx';
import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';

export interface GalleryGridProps {
  preline?: string;
  headline?: string;
  images: StoryblokImage[];
  link?: StoryblokLink;
  linkLabel?: string;
  className?: string;
  id?: string;
}

// Task: BIWR-22
export const GalleryGrid: FC<GalleryGridProps> = ({
  headline,
  preline,
  images,
  link,
  linkLabel,
  className,
  id
}) => {
  const wrapperStyles = clsx('w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3rem]', className);

  return (
    <section className={wrapperStyles} id={id}>
      <div className="flex flex-col gap-[0.8rem]">
        {preline && (
          <span className="inline-block text-[1.2rem] lg:text-[1.6rem] leading-[2rem] lg:leading-[2.8rem] text-primary tracking-[0.3em] uppercase font-heading">
            {preline}
          </span>
        )}
        {headline && (
          <h3 className="font-bold text-black text-[3.2rem] lg:text-[6rem] leading-[3.8rem] lg:leading-[6.4rem]">
            {headline}
          </h3>
        )}
      </div>
      <div className="mt-[1.6rem] lg:mt-[3.2rem]">
        <ul className="grid grid-cols-6 gap-[1.8rem] lg:gap-[3.2rem]" role="list">
          {images?.length > 0 &&
            images.map(({ filename, id, alt }) => {
              const stylesListItem = clsx(
                'relative w-auto h-[10.4rem] lg:h-[51rem] col-span-2',
                '[&:nth-child(4n)]:h-[16.4rem] lg:[&:nth-child(4n)]:h-[78.4rem]',
                '[&:nth-child(5n)]:h-[16.4rem] lg:[&:nth-child(5n)]:h-[78.4rem]',
                '[&:nth-child(4n)]:col-start-1 [&:nth-child(4n)]:col-end-4',
                '[&:nth-child(5n)]:col-start-4 [&:nth-child(5n)]:col-end-7'
              );

              return (
                <li key={id} className={stylesListItem}>
                  <Image src={filename} alt={alt || ''} layout="fill" objectFit="cover" />
                </li>
              );
            })}
        </ul>
        {linkLabel && link && (
          <SBLink link={link}>
            <ButtonLink className="mt-[2.4rem] lg:mt-[4rem]">{linkLabel}</ButtonLink>
          </SBLink>
        )}
      </div>
    </section>
  );
};
