import clsx from 'clsx';

import { colorWithHeadingsMap, ColorTextProp } from '@/styles/colors';

import { BackgroundColumnColor } from '@/components/nextjs/GridColumnsContent/types';

import { RichText as IRichText } from '@/lib/types/richContent';
import { createMarkup } from '@/lib/utils/createMarkup/createMarkup';

type BottomSpace = 'small' | 'normal' | 'large' | 'none';

export interface RichTextProps extends ColorTextProp {
  content?: IRichText;
  bgColor?: BackgroundColumnColor;
  bottomSpace?: BottomSpace;
  className?: string;
}

const bottomSpaceStyles: Record<BottomSpace, string> = {
  none: '',
  small: 'mb-[0.6rem] lg:mb-[0.8rem]',
  normal: 'mb-[1.6rem] lg:mb-[2rem]',
  large: 'mb-[2rem] lg:mb-[3.4rem]'
};

export const RichText = ({
  content,
  bgColor,
  bottomSpace = 'normal',
  className,
  colorText = 'default'
}: RichTextProps) => {
  if (!content) {
    return null;
  }

  const styles = clsx(
    bgColor === 'white' && 'text-black',
    bgColor === 'primary' && 'text-white',
    'prose min-w-full transition-colors',
    bgColor === 'primary' &&
      'prose-h1:text-white prose-h2:text-white prose-h3:text-white prose-h4:text-white prose-h5:text-white prose-h6:text-white prose-a:text-white',
    'prose-h1:text-[4.8rem] prose-h1:lg:text-[12rem] prose-h1:leading-[5rem] prose-h1:lg:leading-[13rem]',
    'prose-h2:text-[3.2rem] prose-h2:lg:text-[6rem] prose-h2:leading-[3.8rem] prose-h2:lg:leading-[6.4rem]',
    'prose-h3:text-[2.4rem] prose-h3:lg:text-[3.2rem] prose-h3:leading-[3rem] prose-h3:lg:leading-[4.4rem]',
    'prose-h4:text-[1.6rem] prose-h4:lg:text-[3.2rem] prose-h4:leading-[2.4rem] prose-h4:lg:leading-[4.4rem]',
    'prose-h5:text-[1.6rem] prose-h5:lg:text-[2.4rem] prose-h5:leading-[2.4rem] prose-h5:lg:leading-[3.4rem]',
    'prose-p:text-[1.6rem] prose-p:lg:text-[1.8rem] prose-p:leading-[2.4rem] prose-p:lg:leading-[2.6rem]',
    'prose-a:underline prose-a:underline-offset-4 prose-a:text-[1.6rem] prose-a:text-primary prose-a:lg:text-[1.8rem]',
    'prose-ul:list-circle',
    bgColor === 'white' &&
      '[&>ol>li]:marker:text-primary [&>ul>li]:marker:text-black prose-a:text-primary',
    bgColor === 'primary' && '[&>ol>li]:marker:text-white [&>ul>li]:marker:text-white',
    '[&>ol>li]:marker:font-bold [&>ol>li]:marker:text-[1.8rem] [&>ol>li]:lg:marker:text-[2.4rem]',
    'prose-li:p-0',
    '[&>ul>li>p]:m-0 [&>ul>li]:mt-0',
    'prose-ul:pl-[2rem]',
    bottomSpaceStyles[bottomSpace],
    colorText !== 'default' && colorWithHeadingsMap[colorText],
    'richtext',
    className
  );

  const markup = createMarkup(content);

  return <div className={styles} dangerouslySetInnerHTML={markup} />;
};
