import { InsightsItems } from '@/lib/api/types/basic';
import { InsightsItemsDocument } from '@/lib/api/types/hooks';
import { InsightsItemsQuery, InsightsItemsQueryVariables } from '@/lib/api/types/operations';
import { Api } from '@/lib/storyblok-sdk';

/**
 * It checks if the data is not undefined and if it is, it returns true
 * @param {any} data - any - this is the data that we're going to check if it's an instance of
 * InsightsItems
 * @returns The function isInsightsPosts is returning a boolean value.
 */
const isInsightsPosts = (data: any): data is InsightsItems => {
  return data !== undefined;
};

/**
 * It gets the data from the API and returns it if it's valid
 * @param {InsightsItemsQueryVariables} variables - InsightsItemsQueryVariables
 * @returns a promise that resolves to an object with a data property.
 */
export const getInsightsPosts = async (
  variables: InsightsItemsQueryVariables
): Promise<InsightsItems | null> => {
  const { data } = await Api.getPageItems<InsightsItemsQuery, InsightsItemsQueryVariables>(
    InsightsItemsDocument,
    variables
  );

  return isInsightsPosts(data?.InsightsItems) ? data.InsightsItems : null;
};
