import clsx from 'clsx';
import { FC } from 'react';

import { Image } from '@/components/shared/Image';
import { Typography } from '@/components/shared/Typography';

import { RichText as IRichText } from '@/lib/types/richContent';
import { StoryblokImage } from '@/lib/types/utils';

import { RichText } from '../RichText';

type CardVariant = 'white' | 'gray';

export interface USPItemProps {
  colorVariant?: CardVariant;
  image?: StoryblokImage;
  headline?: string;
  text?: IRichText;
  className?: string;
}

export const USPItem: FC<USPItemProps> = ({
  colorVariant = 'gray',
  image,
  headline,
  text,
  className
}) => {
  const wrapperStyles = clsx(
    'flex flex-col w-full h-full gap-[3.2rem]',
    'px-[1.6rem] lg:px-[3.2rem] pb-[3.2rem] lg:pb-[5.8rem] pt-[5rem] lg:pt-[8.7rem]',
    colorVariant === 'gray' && ' bg-gray-100',
    colorVariant === 'white' && ' bg-white',
    className
  );

  return (
    <div className={wrapperStyles}>
      {image && (
        <div className="relative w-full max-w-[120px] aspect-[4/3] mx-auto">
          <Image src={image.filename} alt={image.alt || ''} layout="fill" objectFit="contain" />
        </div>
      )}

      <div className="text-center">
        {headline && (
          <Typography isBold tag="p" variant="h5" bottomSpace="none" className="mb-[1rem]">
            {headline}
          </Typography>
        )}
        {text && <RichText content={text}></RichText>}
      </div>
    </div>
  );
};
