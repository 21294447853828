export const FacebookIcon = ({ ...rest }) => (
  <svg
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M16.0007 1.66663H12.0007C10.2325 1.66663 8.53685 2.369 7.28661 3.61925C6.03636 4.86949 5.33398 6.56518 5.33398 8.33329V12.3333H1.33398V17.6666H5.33398V28.3333H10.6673V17.6666H14.6673L16.0007 12.3333H10.6673V8.33329C10.6673 7.97967 10.8078 7.64053 11.0578 7.39048C11.3079 7.14044 11.647 6.99996 12.0007 6.99996H16.0007V1.66663Z"
      stroke="#0000FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
