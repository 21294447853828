import clsx from 'clsx';
import React, { FC } from 'react';

export interface DividerProps {
  space?: 'none' | 'small' | 'normal' | 'large';
}

export const Divider: FC<DividerProps> = ({ space = 'none' }) => {
  const styles = clsx(
    'w-full h-0.5 bg-gray-200',
    space === 'small' && 'my-[1.6rem]',
    space === 'normal' && 'my-[2.4rem]',
    space === 'large' && 'my-[3.4rem]'
  );

  return <div className={styles} />;
};
