import { FC } from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

interface Props {
  items: BlokDynamicZone[];
}

export const DesktopItems: FC<Props> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="hidden lg:grid grid-cols-2 gap-[3.2rem]">
      {items.map((item) => (
        <BlokProvider key={item._uid} blok={{ ...item, className: 'max-w-full' }} />
      ))}
    </div>
  );
};
