import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useDatasourceEntries } from '@/components/layout/DatasourceEntriesProvider/DatasourceEntriesProvider';
import { Button } from '@/components/shared/Button';
import { Checkbox } from '@/components/shared/FormComponents/Checkbox';
import { Input } from '@/components/shared/FormComponents/Input';
import { Textarea } from '@/components/shared/FormComponents/Textarea';
import { RichText } from '@/components/shared/RichText';

import { RichText as IRichText } from '@/lib/types/richContent';

import { SuccessForm } from './components/SuccessForm';
import { contactLayerFormValidationSchema } from './schemaValidation';
import { ContactLayerFormData } from './types';

const defaultValues: ContactLayerFormData = {
  name: '',
  email: '',
  content: '',
  agreement: false
};

export interface ContactLayerFormProps {
  checkboxLabel?: IRichText;
  className?: string;
}

export const ContactLayerForm = ({ checkboxLabel, className }: ContactLayerFormProps) => {
  const [isError, setIsError] = useState(false);
  const { t } = useDatasourceEntries();
  const ref = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm<ContactLayerFormData>({
    defaultValues,
    resolver: yupResolver(contactLayerFormValidationSchema(t))
  });

  // On localhost this method is not allowed -> 405 error
  const onSubmit = async () => {
    const myForm = ref.current;

    if (!myForm) {
      return;
    }

    try {
      const formData = new FormData(myForm);
      const body = new URLSearchParams(formData as any).toString();

      await fetch('/favicon.ico', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body
      });

      window.dataLayer.push({ event: 'contact_form_success' });
      reset(defaultValues);
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  const namePlaceholder = t('name_placeholder');
  const emailPlaceholder = t('email_placeholder');
  const contentPlaceholder = t('content_placeholder');
  const sendButtonLabel = t('send_button');
  const loadingLabel = t('loading');
  const successMessageForm = t('success_message_form');
  const submitErrorMessage = t('submit_error_message');

  return (
    <>
      {isSubmitSuccessful ? (
        <SuccessForm className={className} successMessage={successMessageForm} />
      ) : (
        <form
          ref={ref}
          onSubmit={handleSubmit(onSubmit)}
          name="contact"
          method="POST"
          data-netlify="true"
          className={className}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="flex flex-col gap-[1.6rem]">
            <Input
              placeholder={namePlaceholder}
              {...register('name')}
              isError={!!errors.name}
              helperText={errors.name?.message}
            />
            <Input
              placeholder={emailPlaceholder}
              {...register('email')}
              isError={!!errors.email}
              helperText={errors.email?.message}
            />
            <Textarea
              placeholder={contentPlaceholder}
              {...register('content')}
              isError={!!errors.content}
              helperText={errors.content?.message}
            />
            <div className="flex items-start gap-[1.6rem]">
              <Checkbox {...register('agreement')} isError={!!errors.agreement} />
              <RichText
                content={checkboxLabel}
                bottomSpace="none"
                className="text-black prose-a:text-black min-w-[5rem]"
              />
            </div>
          </div>
          <Button type="submit" className="mt-[2.6rem]">
            {isSubmitting ? loadingLabel : sendButtonLabel}
          </Button>
          {isError && submitErrorMessage && (
            <span className="block text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] text-red-500 mt-[1.2rem]">
              {submitErrorMessage}
            </span>
          )}
        </form>
      )}
    </>
  );
};
