import {
  CasestudiesItem,
  InsightscategoryItem,
  InsightsItem,
  PageItem
} from '@/lib/api/types/basic';

/**
 * If the data object has a content object with a contactLayer array, return the first item in that
 * array, otherwise return null.
 * @param {PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | null} data - PageItem |
 * InsightsItem | InsightscategoryItem | CasestudiesItem | null
 * @returns The contactLayer object from the data object.
 */
export const getContactLayerData = (
  data: PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | null
) => {
  const contactLayer = (data?.content?.contactLayer && data?.content?.contactLayer[0]) || null;

  return contactLayer;
};
