import { FC } from 'react';

import {
  HeroBannerWithText,
  HeroBannerWithTextProps
} from '@/components/nextjs/HeroBannerWithText';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBHeroBannerWithTextProps = HeroBannerWithTextProps & BlokCommonProps;

export const SBHeroBannerWithText: FC<BlokProps<SBHeroBannerWithTextProps>> = ({ blok }) => {
  const { background, content } = blok;

  return <HeroBannerWithText background={background} content={content} />;
};
