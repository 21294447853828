import { FC } from 'react';

import { ImageLabelProps } from '../../utils';

export const ImageLabel: FC<ImageLabelProps> = ({ accesses, label, number }) => {
  if (!accesses) {
    return null;
  }

  return (
    <li className="bg-primary px-[1.2rem] lg:px-[1.6rem] py-[0.4rem] lg:py-[1.2rem] text-white flex items-center gap-[2rem]">
      {number && (
        <span className="text-[1.6rem] lg:text-[3.2rem] leading-[2.4rem] lg:leading-[2.8rem] tracking-[0.3em]">
          {number}
        </span>
      )}

      {label && (
        <span className="text-[1.6rem] lg:test-[1.8rem] leading-[2.4rem] lg:leading-[2.8rem]">
          {label}
        </span>
      )}
    </li>
  );
};
