import type { UseSelectProps } from 'downshift';

import { truncateString } from '@/lib/utils/truncateString';

/**
 * If the item is null, return null. Otherwise, if the callback is provided, return the truncated
 * string of the callback. Otherwise, if the item is a string, return the truncated string of the item
 * @param {T | null} item - T | null
 * @param [callback] - A function that takes an item and returns a string.
 * @returns A function that takes in an item and a callback and returns the item's content.
 */
export const getItemContent = <T>(item: T | null, callback?: UseSelectProps<T>['itemToString']) => {
  if (!item) {
    return null;
  }

  return typeof callback === 'function' && callback
    ? truncateString(callback(item))
    : typeof item === 'string'
    ? truncateString(item)
    : null;
};

/**
 * If the item is a string, return it, otherwise return an empty string
 * @param {T} item - The item to be converted to a string.
 */
export const defaultItemToString = <T>(item: T): string => (typeof item === 'string' && item) || '';
