import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/css/bundle';
import { ColumnContent } from '@/components/nextjs/ColumnContent';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { CommonComponentProps } from '@/lib/types/utils';

import { TabsContent } from './components/TabsContent';

export interface TabItem extends CommonComponentProps {
  component: 'tab';
  label?: string;
  tabPanel?: BlokDynamicZone[];
}

SwiperCore.use([Navigation]);

export interface TabsSectionProps {
  items?: TabItem[];
  topContent?: BlokDynamicZone[];
  bottomContent?: BlokDynamicZone[];
  className?: string;
  id?: string;
}

export const TabsSection: FC<TabsSectionProps> = ({
  items,
  topContent,
  bottomContent,
  className,
  id
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [panelsWithFilters, setPanelsWithFilters] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  /* Checking if the element is sticky. */
  useEffect(() => {
    const getTopValueElement = () => {
      if (!ref.current) {
        return;
      }
      const { top } = ref.current.getBoundingClientRect();
      if (top <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', getTopValueElement);

    return () => {
      window.removeEventListener('scroll', getTopValueElement);
    };
  }, []);

  /* Checking if the filters are available in cardItems component. */
  useEffect(() => {
    /**
     * If the `items` prop is available, then loop through each item and find the `cardItems` panel that
     * has the `filtersAvailable` prop set to `true`. If found, then add the item's `_uid` to the
     * `panelsWithFilters` state
     * @returns An array of panels with filters.
     */
    const isFiltersAvailable = () => {
      if (!items) {
        return;
      }

      const arrayOfPanelsWithFiletrs = [];

      for (const item of items) {
        const cardItemsPanelWithFilters = item.tabPanel?.find(
          (panel) => panel.component === 'cardItems' && panel.filtersAvailable
        );
        if (cardItemsPanelWithFilters) {
          arrayOfPanelsWithFiletrs.push(item._uid);
        }
      }

      setPanelsWithFilters(arrayOfPanelsWithFiletrs);
    };

    isFiltersAvailable();
  }, []);

  if (!items || !Array.isArray(items)) {
    return null;
  }

  const wrapperStyles = clsx('w-full max-w-[160rem] mx-auto', className);

  return (
    <section className={wrapperStyles} id={id}>
      <ColumnContent
        items={topContent}
        className="max-w-[72rem] px-[1.6rem] md:px-[3.6rem] mb-[4rem] lg:mb-[5rem]"
      />
      <div ref={ref} className="max-h-max">
        <TabsContent items={items} isSticky={isSticky} panelsWithFilters={panelsWithFilters} />
      </div>
      <ColumnContent items={bottomContent} className="max-w-[72rem] px-[1.6rem] md:px-[3.6rem]" />
    </section>
  );
};
