import { CardJob, CardJobProps } from '@/components/shared/CardJob';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBCardDefaultProps = CardJobProps & BlokCommonProps;

export const SBCardJob = ({ blok }: BlokProps<SBCardDefaultProps>) => {
  const { items, arrowLink, jobWorkspace, link, number, job_posting } = blok;

  return (
    <CardJob
      link={link}
      items={items}
      arrowLink={arrowLink}
      jobWorkspace={jobWorkspace}
      number={number}
      job_posting={job_posting}
    />
  );
};
