import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { useToggle } from '@/hooks/useToggle';

import { TranslatedSlug } from '@/lib/api/types/basic';
import { CommonComponentProps, StoryblokImage } from '@/lib/types/utils';

import { Hamburger } from './components/Hamburger';
import { HeaderLogo } from './components/HeaderLogo';
import { HeaderTitle } from './components/HeaderTitle';
import { NavigationItem } from './types';

const HeaderNavigation = dynamic<any>(() =>
  import('./components/HeaderNavigation').then((mod) => mod.HeaderNavigation)
);

export interface HeaderConfig {
  pageTitle?: string;
  variant: HeaderVariant;
}

export type HeaderVariant = 'normal' | 'sticky';
export interface HeaderProps extends CommonComponentProps {
  title: string;
  hamburgerLabel?: string;
  logo: StoryblokImage;
  navigation: NavigationItem[];
  translatedSlugs?: TranslatedSlug[];
  backLabel?: string;
  variant?: HeaderVariant;
  headerConfig?: HeaderConfig | null;
}

export const Header = ({
  logo,
  hamburgerLabel,
  title,
  navigation,
  backLabel,
  variant = 'normal',
  headerConfig
}: HeaderProps) => {
  const { locale } = useLang();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const { asPath } = useRouter();
  useLockBodyScroll(isMenuOpen);

  const activeVariant = headerConfig?.variant ?? variant;

  const headerStyles = clsx(
    '2xl:pb-[3.6rem] w-full',
    activeVariant === 'normal' && 'relative bg-white z-20',
    activeVariant === 'sticky' && 'sticky top-0 bg-white-20 z-20'
  );

  /* Closing the menu when the route changes. */
  useEffect(() => {
    if (isMenuOpen) {
      toggleMenu(false);
    }
  }, [asPath, locale]);

  return (
    <header className={headerStyles}>
      <div className="sm:mb-[40px] min-h-[135px] max-w-[160rem] p-[1.6rem] md:px-[3.6rem] mx-auto flex items-center justify-between lg:mb-[112px]">
        <div className="lg:pr-[3.8rem]">
          <HeaderLogo data={logo} />
        </div>
        <HeaderTitle pageTitle={headerConfig?.pageTitle} title={title} navigation={navigation} />
        <div className="flex items-center gap-[5rem]">
          <Hamburger label={hamburgerLabel} isOpen={isMenuOpen} onClick={toggleMenu} />
        </div>
        <HeaderNavigation
          isOpen={isMenuOpen}
          data={navigation}
          hamburgerLabel={hamburgerLabel}
          backLabel={backLabel}
          onMenuClose={toggleMenu}
        />
      </div>
    </header>
  );
};
