import { AsideList } from './components/AsideList';
import { AsideLink } from './types';

export interface AsideProps {
  recent: AsideLink[] | null;
  random: AsideLink[] | null;
  related: AsideLink[] | null;
  slug: string;
  withAsideLinks: boolean;
}

export const Aside = ({
  recent = [],
  random = [],
  related = [],
  slug,
  withAsideLinks
}: AsideProps) => {
  if (!withAsideLinks) {
    return null;
  }

  return (
    <aside
      key={slug}
      className="max-w-[160rem] px-[1.6rem] md:px-[3.2rem] 2xl:px-0 w-full mx-auto mb-[4.4rem] lg:mb-[8.8rem]"
    >
      <div className="w-full flex flex-col md:flex-row justify-between gap-20 lg:gap-40">
        <AsideList title="Interessante Themen" items={related} />
        <AsideList title="Neues von best it" items={recent} />
        <AsideList title="Das könnte auch spannend sein" items={random} />
      </div>
    </aside>
  );
};
