import { FC } from 'react';

import { Row, RowProps } from '@/components/nextjs/Row';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBRowProps = RowProps & BlokCommonProps;

export const SBRow: FC<BlokProps<SBRowProps>> = ({ blok }) => {
  const { gap, items } = blok;

  return <Row gap={gap} items={items} />;
};
