import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'prev' | 'next';
  id?: string;
}

export const PaginationButton = forwardRef<HTMLButtonElement, Props>(
  ({ variant = 'prev', className, id }, ref) => {
    const buttonStyles = clsx(
      'hidden p-2 2xl:block 2xl:absolute 2xl:top-1/2 2xl:-translate-y-full text-primary',
      variant === 'prev' && 'lg:-left-[8.5rem] rotate-180',
      variant === 'next' && 'lg:-right-[4.5rem]',
      'disabled:text-gray-200',
      className
    );

    return (
      <button id={id} ref={ref} className={buttonStyles}>
        <ArrowIcon />
      </button>
    );
  }
);

PaginationButton.displayName = 'PaginationButton';
