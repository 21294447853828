import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import SbEditable from 'storyblok-react';

import { componentsMap } from './componentsMap';
import type { BlokDynamicZone, BlokProps } from './types';

const Placeholder = dynamic<any>(
  () => import('@/components/storyblok/Placeholder').then((mod) => mod.Placeholder),
  { ssr: false }
);

export type TBlokProviderProps = {
  blok: BlokDynamicZone;
};

/* A function that takes a block and returns a component. */
export const getBlock = <T extends BlokDynamicZone>(blok: T) => {
  const component = blok.component;
  if (component && componentsMap[component] !== undefined) {
    const Component = componentsMap[component] as FC<BlokProps<BlokDynamicZone>>;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      <SbEditable content={blok} key={blok._uid}>
        <Component key={blok._uid} blok={blok} />
      </SbEditable>
    );
  }

  return <Placeholder key={blok._uid} componentName={component} />;
};

export const BlokProvider: FC<TBlokProviderProps> = ({ blok }) => getBlock(blok);
