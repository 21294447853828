import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

import { useLang } from '@/hooks/useLang';

import { domainPrefix, twitterHandler } from '@/lib/consts';

import { SeoComponent } from './types';

interface SeoProps extends NextSeoProps {
  readonly seo?: SeoComponent;
  readonly additionalMetaTags?: NextSeoProps['additionalMetaTags'];
}

export const Seo = ({ seo, additionalMetaTags = [], ...rest }: SeoProps) => {
  const { asPath } = useRouter();
  const { currentDomain } = useLang();

  const path = asPath.replace(/\/page\/\d+/, '');

  const canonical = `${domainPrefix}${currentDomain?.domain}${path}`;
  const languageDEAlternate = `https://www.bestit.de${path}`;
  const languageDEATAlternate = `https://www.bestit.at${path}`;

  const languageAlternates: NextSeoProps['languageAlternates'] = [
    {
      hrefLang: 'de-DE',
      href: languageDEAlternate
    },
    {
      hrefLang: 'de-AT',
      href: languageDEATAlternate
    },
    {
      hrefLang: 'x-default',
      href: canonical
    }
  ];

  const { metaTitle, metaDescription, metaKeywords, noFollow, noIndex, metaImage } = seo || {};

  const titleTemplate = currentDomain?.domain ? `%s | ${currentDomain?.domain}` : undefined;

  return (
    <NextSeo
      title={metaTitle || undefined}
      titleTemplate={titleTemplate}
      description={metaDescription || undefined}
      nofollow={noFollow || false}
      noindex={noIndex || false}
      openGraph={{
        url: canonical,
        title: metaTitle,
        description: metaDescription,
        images: [
          {
            url: metaImage?.filename ?? '',
            alt: metaImage?.alt ?? '',
            width: 1200,
            height: 628
          }
        ]
      }}
      canonical={canonical}
      languageAlternates={languageAlternates}
      additionalMetaTags={[
        {
          property: 'keywords',
          content: metaKeywords ? metaKeywords : ''
        },
        {
          property: 'viewport',
          content: 'width=device-width, initial-scale=1'
        },
        ...additionalMetaTags
      ]}
      twitter={{
        handle: twitterHandler,
        site: twitterHandler,
        cardType: 'summary_large_image'
      }}
      {...rest}
    />
  );
};
