import clsx from 'clsx';
import { FC } from 'react';

import { Image } from '@/components/shared/Image';

import { HeroBannerProps } from '../../HeroBanner';

interface HeroBannerImageProps {
  image?: HeroBannerProps['image'];
  className?: string;
  priority?: boolean;
}

export const HeroBannerImage: FC<HeroBannerImageProps> = ({ image, className, priority }) => {
  const wrapperStyled = clsx('w-full', className);

  return (
    <div className={wrapperStyled}>
      <div className="ml-auto 3xl:mr-[-16rem] w-[27rem] sm:w-[40rem] lg:w-auto max-w-[81rem] h-[30rem] sm:h-[40rem] lg:h-[91rem] relative">
        {image && image.filename && (
          <Image
            priority={priority ?? true}
            src={image.filename}
            alt={image.alt || ''}
            width={810}
            height={910}
            layout="fill"
            objectFit="contain"
          />
        )}
      </div>
    </div>
  );
};
