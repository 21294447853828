import { getDataSourceEntries } from '../utils/getDataSourceEntries';
import { getLinks } from '../utils/getLinks';
import { getPageItem } from '../utils/getPageItem';
import { getPageItems } from '../utils/getPageItems';

export const Api = {
  getLinks,
  getPageItem,
  getPageItems,
  getDataSourceEntries
};
