import { createContext, FC, ReactNode, useContext } from 'react';

export interface SearchedItem {
  id: number;
  name: string;
  full_slug: string;
}

interface SearchContext {
  searchedItems: SearchedItem[];
}

interface SearchProviderProps {
  children: ReactNode;
  searchedItems: SearchedItem[];
}

const SearchContext = createContext<SearchContext | undefined>(undefined);
SearchContext.displayName = 'SearchContext';

/**
 * It checks if the data is an array of SearchedItem objects
 * @param {any} data - any - this is the data that we're checking to see if it's an array of
 * SearchedItem
 */
export const isSearchedItems = (data: any): data is SearchedItem[] => data !== undefined;

/**
 * It takes in a list of items to search through and returns a context provider that can be used to
 * access the list of items
 * @param  - FC<SearchProviderProps> - This is a generic type that takes a type parameter. In this
 * case, it's the SearchProviderProps type.
 */
export const SearchProvider: FC<SearchProviderProps> = ({ children, searchedItems }) => (
  <SearchContext.Provider value={{ searchedItems }}>{children}</SearchContext.Provider>
);

/**
 * It returns the context object that was created by the SearchProvider component
 * @returns The context object.
 */
export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error('Hook useSearchContext must be used within a SearchProvider component');
  }

  return context;
};
