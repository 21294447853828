import emailjs from '@emailjs/browser';
import { FC, useRef, useState } from 'react';

import { useDatasourceEntries } from '@/components/layout/DatasourceEntriesProvider/DatasourceEntriesProvider';
import { Button } from '@/components/shared/Button';
import { Checkbox } from '@/components/shared/FormComponents/Checkbox';
import { Input } from '@/components/shared/FormComponents/Input';
import { Textarea } from '@/components/shared/FormComponents/Textarea';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

import {
  addStringToLocalStorageArray,
  isStringInLocalStorageArray
} from '@/lib/utils/localStorage';

export interface ContactPageFormProps {
  id?: string;
  success_event?: string;
  failed_event?: string;
}

export const ContactPageForm: FC<BlokProps<ContactPageFormProps & BlokCommonProps>> = ({
  blok
}) => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [company, setCompany] = useState('');
  const [salut, setSalut] = useState('0');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const { id, success_event } = blok;

  const { t } = useDatasourceEntries();
  const emailPlaceholder = t('email_placeholder');
  const sendButtonLabel = t('send_button');
  const loadingLabel = t('loading');

  const isValidToSend = (data: string): boolean => {
    if (!isStringInLocalStorageArray('send_mails', data, true)) {
      addStringToLocalStorageArray('send_mails', data, true);

      return true;
    }

    return false;
  };

  const resetValues = () => {
    setIsSubmitSuccessful(false);
    setCompany('');
    setSalut('0');
    setName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setLoading(false);
  };

  const tryPushEvent = (name: string | undefined): void => {
    if (name === undefined) return;

    window.dataLayer.push({
      event: name
    });
  };

  const onSubmit = async () => {
    if (!form?.current) {
      return;
    }

    if (!isValidToSend(email + message)) {
      return;
    }

    setLoading(true);
    await emailjs
      .sendForm(
        `${process.env['NEXT_PUBLIC_MAILJS_SERVICE_ID']}`,
        `${process.env['NEXT_PUBLIC_MAILJS_TEMPLATE_ID']}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form?.current,
        `${process.env['NEXT_PUBLIC_MAILJS_PUBLIC_KEY']}`
      )
      .then(
        () => {
          setLoading(false);
          resetValues();
        },
        () => {
          tryPushEvent(success_event);
          setLoading(false);
        }
      );
  };

  return (
    <>
      {!isSubmitSuccessful && (
        <form
          {...(id && { id: id })}
          className="px-[1.6rem] md:px-[3.6rem] mb-[100px]"
          ref={form}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div
            className={
              'sm:grid grid-rows-2 grid-flow-col gap-4 max-w-[160rem] mx-auto w-full bg-white mb-[1.4rem]'
            }
          >
            <Input
              placeholder={'Unternehmen'}
              value={company}
              onChange={(e) => {
                setCompany((e.target as HTMLInputElement).value);
              }}
              name={'company'}
              className={'mt-10 lg:mt-0 border border-primary sm:h-[54px] h-[53px]'}
            />

            <select
              value={salut}
              onChange={(e) => setSalut((e.target as HTMLSelectElement).value)}
              name={'salut'}
              className={
                'mt-10 lg:mt-0 w-full border border-primary  h-[52px] text-[19px] pl-[15px]'
              }
            >
              <option value="0">Anrede</option>
              <option value="her">Herr</option>
              <option value="frau">Frau</option>
              <option value="diverse">Divers</option>
            </select>

            <Input
              placeholder={'Vorname'}
              value={name}
              onChange={(e) => setName((e.target as HTMLInputElement).value)}
              name={'name'}
              required={true}
              className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
            />
            <Input
              placeholder={'Nachname'}
              value={lastName}
              onChange={(e) => setLastName((e.target as HTMLInputElement).value)}
              name={'last_name'}
              required={true}
              className={'mt-10 lg:mt-0 border border-primary  h-[53px] '}
            />
            <Input
              placeholder={emailPlaceholder}
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              name={'email'}
              required={true}
              className={'mt-10 lg:mt-0 border border-primary  h-[53px]'}
            />

            <Input
              placeholder={'Telefon'}
              value={phone}
              onChange={(e) => setPhone((e.target as HTMLInputElement).value)}
              name={'phone_number'}
              required={true}
              className={'mt-10 lg:mt-0 border border-primary sm:h-[54px] h-[53px]'}
            />
          </div>
          <div className={'max-w-[160rem] mx-auto w-full bg-white'}>
            <Textarea
              placeholder={'Ihre Nachricht'}
              value={message}
              onChange={(e) => setMessage((e.target as HTMLInputElement).value)}
              name={'message'}
              className={'mt-10 lg:mt-0 border border-primary'}
            />
          </div>
          <div className="flex flex-row justify-center  mt-10">
            <Checkbox name={'agrement'} required={true} onChange={() => true} />
            <p className="mb-0 self-center ml-3">
              Ich stimme zu, dass meine persönlichen Daten gespeichert und durch die best it Gmbh
              verarbeitet werden.
            </p>
          </div>
          <div className="text-center mt-10">
            <Button type="submit" className="mt-[2.6rem]">
              {loading ? loadingLabel : sendButtonLabel}
            </Button>
          </div>
        </form>
      )}
    </>
  );
};
