export const getRandom = (arr: any, n: number) => {
  const result = new Array(n);
  let len = arr.length;
  const taken: number[] = new Array(len);

  if (n > len) {
    throw new RangeError('getRandomObjects: more elements taken than available');
  }

  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }

  return result;
};
