import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';
// import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import { FacebookIcon } from '@/components/shared/Icons/FacebookIcon';
import { LinkedinIcon } from '@/components/shared/Icons/LinkedinIcon';
import { TwitterIcon } from '@/components/shared/Icons/TwitterIcon';

import { useLang } from '@/hooks/useLang';

import { domainPrefix } from '@/lib/consts';

const FacebookShareButton = dynamic<any>(
  () => import('react-share').then((mod) => mod.FacebookMessengerShareButton),
  { ssr: false }
);
const LinkedinShareButton = dynamic<any>(
  () => import('react-share').then((mod) => mod.LinkedinShareButton),
  { ssr: false }
);
const TwitterShareButton = dynamic<any>(
  () => import('react-share').then((mod) => mod.TwitterShareButton),
  { ssr: false }
);

export interface ShareWebsiteProps {
  title?: string | null;
  isFull?: boolean;
  className?: string;
  id?: string;
}

export const ShareWebsite: FC<ShareWebsiteProps> = ({ isFull, title, className, id }) => {
  const { asPath } = useRouter();
  const { currentDomain } = useLang();

  const url = currentDomain && `${domainPrefix}${currentDomain.domain}${asPath}`;

  if (!url) {
    return null;
  }

  const wrapperStyles = clsx(
    'bg-gray-100 py-[3.4rem] lg:py-[4.4rem] px-[1.6rem] md:px-[3.6rem] mx-auto mb-[4.4rem] max-w-[160rem]',
    isFull && 'max-w-full',
    className
  );

  return (
    <section className={wrapperStyles} id={id}>
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center gap-[3.4rem] sm:gap-[12rem]">
        <span className="text-[2.2rem] lg:text-[3.2rem] leading-[3.8rem] font-bold">{title}</span>
        <div className="flex items-center gap-[2.8rem]">
          <FacebookShareButton url={url}>
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon />
          </TwitterShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </div>
      </div>
    </section>
  );
};
