import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import { GalleryGrid, GalleryGridProps } from '@/components/nextjs/GalleryGrid';
import { BlokCommonProps, BlokProps } from '@/components/storyblok/BlokProvider';

export type SBGalleryGridProps = GalleryGridProps & BlokCommonProps & SpaceBottomProp;

export const SBGalleryGrid: FC<BlokProps<SBGalleryGridProps>> = ({ blok }) => {
  const { headline, preline, images, link, linkLabel, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <GalleryGrid
      id={id}
      headline={headline}
      preline={preline}
      images={images}
      link={link}
      linkLabel={linkLabel}
      className={styles}
    />
  );
};
