import { FC } from 'react';

import { ButtonElement, ButtonProps } from '@/components/nextjs/ButtonElement';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBButtonElementProps = ButtonProps & BlokCommonProps;

export const SBButtonElement: FC<BlokProps<SBButtonElementProps>> = ({ blok }) => {
  const { id, disabled, withIcon, label, variant, link } = blok;

  return (
    <ButtonElement
      id={id}
      disabled={disabled}
      withIcon={withIcon}
      label={label}
      variant={variant}
      link={link}
    />
  );
};
