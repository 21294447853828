import { SBCardAwardProps } from '@/components/sb/SBCardAward';
import { SBCardDefaultProps } from '@/components/sb/SBCardDefault';
import { BlokDynamicZone } from '@/components/storyblok/BlokProvider';

import { InsightscategoryItem } from '@/lib/api/types/basic';

const validCardsWithFilters = ['cardAward', 'cardPartner', 'cardQualifications'];

const isCardWithCategories = (
  item: BlokDynamicZone
): item is SBCardDefaultProps | SBCardAwardProps => {
  return validCardsWithFilters.includes(item.component);
};

/**
 * It takes an array of BlokDynamicZone items and returns an array of BlokCategory items
 * @param {BlokDynamicZone[]} [items] - The items that you want to get the categories from.
 * @returns An array of categories.
 */
export const getCategories = (items?: BlokDynamicZone[]) => {
  if (!items) {
    return null;
  }

  const categories: InsightscategoryItem[] = [];

  for (const item of items) {
    if (!isCardWithCategories(item)) {
      return;
    }

    if (item.categories) {
      for (const category of item.categories) {
        categories.push(category);
      }
    }
  }

  const result = categories.filter(
    (category, index, self) => index === self.findIndex((t) => t.id === category.id)
  );

  return result;
};

/**
 * It filters out the cards that don't belong to the active category
 * @param {BlokDynamicZone[]} items - BlokDynamicZone[] - this is the array of cards that we're
 * filtering
 * @param {number | null} activeCategoryID - The ID of the category that is currently active.
 * @returns An array of BlokDynamicZone objects that have a category with an id that matches the
 * activeCategoryID.
 */
export const getFilteredCards = (items: BlokDynamicZone[], activeCategoryID: number | null) => {
  if (!activeCategoryID) {
    return items;
  }

  const filteredCards = items.filter((item) => {
    const category =
      isCardWithCategories(item) &&
      item?.categories?.find((category) => category.id === activeCategoryID);

    return !!category;
  });

  return filteredCards;
};

/**
 * It takes an array of cards and returns a subset of that array
 * @param {BlokDynamicZone[]} [items] - The array of items to filter.
 * @param {number} [maxItems] - The maximum number of items to show.
 * @returns An array of BlokDynamicZone objects.
 */
export const getInitialItems = (items?: BlokDynamicZone[], maxItems?: number) => {
  if (!items) {
    return items;
  }

  const filteredCards = getFilteredCards(items, null);
  const maxFilteredItems = maxItems ?? filteredCards.length;

  return filteredCards.slice(0, maxFilteredItems);
};

/**
 * It returns true if the number of items is greater than the maxItems value, otherwise it returns
 * false
 * @param {BlokDynamicZone[]} [items] - The items to be filtered.
 * @param {number} [maxItems] - The maximum number of items to show.
 * @returns A boolean value.
 */
export const getInitialItemsShowMoreVisibleState = (
  items?: BlokDynamicZone[],
  maxItems?: number
) => {
  if (!items) {
    return false;
  }

  const filteredCards = getFilteredCards(items, null);
  const maxFilteredItems = maxItems ?? filteredCards.length;

  return maxFilteredItems < items.length;
};
