import { FC } from 'react';

import { Image } from '@/components/shared/Image';

import { StoryblokImage, StoryblokLink } from '@/lib/types/utils';

import { FooterColumnTitle } from '../FooterColumnTitle/FooterColumnTitle';
import { FooterLink } from '../FooterLink';

interface SocialMediaLink {
  component: 'footerNavItemSocial';
  image: StoryblokImage;
  link: StoryblokLink;
  name?: string;
  _editable: string;
  _uid: string;
}

export interface FooterSocialMediaProps {
  title: string;
  items: SocialMediaLink[];
}

export const FooterSocialMedia: FC<FooterSocialMediaProps> = ({ title, items }) => (
  <div>
    <FooterColumnTitle>{title}</FooterColumnTitle>
    <ul className="flex gap-[4rem] lg:gap-[2rem]">
      {items.map(({ image, _uid, link, name }) => (
        <li key={_uid} className="w-[2rem] h-[2rem]">
          <FooterLink link={link} name={name}>
            <Image
              src={image.filename}
              alt={image.alt ?? name ?? ''}
              width={20}
              height={20}
              placeholder="empty"
            />
          </FooterLink>
        </li>
      ))}
    </ul>
  </div>
);
