import { LinkType, StoryblokLink } from '@/lib/types/utils';

type StoryblokLinkOptions = Record<LinkType, string>;

/**
 * If the linktype is story, url, or email, return the appropriate value. Otherwise, return '/'
 * @param  - `linktype` - The type of link.
 * @returns A string
 */
export const getUrl = ({ linktype, cached_url, url, email }: Partial<StoryblokLink>): string => {
  const storyblokLinkOptions: StoryblokLinkOptions = {
    story: `/${cached_url}`,
    url: url ?? '',
    email: `mailto:${email}`
  };

  if (!linktype) {
    return '/';
  }

  if (storyblokLinkOptions[linktype] !== undefined) {
    return storyblokLinkOptions[linktype];
  }

  return '/';
};
