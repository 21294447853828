import { relations } from '@/lib/api/relations';
import { getFooter, getHeader, getPage } from '@/lib/api/utils';
import { ContainerProps, SlugParams } from '@/lib/types';

import { getBottomSliderData } from '../../getBottomSliderData';
import { getSlugFromParams } from '../../getSlugFromParams';
import { TemplatesType } from '../../getTemplateType';
import { getAsideData } from '../getAsideData';

interface GetDefaultTemplateDataInput {
  locale: string;
  preview: boolean;
  params?: SlugParams;
  templateType: TemplatesType;
}

interface GetDefaultTemplateDataOutput {
  props: ContainerProps;
}

/**
 * It gets the data for the default template
 * @param {GetDefaultTemplateDataInput}  - `locale` - the locale of the page
 * @returns An object with a property called props.
 */
export const getDefaultTemplateData = async ({
  locale,
  params,
  templateType,
  preview
}: GetDefaultTemplateDataInput): Promise<GetDefaultTemplateDataOutput> => {
  const slug = getSlugFromParams({ params });

  const footerData = await getFooter({ slug: `${locale}/layout/footer` });
  const headerData = await getHeader({ slug: `${locale}/layout/header` });
  const data = await getPage({ slug: `${locale}/${slug}`, relations });

  const withAsideLinks = data?.content?.withAsideLinks ?? true;

  const aside = await getAsideData({ slug, locale, withAsideLinks });

  const bottomSliderData = await getBottomSliderData(data?.content?.bottomSliderType, locale);

  return {
    props: {
      data,
      headerData,
      footerData,
      preview,
      templateType,
      bottomSliderData,
      aside
    }
  };
};
