import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export interface VideoYoutubeProps {
  url?: string;
}

export const VideoYoutube = ({ url }: VideoYoutubeProps) => {
  const [showChild, setShowChild] = useState(false);

  // TODO fix this later
  // https://ittutorialpoint.com/solved-react-18-hydration-failed-because-the-initial-ui-does-not-match-what-was-rendered-on-the-server/
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!url || !showChild) {
    return null;
  }

  return (
    <ReactPlayer
      width="100%"
      height="100%"
      className="react-player"
      url={url}
      config={{
        youtube: {
          playerVars: {
            autoPlay: 0,
            disablekb: 1,
            showinfo: 1,
            volume: 0,
            muted: 0,
            pip: 1,
            controls: 2,
            enablejsapi: 1
          }
        }
      }}
    />
  );
};
