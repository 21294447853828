import { HeaderConfig } from '@/components/layout/Header';

import {
  CasestudiesItem,
  InsightscategoryItem,
  InsightsItem,
  PageItem
} from '@/lib/api/types/basic';

/**
 * It takes in a data object, and returns the headerConfig object from the data object
 * @param {PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | null} data - PageItem |
 * InsightsItem | InsightscategoryItem | CasestudiesItem | null
 * @returns HeaderConfig | null | undefined
 */
export const getHeaderConfigData = (
  data: PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | CasestudiesItem | null
) => {
  const headerConfig: HeaderConfig | null | undefined =
    (data?.content?.headerConfig && data?.content?.headerConfig[0]) || null;

  return headerConfig;
};
