import { FC } from 'react';
import { JobPosting, Place, WithContext } from 'schema-dts';

import { StructuredData } from '@/components/shared/StructuredData';

import { JobworkplaceItem } from '@/lib/api/types/basic';
import { StoryblokLink } from '@/lib/types/utils';
import { getOrganizationSchema } from '@/lib/utils/getOrganizationSchema';

export interface JobPostingSchemaProps {
  title?: string;
  description?: string;
  date_posted?: string;
  validThrough?: string;
  jobWorkplace?: JobworkplaceItem[];
  link?: StoryblokLink;
}

export const JobPostingSchema: FC<JobPostingSchemaProps> = ({
  title,
  description,
  date_posted,
  validThrough,
  jobWorkplace,
  link
}: JobPostingSchemaProps) => {
  if (!title || !description || !date_posted || !validThrough) {
    return null;
  }

  const jobWorkplaceToSchema = (JobWorkplace: JobworkplaceItem[]): Place[] => {
    const result: Place[] = [];

    JobWorkplace.forEach((workplace) => {
      if (!(workplace?.content?.isRemote ?? false)) {
        result.push({
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            ...(workplace?.content?.city && { addressLocality: workplace.content.city }),
            ...(workplace?.content?.region && { addressRegion: workplace.content.region }),
            ...(workplace?.content?.country && { addressCountry: workplace.content.country }),
            ...(workplace?.content?.street && { streetAddress: workplace.content.street }),
            ...(workplace?.content?.postal && { postalCode: workplace.content.postal })
          }
        });
      }
    });

    return result;
  };

  const schema: WithContext<JobPosting> = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    hiringOrganization: getOrganizationSchema(),
    title: title,
    description: description,
    datePosted: date_posted,
    validThrough: validThrough,
    ...(link?.url && { url: link.url }),
    ...(jobWorkplace && { jobLocation: jobWorkplaceToSchema(jobWorkplace) })
  };

  return <StructuredData data={schema} />;
};
