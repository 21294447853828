import React, { FC } from 'react';

import { Video, VideoProps } from '@/components/shared/Video';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBVideoProps = VideoProps & BlokCommonProps;

export const SBVideo: FC<BlokProps<SBVideoProps>> = ({ blok }) => {
  const { file, loop, autoPlay, thumbnailImage, uploadDate } = blok;

  return (
    <Video
      file={file}
      loop={loop}
      autoPlay={autoPlay}
      thumbnailImage={thumbnailImage}
      uploadDate={uploadDate}
    />
  );
};
