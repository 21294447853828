import clsx from 'clsx';
import { FC } from 'react';

import { Chips } from '@/components/shared/Chips';
import { SBLink } from '@/components/shared/SBLink';

import { StoryblokLink } from '@/lib/types/utils';

export interface ColumnContentChipsProps {
  name?: string;
  link?: StoryblokLink;
  spaceBottom?: 'small' | 'normal' | 'none';
}

export const ColumnContentChips: FC<ColumnContentChipsProps> = ({
  link,
  name,
  spaceBottom = 'none'
}) => {
  const hasLink = link && (link.cached_url || link.url);

  const styles = clsx(
    'max-w-max',
    spaceBottom === 'small' && 'mb-[1.2rem]',
    spaceBottom === 'normal' && 'mb-[1.6rem]',
    !hasLink && 'cursor-default'
  );

  if (link && !link?.cached_url && !link?.url) {
    return <Chips label={name} className={styles} />;
  }

  if (!link) {
    return null;
  }

  return (
    <SBLink link={link}>
      <Chips label={name} as="a" className={styles} />
    </SBLink>
  );
};
