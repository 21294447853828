import { CSSProperties, FC } from 'react';

import { TypographyProps, Typography } from '@/components/shared/Typography';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

import { StoryblokPalette } from '@/lib/types/utils/storeyblokPallete';

interface Palette {
  color: StoryblokPalette;
}

export type SBTypographyProps = TypographyProps & BlokCommonProps & Palette;

export const SBTypography: FC<BlokProps<SBTypographyProps>> = ({ blok }) => {
  const { children, tag, variant, bottomSpace, isBold, isUppercase, color } = blok;

  const style = color?.value ? { '--text-sb-color': color?.value } : undefined;
  const className = color?.value ? '!text-[var(--text-sb-color)]' : undefined;

  return (
    <Typography
      tag={tag}
      variant={variant}
      bottomSpace={bottomSpace}
      isBold={isBold}
      isUppercase={isUppercase}
      className={className}
      style={style as CSSProperties}
    >
      {children}
    </Typography>
  );
};
