import React, { FC } from 'react';

import { ListItem, ListItemProps } from '@/components/nextjs/List/Components/ListItem';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBListItemProps = ListItemProps & BlokCommonProps;

export const SBListItem: FC<BlokProps<SBListItemProps>> = ({ blok }) => {
  const { variant, isBold, content, bgColor, index } = blok;

  return (
    <ListItem variant={variant} isBold={isBold} content={content} bgColor={bgColor} index={index} />
  );
};
