import { ContactPageForm } from '@/components/nextjs/ContactPageForm';
import { CookiebotDeclaration } from '@/components/nextjs/CookiebotDeclaration';
import { WhitePaperForm } from '@/components/nextjs/WhitePaperForm';
import { SBBreadcrumb } from '@/components/sb/SBBreadcrumb';
import { SBButtonElement } from '@/components/sb/SBButtonElement';
import { SBCardAward } from '@/components/sb/SBCardAward';
import { SBCardDefault } from '@/components/sb/SBCardDefault';
import { SBCardItems } from '@/components/sb/SBCardItems';
import { SBCardJob } from '@/components/sb/SBCardJob';
import { SBCaseStudy } from '@/components/sb/SBCaseStudy';
import { SBColumnContent } from '@/components/sb/SBColumnContent';
import { SBColumnContentChips } from '@/components/sb/SBColumnContentChips';
import { SBColumnImage } from '@/components/sb/SBColumnImage';
import { SBColumnsWithCardsAndBackgroundImage } from '@/components/sb/SBColumnsWithCardsAndBackgroundImage';
import { SBContactLayerForm } from '@/components/sb/SBContactLayerForm';
import { SBContactLayerPersonContact } from '@/components/sb/SBContactLayerPersonContact';
import { SBDivider } from '@/components/sb/SBDivider';
import { SBFaqAccordion } from '@/components/sb/SBFaqAccordion';
import { SBFaqItem } from '@/components/sb/SBFaqItem';
import { SBFooterColumn } from '@/components/sb/SBFooterColumn';
import { SBFooterNavigation } from '@/components/sb/SBFooterNavigation';
import { SBFooterNewsletter } from '@/components/sb/SBFooterNewsletter';
import { SBFooterSocialMedia } from '@/components/sb/SBFooterSocialMedia';
import { SBGalleryGrid } from '@/components/sb/SBGalleryGrid';
import { SBGallerySlider } from '@/components/sb/SBGallerySlider';
import { SBGallerySliderItem } from '@/components/sb/SBGallerySliderItem';
import { SBGlobalDataContent } from '@/components/sb/SBGlobalDataContent';
import { SBGridColumnsContent } from '@/components/sb/SBGridColumnsContent';
import { SBGridImageRight } from '@/components/sb/SBGridImageRight';
import { SBHeroBanner } from '@/components/sb/SBHeroBanner';
import { SBHeroBannerWithText } from '@/components/sb/SBHeroBannerWithText';
import { SBHeroTextImage } from '@/components/sb/SBHeroTextImage';
import { SBImageSection } from '@/components/sb/SBImageSection';
import { SBImageText } from '@/components/sb/SBImageText';
import { SBJobCardItems } from '@/components/sb/SBJobCardItems';
import { SBJobPostingSchema } from '@/components/sb/SBJobPostingSchema/SBJobPostingSchema';
import { SBLink } from '@/components/sb/SBLink';
import { SBList } from '@/components/sb/SBList';
import { SBListItem } from '@/components/sb/SBListItem';
import { SBMobileSliderDesktopCol } from '@/components/sb/SBMobileSliderDesktopCol';
import { SBMobileSliderDesktopRow } from '@/components/sb/SBMobileSliderDesktopRow';
import { SBQuestionSelect } from '@/components/sb/SBQuestionSelect';
import { SBRawCode } from '@/components/sb/SBRawCode';
import { SBRichText } from '@/components/sb/SBRichText';
import { SBRow } from '@/components/sb/SBRow';
import { SBShareWebsite } from '@/components/sb/SBShareWebsite';
import { SBTabsSection } from '@/components/sb/SBTabsSection';
import { SBTypography } from '@/components/sb/SBTypography';
import { SBUSPItem } from '@/components/sb/SBUSPItem/SBUSPItem';
import { SBVideo } from '@/components/sb/SBVideo';
import { SBVideoYoutube } from '@/components/sb/SBVideoYoutube';

import { withLazyHydration } from '@/lib/utils/withLazyHudration/withLazyHudration';

export const componentsMap = {
  // Sections
  galleryGrid: withLazyHydration(SBGalleryGrid, { whenVisible: true }),
  gallerySlider: withLazyHydration(SBGallerySlider, { whenVisible: true }),
  heroTextImage: withLazyHydration(SBHeroTextImage, { whenVisible: true }),
  mobileSliderDesktopRow: withLazyHydration(SBMobileSliderDesktopRow, { whenVisible: true }),
  mobileSliderDesktopCol: withLazyHydration(SBMobileSliderDesktopCol, { whenVisible: true }),
  gridColumnsContent: withLazyHydration(SBGridColumnsContent, { whenVisible: true }),
  shareWebsite: withLazyHydration(SBShareWebsite, { whenVisible: true }),
  caseStudy: withLazyHydration(SBCaseStudy, { whenVisible: true }),
  imageSection: withLazyHydration(SBImageSection, { whenVisible: true }),
  gridImageRight: withLazyHydration(SBGridImageRight, { whenVisible: true }),
  heroBanner: withLazyHydration(SBHeroBanner, { whenVisible: true }),
  heroBannerWithText: withLazyHydration(SBHeroBannerWithText, { whenVisible: true }),
  cardItems: withLazyHydration(SBCardItems, { whenVisible: true }),
  tabsSection: withLazyHydration(SBTabsSection, { whenVisible: true }),
  questionSelect: withLazyHydration(SBQuestionSelect, { whenVisible: true }),
  jobCardItems: withLazyHydration(SBJobCardItems, { whenVisible: true }),
  columnsWithCardsAndBackgroundImage: withLazyHydration(SBColumnsWithCardsAndBackgroundImage, {
    whenVisible: true
  }),
  rawCode: withLazyHydration(SBRawCode, { whenVisible: true }),

  // Footer,
  footerColumn: withLazyHydration(SBFooterColumn, { whenVisible: true }),
  footerNewsletter: withLazyHydration(SBFooterNewsletter, { whenVisible: true }),
  footerNavigation: withLazyHydration(SBFooterNavigation, { whenVisible: true }),
  footerSocialMedia: withLazyHydration(SBFooterSocialMedia, { whenVisible: true }),

  // columnContent
  list: SBList,
  link: SBLink,
  button: SBButtonElement,
  columnImage: SBColumnImage,
  breadcrumb: SBBreadcrumb,
  richText: SBRichText,
  typography: SBTypography,
  videoYoutube: SBVideoYoutube,
  video: SBVideo,
  chips: SBColumnContentChips,
  divider: SBDivider,
  row: SBRow,
  columnContent: SBColumnContent,
  listItem: SBListItem,
  faqAccordion: SBFaqAccordion,
  faqItem: SBFaqItem,

  // ContactLayer
  contactLayerPersonContact: SBContactLayerPersonContact,
  contactLayerForm: SBContactLayerForm,

  // cardItems
  cardDefault: SBCardDefault,
  // cardQualifications and cardPartner are the same as cardDefault. In the SB panel, filter values from various sources are transferred.
  cardQualifications: SBCardDefault,
  cardPartner: SBCardDefault,
  //
  cardAward: SBCardAward,
  uspItem: SBUSPItem,
  cardJob: SBCardJob,

  // Slider items
  imageTextItem: SBImageText,
  gallerySliderItem: SBGallerySliderItem,
  cookiebotDeclaration: CookiebotDeclaration,

  // contact
  contactPageForm: ContactPageForm,

  // whitePaper
  whitePaper: WhitePaperForm,

  //globalDataContent
  globalDataContent: SBGlobalDataContent,

  // schemas
  jobPostingSchema: SBJobPostingSchema
};
