import clsx from 'clsx';
import { FC } from 'react';

import { SpaceBottomProp, spacingBottom } from '@/styles/spacing';

import {
  GridColumnsContent,
  GridColumnsContentProps
} from '@/components/nextjs/GridColumnsContent';
import { BlokProps, BlokCommonProps } from '@/components/storyblok/BlokProvider';

export type SBGridColumnsContentProps = GridColumnsContentProps & BlokCommonProps & SpaceBottomProp;

export const SBGridColumnsContent: FC<BlokProps<SBGridColumnsContentProps>> = ({ blok }) => {
  const { columns, columnsPerRow, spaceBetween, containerSize, background, spaceBottom, id } = blok;

  const styles = clsx(spacingBottom[spaceBottom ?? 'default']);

  return (
    <GridColumnsContent
      id={id}
      columns={columns}
      columnsPerRow={columnsPerRow}
      spaceBetween={spaceBetween}
      containerSize={containerSize}
      background={background}
      className={styles}
    />
  );
};
